import React, { useEffect, useState } from "react";
import { FormGridRow } from "../../form-grid-row/form-grid-row";
import InputBox from "../../input-box/input-box";
import { IModalAddressProps } from './modal-adress-types';
import MainButton from "../../main-button/main-button";
import ModalContainer from "../modal-container/modal-container";
import { ModalAdressStyle } from "./modal-adress-style";
import SelectBox from "../../select-box/select-box";
import {
  CARD_NUMBER_INPUT_NAME,
  getNewCardInputMetadata,
  CARD_HOLDERNAME_INPUT,
  CARD_CVV_INPUT_INPUT,
  CARD_ADDRESS_LINE1_INPUT,
  CARD_ADDRESS_PC_INPUT,
  CARD_EXP_MONTH_INPUT,
  CARD_EXP_YEAR_INPUT,
  CARD_ADDRESS_LINE2_INPUT,
  CARD_ADDRESS_CITY_INPUT,
  CARD_ADDRESS_STATE_INPUT
} from "../../../utils/login-input-names";
import { useForm, Controller } from "react-hook-form";
import { getNewCardFormErrors } from "../../../utils/login-input-validations";
import { STATES } from "@aluxion-lightpay/interfaces";
import { useSelector, useDispatch } from 'react-redux';
import { cardActions } from "../../../store/card/actions";
import { authSelectors } from "../../../store/auth/selectors";
import { modalActions } from "../../../store/modal/actions";
import useModal from "../../../hooks/useModal/use-modal";
import { isValidNumber } from "../../../utils/validation";
import moment from 'moment';
import { loaderActions } from "../../../store/loader/actions";
import { loadSelector } from "../../../store/loader/selectors";
import Loader from "../../loader/loader";
import amplitude from 'amplitude-js'
import { paymentSelector } from "../../../store/payment/selectors";
import SendApiConversion from "../../../constants/apiConversionFb";
import { cardSelector } from "../../../store/card/selectors";

const ModalAdress: React.FC<IModalAddressProps> = (props: IModalAddressProps) => {
  const { modalConfig, onClick ,creditCardsDigits} = props
  const dispatch = useDispatch();
  const { id, email, phoneNumber }: any = useSelector(authSelectors.getMe());
  // const  id  = 234567;
  const { register, handleSubmit, errors, control, watch } = useForm();
  const cardNumberData = watch(CARD_NUMBER_INPUT_NAME);
  const { isModal, openModal, closeModal } = useModal();
  const loading = useSelector(loadSelector.isLoading());
  const purchasesData: any = useSelector(paymentSelector.purchasesData())
  let maxlen: number = 30;
  const { items: creditCardData } = useSelector(cardSelector.getCardData());
  const [cardValidation, setCardValidation] = useState<boolean>(false);


/*   let creditCardsDigits = creditCardData.map(item => {
    return item.lastDigits      
  })*/
  // const openModal = async (id: number | string) => {
  //   await dispatch(modalActions.show(id));
  // };

  // useEffect(() => {
  //   openModal('errorBank')
  // })

  const validateMonth = (value: string) => {
    if (!isValidNumber(value)) return false
    if (parseInt(value) > 12) {
      return false
    }
  }

  const validateYear = (value: string) => {
    if (!isValidNumber(value)) return false
    if (parseInt(value) < parseInt(moment().format('YY'))) {
      return false;
    }
    if (parseInt(value) > parseInt(moment().format('YY')) + 19) {
      return false;
    }
  }

  const handleForm = async (data: any) => {
    let cardData: any = {
      address: {
        city: data[CARD_ADDRESS_CITY_INPUT],
        line1: data[CARD_ADDRESS_LINE1_INPUT],
        postalCode: data[CARD_ADDRESS_PC_INPUT],
        state: data[CARD_ADDRESS_STATE_INPUT],
      },
      cardNumber: data[CARD_NUMBER_INPUT_NAME].replace(/\s/g, ''),
      cvv2: data[CARD_CVV_INPUT_INPUT],
      expirationMonth: parseInt(data[CARD_EXP_MONTH_INPUT]),
      expirationYear: parseInt(data[CARD_EXP_YEAR_INPUT]),
      holderName: data[CARD_HOLDERNAME_INPUT],
    };
    if (data[CARD_ADDRESS_LINE2_INPUT]) {
      cardData = {
        ...cardData,
        address: {
          ...cardData.address,
          line2: data[CARD_ADDRESS_LINE2_INPUT]
        }
      }
    }
    let lastDigits = cardData.cardNumber.slice(-4)
    creditCardData.map(item => {
      if(item.lastDigits === lastDigits){
        setCardValidation(true)
        console.log('Tarjeta duplicada')
      }      
    })
      try {
        dispatch(loaderActions.loading());
        (window as any).OpenPay.token.create({
          expiration_month: cardData.expirationMonth,
          expiration_year: cardData.expirationYear,
          cvv2: cardData.cvv2,
          card_number: cardData.cardNumber,
          holder_name: cardData.holderName,
          address: {
            country_code: "MX",
            city: cardData.address.city,
            postal_code: cardData.address.postalCode,
            line1: cardData.address.line1,
            state: cardData.address.state,
          }
  
        }, async (response) => {
          await dispatch(cardActions.addCard(id, cardData, response.data.id, purchasesData?.id,creditCardsDigits));
          let size = purchasesData?.products?.length
          amplitude.getInstance().logEvent('payment card info added', { payment_method: response, totalCartValue: purchasesData?.totalAmount, cartSize: size, totalDiscounts: purchasesData?.extraDetails?.discountCode?.giftAmount, email: email, merchantName: purchasesData?.commerce?.name });
          //SendApiConversion('payment card info added',[email],[phoneNumber],purchasesData?.commerce?.name);
          onClick();
          await dispatch(loaderActions.loaded());
          //modalConfig?.onClose && modalConfig.onClose();
        },
          error => {
            console.log(error, 'error')
            amplitude.getInstance().logEvent('error encountered', { 'error_type': 'payment card info', 'error_reason': JSON.stringify(error) });
            dispatch(loaderActions.loaded());
            openModal('errorBank')
          }
        );
  
      } catch (error) {
        console.log(error);
        amplitude.getInstance().logEvent('error encountered', { 'error_type': 'payment card info', 'error_reason': JSON.stringify(error) });
        await dispatch(loaderActions.loaded());
        //modalConfig?.onClose && modalConfig.onClose();
        openModal('errorBuy')
      }

    //history.push('/perfil');
  };

  return (
    <>
      {loading && <><Loader /></>}
      <ModalContainer {...modalConfig}>
        <ModalAdressStyle>
          <div className="new-credit-card-form">
          <div className="recomendation">
            <p>Si vas a utilizar tu tarjeta <a href="https://www.bbva.mx/personas/servicios-digitales/tarjeta-digital.html" target="_blank" className="banks">BBVA</a> o <a href="https://www.banamex.com/cvvdigital/index.html" target="_blank" className="banks"> CitiBanamex</a> <p>te recomendamos utilizar tu tarjeta digital.</p></p>
          </div>
            <div className="modal-adress-title update-address-title">
              <p>Datos de la <span>tarjeta</span> </p>
            </div>
            <FormGridRow pattern="1fr">
              <Controller
                as={
                  <InputBox
                    labelText="Nº de tarjeta"
                    name={CARD_NUMBER_INPUT_NAME}
                    // inputRef={register(getNewCardInputMetadata(CARD_NUMBER_INPUT_NAME))}
                    errorCode={getNewCardFormErrors(errors, CARD_NUMBER_INPUT_NAME)}
                    cleaveFormat={{
                      creditCard: true,
                    }}
                  />
                }
                name={CARD_NUMBER_INPUT_NAME}
                control={control}
                rules={getNewCardInputMetadata(CARD_NUMBER_INPUT_NAME)}
              />

            </FormGridRow>
            <FormGridRow pattern="1fr">
              <InputBox
                labelText="Nombre del titular"
                name={CARD_HOLDERNAME_INPUT}
                inputRef={register(getNewCardInputMetadata(CARD_HOLDERNAME_INPUT))}
                errorCode={getNewCardFormErrors(errors, CARD_HOLDERNAME_INPUT)}
              />
            </FormGridRow>
            <FormGridRow pattern="1fr 1fr 1fr" respPattern="1fr">
              <InputBox
                labelText="cvv"
                name={CARD_CVV_INPUT_INPUT}
                inputRef={register(getNewCardInputMetadata(CARD_CVV_INPUT_INPUT, {},
                  cardNumberData ? cardNumberData.substring(0, 2) === '34' || cardNumberData.substring(0, 2) === '37' ? true : false : false
                ))}
                errorCode={getNewCardFormErrors(errors, CARD_CVV_INPUT_INPUT)}
                maxLength={cardNumberData ? cardNumberData.substring(0, 2) === '34' || cardNumberData.substring(0, 2) === '37' ? 4 : 3 : 3}
              />

              <Controller
                as={
                  <InputBox
                    labelText="mm"
                    name={CARD_EXP_MONTH_INPUT}
                    errorCode={getNewCardFormErrors(errors, CARD_EXP_MONTH_INPUT)}
                    cleaveFormat={{
                      date: true,
                      datePattern: ['m'],
                    }}
                  />
                }
                name={CARD_EXP_MONTH_INPUT}
                control={control}
                rules={getNewCardInputMetadata(CARD_EXP_MONTH_INPUT, { invalidMonth: validateMonth })}
              />

              <Controller
                as={
                  <InputBox
                    labelText="yy"
                    name={CARD_EXP_YEAR_INPUT}
                    errorCode={getNewCardFormErrors(errors, CARD_EXP_YEAR_INPUT)}
                    cleaveFormat={{
                      numeral: true,
                      blocks: [2],
                    }}
                    maxLength={2}
                  />
                }
                name={CARD_EXP_YEAR_INPUT}
                control={control}
                rules={getNewCardInputMetadata(CARD_EXP_YEAR_INPUT, { invalidYear: validateYear })}
              />

            </FormGridRow>
          </div>
          <div className="modal-adress-title update-address-title">
            <p>Dirección ligada a esta <span>tarjeta</span> </p>
          </div>
          <div className="modal-adress-form">
            <FormGridRow pattern="1fr">
              <InputBox
                labelText="Calle y Número"
                name={CARD_ADDRESS_LINE1_INPUT}
                inputRef={register(getNewCardInputMetadata(CARD_ADDRESS_LINE1_INPUT))}
                errorCode={getNewCardFormErrors(errors, CARD_ADDRESS_LINE1_INPUT)}
                maxLength={maxlen}
              />
            </FormGridRow>
            <FormGridRow pattern="1fr 1fr" respPattern="1fr">
              <InputBox
                labelText="CP"
                name={CARD_ADDRESS_PC_INPUT}
                inputRef={register(getNewCardInputMetadata(CARD_ADDRESS_PC_INPUT))}
                errorCode={getNewCardFormErrors(errors, CARD_ADDRESS_PC_INPUT)}
              />
              <InputBox
                labelText="Colonia (opcional)"
                name={CARD_ADDRESS_LINE2_INPUT}
                inputRef={register(getNewCardInputMetadata(CARD_ADDRESS_LINE2_INPUT))}
                errorCode={getNewCardFormErrors(errors, CARD_ADDRESS_LINE2_INPUT)}
              />
            </FormGridRow>
            <FormGridRow pattern="1fr 1fr" respPattern="1fr">
              <InputBox
                labelText="Ciudad"
                name={CARD_ADDRESS_CITY_INPUT}
                inputRef={register(getNewCardInputMetadata(CARD_ADDRESS_CITY_INPUT))}
                errorCode={getNewCardFormErrors(errors, CARD_ADDRESS_CITY_INPUT)}
              />
              <Controller
                as={
                  <SelectBox
                    name={CARD_ADDRESS_STATE_INPUT + 'inner'}
                    labelText="Estado"
                    optionsArray={STATES}
                    errorCode={getNewCardFormErrors(errors, CARD_ADDRESS_STATE_INPUT)}
                  />
                }
                name={CARD_ADDRESS_STATE_INPUT}
                control={control}
                rules={getNewCardInputMetadata(CARD_ADDRESS_STATE_INPUT)}
              />
            </FormGridRow>
          </div>
          {cardValidation &&
          <div className="credit-card-duplicated">
            <p>Parece que esta tarjeta ya ha sido agregada, prueba agregando otra.</p>
          </div>
          }
          <div className="modal-adress-submit">
            <MainButton type="primary" text="Añadir y pagar" onClick={handleSubmit(handleForm)} />
          </div>
          <div className="credit-card-back mt-4" onClick={modalConfig.onClose}>
            <p>Cancelar y volver a mi carrito</p>
          </div>
        </ModalAdressStyle>
      </ModalContainer>
    </>
  );
};

export default ModalAdress;
