import styled from 'styled-components';

export const CircleStatStyle = styled.div`
position: relative;
display: flex;
justify-content: center;
align-items: center;

circle {
  transition: stroke-dashoffset 0.35s;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}


.circle-progress {
  position: absolute;
  top : 0;
  left: 0;
}

`