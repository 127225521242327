import styled from 'styled-components/macro';

const ErrorText = styled.p`
  min-height: 17px;
  font-family: 'Gilroy';
  font-size: 12px;
  line-height: 17px;
  color: ${props => props.theme.colors.onError};
`;

export default ErrorText;
