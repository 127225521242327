import styled from 'styled-components';


export const ModalBuyResumeStyle = styled.div`
  max-width: 444px;
  .modal-buy-card {
    background: white;
    padding: 42px;
    margin-bottom: 35px;
  }
  .modal-buy-card-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 4px;
    border-bottom: 1px solid #E6E6E7;
    margin-bottom: 18px;
    &__logo {
      margin-right: 12px;
      max-height: 80px;
      margin-bottom: 8px;
      img {
        height: 100%;
        max-height: 80px;
        width: auto;
      }
    }
    &__order {
      font-size: 14px;
      line-height: 22px;
      text-align: right;
      &__label {
        font-weight: 600;
      }
      &__number {
        font-weight: 300;
      
      }
    }
  }

  .modal-buy-card-resume-row {
      display: flex;
      margin-bottom: 8px;
      justify-content: space-between;
      font-size: 14px;
      line-height: 22px;
      &__title {
        font-weight: 600;
        font-size: 16px;
        display: flex;
        justify-content: space-between;
      }
      &__submain-amount {
        font-weight: 600;
        font-size: 16px;
      }
      &__main-title {
        font-size: 16px;
        color: #1A4CCD;
        font-weight: 600;
      }

      &__main-amount {
        font-size: 16px;
        color: #1A4CCD;
        font-weight: 600;
      }

      &:last-child {
        margin-bottom: 25px;
      }
  }

  .modal-buy-card-resume_message {
    line-height: 22px;
    max-width: 100%;
    font-size: 13px;
    font-weight: 300;
  }

  .modal-buy-card-resume-products {
      display: flex;
      flex-direction:column;
      margin-bottom: 8px;
      line-height: 22px;
      &__title {
      /* font-weight: 600; */
      font-size: 1.1rem;
      margin-bottom: 8px;
        span {
          font-weight: 600;
          color: #1A4CCD;
        }
      }

      &__subtitle {
        font-size: 1.3rem;
        margin-bottom: 8px;
        font-weight: 600;
        span {
          color: #1A4CCD;
        }
      }
      
      &:last-child {
        margin-bottom: 8px;
      }
      &__amount {
        display: flex;
        justify-content: space-between;
        &_product {
          display: flex;
          flex-direction:column;
          span {
          color: #B3B3B6;
          font-size: 12px;
          margin-bottom: 5px;
          }
        }
      }
  }

  .modal-buy-card-resume-total {
      display: flex;
      margin-bottom: 12px;
      justify-content: space-between;
      font-size: 18px;
      line-height: 22px;
      font-weight: 600;
      color: #1A4CCD;
      margin-top: 25px;
      &__amount {
        margin-left: auto;
        .title {
          display: flex;
          justify-content: flex-end;
        }
        .date {
          font-size: 14px;
          margin-right: 5px;
          color: #B3B3B6;
        }
        &-items{
          padding-top: 23px;
          margin-bottom: 10px;
          font-size: 14px;
          color: black;
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          font-weight: normal;
          p {
            margin-bottom: 8px;
          } 
          .item {
            color: #1A4CCD;
          }
        }
      }

  }

  .logo-openpay {
    margin: 30px auto;
    display: flex;
    justify-content: center;
    width: 100px;
    height: 30px;
    img {
      /* margin: 0 auto;
      height: auto;
      object-fit: contain; */
      object-fit: cover;
      height: auto;
      width: 100%;
      min-height: 100%;
    }
  }

  .modal-buy-card-buttons {
    display: flex;
    justify-content: center;
    margin: 0 12px;
    &__item {
      width: 100%;
      margin: 0 12px;
    }
  }

  @media (max-width: 420px) {
    max-width: 350px;
  }

`