import produce from 'immer';

import { AuthActions, IAuthState, IAuthReducer } from './types';

// Move to other file
const checkForDataOnLocalstorage = (blockName: string): IAuthState => {
  let tempState: IAuthState;
  // Think a more abstract solution
  const defaultState: IAuthState = {
    loggingError: null,
    registerError: null,
    checkEmailError: null,
    user: null,
    userToken: null,
    token:null,
    registerForm: { ineModal: { isOpen: false } },
    recoverPasswordError: null,
    resetPasswordError: null,
    userProductionFormData: null,
    isItVerify: null,
    ine: null,
    phoneNumber:null,
  };
  try {
    if (sessionStorage.getItem(blockName)) tempState = JSON.parse(sessionStorage.getItem(blockName) as string);
    else tempState = defaultState;
  } catch (error) {
    tempState = defaultState;
  }

  const initialState: IAuthState = {
    ...tempState,
    userToken: sessionStorage.getItem('token'),
  }; // Refact this and use a redux action
  return initialState;
};

// End move to other file

export const initialState: IAuthState = checkForDataOnLocalstorage('AuthState');

export const saveStoreInLocalstorage = (blockName: string, data: IAuthState) => {
  sessionStorage.setItem(blockName, JSON.stringify(data));
};

const authReducer: IAuthReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case AuthActions.REGISTER_FORM_INE_MODAL_OPEN:
        if (!draft.registerForm) draft.registerForm = {};
        if (!draft.registerForm.ineModal) draft.registerForm.ineModal = {};
        draft.registerForm.ineModal.isOpen = true;
        break;

      case AuthActions.REGISTER_FORM_INE_MODAL_CLOSE:
        if (!draft.registerForm) draft.registerForm = {};
        if (!draft.registerForm.ineModal) draft.registerForm.ineModal = {};
        draft.registerForm.ineModal.isOpen = false;
        break;

      case AuthActions.REGISTER_FORM_INE_MODAL_DATA_ADDED:
        if (!draft.ine) draft.ine = {};
        draft.ine = action.payload;
        break;

      case AuthActions.REGISTER_FORM_PHONE_DATA_ADDED:
        draft.phoneNumber = action.payload;
        break;

      case AuthActions.REGISTER_USER_SUCCESS:
        if (!draft.registerForm) draft.registerForm = {};
        draft.user = action.payload;

        draft.registerError = null;
        draft.registerForm = null;
        break;

      case AuthActions.REGISTER_USER_FAIL:
        if (!draft.registerForm) draft.registerForm = {};
        draft.registerError = action.error;
        break;

      case AuthActions.EMAIL_CHECKED_SUCCESS:
        if (!draft.registerForm) draft.registerForm = {};
        draft.registerForm.email = action.payload;
        draft.checkEmailError = null;
        break;

      case AuthActions.VERIFY_EMAIL:
        draft.isItVerify = action.payload;
        break;

      case AuthActions.EMAIL_CHECKED_FAIL:
        draft.checkEmailError = action.error;
        if (!draft.registerForm) draft.registerForm = {};
        draft.registerForm.email = action.payload;
        break;

      case AuthActions.LOGIN_SUCCESS:
        draft.user = action.payload;
        draft.registerError = null;
        draft.registerForm = null;
        draft.loggingError = null;
        break;

      case AuthActions.LOGIN_FAIL:
        draft.loggingError = action.error;
        break;

      case AuthActions.SET_JWT_TOKEN:
        draft.userToken = action.payload;
        break;
      
      case AuthActions.SET_BUY_JWT_TOKEN:
        draft.token = action.payload;
        break;

      case AuthActions.RECOVER_PASSWORD:
        if (!draft.user) draft.user = {};
        draft.user.email = action.payload;
        break;

      case AuthActions.RECOVER_PASSWORD_FAIL:
        draft.recoverPasswordError = action.error;
        break;

      case AuthActions.RESET_PASSWORD:
        break;

      case AuthActions.RESET_PASSWORD_FAIL:
        draft.recoverPasswordError = action.error;
        break;

      case AuthActions.LOGOUT:
        draft.userToken = null;
        draft.user = null;
        break;

      case AuthActions.SET_SCORING_RESULT:
          draft.scoringResult = action.payload;
          break;

      default:
    }
    saveStoreInLocalstorage('AuthState', draft);
  });

export default authReducer;
