import { isValidEmail, isValidPassword, isValidNumber, isValidName, isValidSelect, isRFC, isValidCard, isValidPhone } from "./validation";

// LOGIN
export const LOGIN_EMAIL_INPUT_NAME = 'email';
export const LOGIN_PASSWORD_INPUT_NAME = 'password';
export const LOGIN_PASSWORD_RECOVER_INPUT_NAME = 'recover-password';
export const LOGIN_REPEAT_PASSWORD_INPUT_NAME = 'repeat-pass';

export const getLoginInputMetada = (inputName: string, extraValidation: any = {}) => {
  const inputsArray: any = {
    [LOGIN_EMAIL_INPUT_NAME]: {
      required: { value: true, message: 'required' },
      validate: {
        isValidEmail: (value: any) => isValidEmail(value),
      },
    },
    [LOGIN_PASSWORD_INPUT_NAME]: {
      required: { value: true, message: 'required' },
      minLength: { value: 8, message: 'too-short-password' },
    },
    [LOGIN_PASSWORD_RECOVER_INPUT_NAME]: {
      required: { value: true, message: 'required' },
      validate: {
        isValidPassword: (value: any) => isValidPassword(value),
      },
    },
    [LOGIN_REPEAT_PASSWORD_INPUT_NAME]: {
      required: { value: true, message: 'required' },
      validate: extraValidation,
    },
  };

  return inputsArray[inputName];
};

// Register 
export const FIRST_NAME_INPUT_NAME = 'first-name';
export const LAST_NAME_INPUT_NAME = 'last-name';
export const SECOND_LAST_NAME_INPUT_NAME = 'second-last-name';
export const REGISTER_EMAIL_INPUT_NAME = 'email';
export const PASSWORD_INPUT_NAME = 'password';
export const REPEAT_PASSWORD_INPUT_NAME = 'repeat-pass';
export const LIGHTPAY_TERMS_POLICY_INPUT_NAME = 'lightpay-terms-policy';
export const OWNS_TDC_INPUT_NAME = 'ownsTdc';
export const TDC_INPUT_NAME = 'tdc';
export const MORTGAGE_LOAN_INPUT_NAME = 'mortgageLoan';
export const AUTO_LOAN_INPUT_NAME = 'autoLoan';
export const RFC_INPUT_NAME = 'rfc';
export const INE_INPUT_NAME = 'ine';
export const CP_INPUT_NAME = 'cp';
export const STATE_INPUT_NAME = 'state';
export const CITY_INPUT_NAME = 'city';
export const BIRTHDATE_INPUT_NAME = 'birthdate';
export const COLONY_INPUT_NAME = 'colony';
export const STREET_INPUT_NAME = 'street';
export const PHONE_NUMBER_INPUT_NAME = 'phoneNumber';
export const CODE_NUMBER_INPUT_NAME = 'codeNumber';
export const CONFIRM_PASSWORD_INPUT_NAME = 'confirmPassowrd';
export const PAYMENT_TYPE = 'paymentType';
export const GENDER_INPUT_NAME = 'gender'

export const getRegisterInputMetadata = (inputName: string, extraValidation: any = {}) => {
  const inputsArray: any = {
    [PHONE_NUMBER_INPUT_NAME]: {
      required: { value: true, message: 'required' },
      validate: {
        isValidPhone: (value: any) => isValidPhone(value),
      },
    },
    [CODE_NUMBER_INPUT_NAME]: {
      required: { value: true, message: 'required' },
    },
    [CONFIRM_PASSWORD_INPUT_NAME]: {
      required: { value: true, message: 'required' },
      validate: extraValidation,
    },
    [FIRST_NAME_INPUT_NAME]: {
      required: { value: true, message: 'required' },
      minLength: { value: 4, message: 'too-short-name' },
      validate: {
        isValidName: (value: any) => isValidName(value),
      },
    },
    [LAST_NAME_INPUT_NAME]: {
      required: { value: true, message: 'required' },
      minLength: { value: 4, message: 'too-short-surname' },
      validate: {
        isValidName: (value: any) => isValidName(value),
      },
    },
    [PASSWORD_INPUT_NAME]: {
      required: { value: true, message: 'required' },
      minLength: { value: 8, message: 'too-short-password' },
      validate: {
        isValidPassword: (value: any) => isValidPassword(value),
      },
    },
    [REGISTER_EMAIL_INPUT_NAME]: {
      required: { value: true, message: 'required' },
      validate: {
        isValidEmail: (value: any) => isValidEmail(value),
      },
    },
    [REPEAT_PASSWORD_INPUT_NAME]: {
      required: { value: true, message: 'required' },
      validate: extraValidation,
    },
    [LIGHTPAY_TERMS_POLICY_INPUT_NAME]: {
      required: { value: true, message: 'accept-terms' },
    },
    [PAYMENT_TYPE]: {
      required: { value: true, message: 'choose one' },
    },
    [RFC_INPUT_NAME]: {
      required: { value: true, message: 'required' },
      validate: {
        isRFC: (value: any) => isRFC(value),
        // existRFC: async (value:any) => {
        //   store.dispatch<any>(
        //     loaderActions.loading(),
        //   )
        //   const res =  await LightpayApi.post<IServerResponseCheckEmail>('/auth/check-rfc', {
        //      rfc: value,
        //    });
        //    store.dispatch<any>(
        //     loaderActions.loaded(),
        //   )
        //    if(res.data.exists){
        //      store.dispatch<any>(
        //        toastActions.error( authActions.handleCustomErrors('REGISTER' , 'RFC_EXIST')),
        //      )
        //      return false;
        //    }
        // },
      },
    },
    [INE_INPUT_NAME]: {
      // required: { value: true, message: 'required' },
      validate: extraValidation,
    },
    [CP_INPUT_NAME]: {
      required: { value: true, message: 'required' },
      validate: {
        isValidNumber: (value: any) => isValidNumber(value),
      },
    },
    [STATE_INPUT_NAME]: {
      required: { value: true, message: 'required' },
      validate: {
        isValidSelect: (value: any) => isValidSelect(value),
      },
    },
    [CITY_INPUT_NAME]: {
      required: { value: true, message: 'required' },
      validate: {
        isValidSelect: (value: any) => isValidSelect(value),
      },
    },
    [BIRTHDATE_INPUT_NAME]: {
      required: { value: true, message: 'required' },
      // validate: {
      //   isValidDate: (value: any) => isValidDate(value),
      // },
    },
    [COLONY_INPUT_NAME]: {
      required: { value: true, message: 'required' },
    },
    [STREET_INPUT_NAME]: {
      required: { value: true, message: 'required' },
    },
    [OWNS_TDC_INPUT_NAME]: {
      // required: { value: true, message: 'required' },
    },
    [TDC_INPUT_NAME]: {
      required: { value: true, message: 'required' },
      minLength: { value: 4, message: 'too-short-tdc' },
      maxLength: { value: 4, message: 'too-short-tdc' },
      validate: {
        isValidNumber: (value: any) => isValidNumber(value),
      },
    },
    [MORTGAGE_LOAN_INPUT_NAME]: {
      // required: { value: true, message: 'required' },
    },
    [AUTO_LOAN_INPUT_NAME]: {
      // required: { value: true, message: 'required' },
    },
  };
  return inputsArray[inputName];
};

// USER'S CARD

export const SELECT_CARDS_INPUT_NAME = 'card';
export const ADRESS_CARD_INPUT_NAME = 'addressCard';

export const getCardInputMetadata = (inputName: string, extraValidation: any = {}) => {
  const inputsArray: any = {
    [SELECT_CARDS_INPUT_NAME]: {
      required: { value: true, message: 'required' },
      validate: {
        isValidSelect: (value: any) => isValidSelect(value),
      },
    }
  };
  return inputsArray[inputName];
};

// NEW USER'S CARD  

export const CARD_NUMBER_INPUT_NAME = 'cardNumber';
export const CARD_CVV_INPUT_INPUT = 'cvv2';
export const CARD_EXP_MONTH_INPUT = 'expirationMonth';
export const CARD_EXP_YEAR_INPUT = 'expirationYear';
export const CARD_HOLDERNAME_INPUT = 'holderName'
export const CARD_ADDRESS_CITY_INPUT = 'city';
export const CARD_ADDRESS_PC_INPUT = 'postalCode';
export const CARD_ADDRESS_STATE_INPUT = 'state'
export const CARD_ADDRESS_LINE1_INPUT = 'line1';
export const CARD_ADDRESS_LINE2_INPUT = 'line2';
export const CARD_ADDRESS_LINE3_INPUT = 'line3';

export const getNewCardInputMetadata = (inputName: string, extraValidation: any = {}, isItMX: boolean = false ) => {
  const inputsArray: any = {
    [CARD_NUMBER_INPUT_NAME]: {
      required: { value: true, message: 'required' },
      validate: {
        isValidCard: (value: any) => isValidCard(value),
      },
    },
    [CARD_CVV_INPUT_INPUT]: {
      required: { value: true, message: 'required' },
      minLength: { value: isItMX ? 4 : 3, message: isItMX ? 'too-short-cvv-mx' : 'too-short-cvv' },
      maxLength: { value: 4, message: 'too-short-cvv' },
      validate: {
        isValidNumber: (value: any) => isValidNumber(value),
      },
    },
    [CARD_EXP_MONTH_INPUT]: {
      required: { value: true, message: 'required' },
      minLength: { value: 2, message: 'too-short-month' },
      validate: extraValidation,  
      
    },
    [CARD_EXP_YEAR_INPUT]: {
      required: { value: true, message: 'required' },
      minLength: { value: 2, message: 'too-short-month' },
      validate: extraValidation,  
    },
    [CARD_HOLDERNAME_INPUT]: {
      required: { value: true, message: 'required' },
      minLength: { value: 4, message: 'too-short-name' },
      validate: {
        isValidName: (value: any) => isValidName(value),
      },
    },
    [CARD_ADDRESS_CITY_INPUT]: {
      required: { value: true, message: 'required' },
      minLength: { value: 3, message: 'too-short-name' },
      validate: {
        isValidName: (value: any) => isValidName(value),
      },
    },
    [CARD_ADDRESS_PC_INPUT]: {
      required: { value: true, message: 'required' },
      validate: {
        isValidNumber: (value: any) => isValidNumber(value),
      },
    },
    [CARD_ADDRESS_STATE_INPUT]: {
      required: { value: true, message: 'required' },
      validate: {
        isValidSelect: (value: any) => isValidSelect(value),
      },
    },
    [CARD_ADDRESS_LINE1_INPUT]: {
      required: { value: true, message: 'required' },
    },
    [CARD_ADDRESS_LINE2_INPUT]: {
    },
    [CARD_ADDRESS_LINE3_INPUT]: {
    },
  };
  return inputsArray[inputName];
};

  // EXTRA BANK DATA

export const BANK_INPUT_NAME = 'bankName';
export const BANK_USER_INPUT = 'username';
export const BANK_PASSOWRD_INPUT = 'passoword';
export const BANK_EMAIL_INPUT = 'email';
export const BANK_TOKEN_INPUT = 'token';

export const getBankInputMetadata = (inputName: string, extraValidation: any = {}) => {
  const inputsArray: any = {
    [BANK_INPUT_NAME]: {
      required: { value: true, message: 'required' },
      validate: {
        isValidName: (value: any) => isValidName(value),
      },
    },

    [BANK_USER_INPUT]: {
      required: { value: true, message: 'required' },
      minLength: { value: 3, message: 'too-short-name' },
    },

    [BANK_PASSOWRD_INPUT]: {
      required: { value: true, message: 'required' },
      minLength: { value: 3, message: 'too-short-password-bank' },
    },

    [BANK_TOKEN_INPUT]: {
      required: { value: true, message: 'required' },
    },

    [BANK_EMAIL_INPUT]: {
      required: { value: true, message: 'required' },
      validate: {
        isValidEmail: (value: any) => isValidEmail(value),
      },
    },
  }


  return inputsArray[inputName];
};