import produce from 'immer';

import { IToastActions, IToastState, IToastReducer } from './types';

export const initialState: IToastState = {
  toasts: [],
  lastToast: null,
};

const toastsReducer: IToastReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case IToastActions.ADD_TOAST:
        draft.toasts.push(action.toast);
        draft.lastToast = action.toast;
        break;

      case IToastActions.REMOVE_TOAST:
        draft.toasts = draft.toasts.filter(toast => toast.id !== action.id);
        break;

      default:
    }
  });

export default toastsReducer;
