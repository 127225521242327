import { Validator } from 'class-validator';
// import { isDate, isValid, isExists } from 'date-fns/esm';
import PasswordValidator from 'password-validator';

// EMPTY
export const isEmpty = (value: any) => value === null || value === undefined || value === '';

// EMAIL
export const isValidEmail = (mail: string) => {
  const validator = new Validator();
  return validator.isEmail(mail);
};

// DATE
export const isValidDate = (date: string) => {
  return false
  // let newDate: string[] | number[] = date.split('/');
  // if (newDate[2].length < 4) {
  //   return false;
  // }
  // newDate = newDate.map(string => parseInt(string));

  // return isExists(newDate[2], newDate[1] - 1, newDate[0]);
};

// URL
export const isValidUrl = (url: string) => {
  const validator = new Validator();

  if (
    !validator.isURL(url, {
      protocols: ['https'],
      require_protocol: true,
      require_valid_protocol: true,
    })
  ) {
    return false;
  }

  if (url.indexOf('www.') !== -1) {
    const dividedUrl = url.split('//');
    const newUrl = dividedUrl[1].split('.');
    if (newUrl[1].length >= 2) {
      return true;
    } else return false;
  } else {
    const dividedUrl = url.split('//');
    const newUrl = dividedUrl[1].split('.');
    if (newUrl[0].length >= 2) {
      return true;
    } else return false;
  }
};

// VALID NUMBER STRING
export const isValidNumber = (number: string) => {
  const validator = new Validator();
  return validator.isNumberString(number);
};

// PHONE INTERNATIONAL
export const isValidPhone = (number: string) => {
  const validator = new Validator();
  // change MX to ZZ probably !
  return number ? validator.isPhoneNumber(number, 'ZZ') : true;
};

// FOR SELECTS
export const isValidSelect = (text: string) => {
  if (text === 'Seleccionar') {
    return false;
  } else return true;
};

// VALID PASSPORT OR IFE
export const isValidIdentification = (text: string) => {
  const passport = new RegExp('^[A-Z][0-9]{8}$');
  if (!passport.test(text)) {
    return false;
  } else return true;
};

// VALID PDF
export const isValidPdf = (file: File) => {
  if (!file.type) return false;
  const type = file.type.split('/');
  if (type[1] !== 'pdf') {
    return false;
  } else return true;
};

// VALID XML
export const isValidXML = (file: File) => {
  if (!file.type) return false;
  const type = file.type.split('/');
  if (type[1] !== 'xml') {
    return false;
  } else return true;
};

// VALID PNG/JPG/JPEG
export const isValidImage = (file: File) => {
  if (!file.type) return false;
  const arrayFiles: string[] = ['png', 'jpeg'];
  const type = file.type.split('/');
  if (!arrayFiles.includes(type[1])) {
    return false;
  } else return true;
};

// VALID FILE SIZE
export const isValidSize = (file: File) => {
  if (!file.type) return false;
  if (file.size > 6000000) {
    return false;
  } else return true;
};

// CARD
export const isValidCard = (text: string) => {
  // if (!text || text.length < 18) return false;

  const number = text.split(' ').join('');
  if (number.length < 15 || number.length > 16) return false;

  if (number.length === 15 && number.substr(0, 2) !== '34' && number.substr(0, 2) !== '37') return false;

  if (number.length === 16) {
    const number = text.split(' ').join('');
    console.log((window as any).OpenPay.card.validateCardNumber(number), number)
    return (window as any).OpenPay.card.validateCardNumber(number)
    // const firstDigits = parseInt(number.substr(0, 2), 10);
    // if (number.substr(0, 1) !== '4' && (firstDigits < 51 || firstDigits > 55)) return false;
  }

  return number.match(/[0-9]/);

};

export const hasNumber = (text: string) => {
  return /\d/.test(text);
};

export const isNumeric = (text: string) => {
  return /^\d+$/.test(text);
};

// name and last name
export const isValidName = (text: string) => {
  if (/(?=.*[!¡@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?\d])/.test(text)) {
    return false;
  } else return true;
};

// rfc mexico
export const isRFC = (rfc: string) =>
  /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/.test(rfc);

// password
export const isValidPassword = (password: string) => {
  const validator = new PasswordValidator();
  validator
    .has()
    .uppercase()
    .has()
    .lowercase()
    .has()
    .digits()
    .has()
    .symbols();

  return validator.validate(password) as boolean;
};

// password handle error
// export const getPasswordErrors = (password: string) => {
//   const validator = new PasswordValidator();
//   validator
//     .has()
//     .uppercase()
//     .has()
//     .lowercase()
//     .has()
//     .digits();

//   const listErrors = validator.validate(password, { list: true }) as string[];
//   const messages = [];
//   if (listErrors.includes('uppercase')) {
//     messages.push('una mayúscula');
//   }

//   if (listErrors.includes('lowercase')) {
//     messages.push('una minúscula');
//   }

//   if (listErrors.includes('digits')) {
//     messages.push('un número');
//   }

//   let message = 'Falta ';
//   if (listErrors.length > 1) {
//     message = 'Faltan caracteres, ';
//   }

//   return message.concat(messages.join(' , '));
// };
