import styled from 'styled-components';

export const AccordionStyle = styled.div`
  position: relative;
  z-index: 0;
  transition: 0.3s ease-in-out all;
  background: white;
  padding: 10px;
  border-radius: 3px;

  .accordion-head {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
    transition: 0.3s ease-in-out all;

    &.disabled {
      pointer-events: none;
    }

    &__title {
      flex: auto;
      justify-content: center;
    }

    .accordion-head-icon {
      width: 28px;
      height: 28px;
      transition: 0.3s ease-in-out;
      position: relative;
      &__bottom {
        width: 12px;
        height: 2px;
        position: absolute;
        top: 12px;
        left: 6px;
        background: #1a4ccd;
      }
      &__top {
        width: 12px;
        height: 2px;
        position: absolute;
        top: 12px;
        left: 6px;
        background: #1a4ccd;
        transition: 0.3s ease-in-out all;
        transform: rotate(90deg);
      }
    }
  }
  .accordion-drop {
    max-height: 0;
    height: 100%;
    transition: 0.3s ease-in-out;
    z-index: -1;
  }
  .accordion-drop-content {
    opacity: 0;
    padding-top: 23px;
    transition: 0.3s ease-in-out;
    margin-bottom: 10px;
    font-size: 14px;
    color: black;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    font-weight: normal;
    span {
      color: #1A4CCD;
    }
  }

  &.active {
    .accordion-head {
      .accordion-head-icon {
        &__top {
          transform: rotate(0deg);
        }
      }
    }
    .accordion-drop {
    }
  }

  &.error {
    background: #faebf1;
    .accordion-head {
      &__title {
        color: #e49cbb;
      }
    }
  }
`;
