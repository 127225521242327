import produce from 'immer';

import { IErrorsActions, IErrorsState, IErrorReducer } from './types';

export const initialState: IErrorsState = {
  title: '',
  subtitle: '',
  isActvieModal: false
};

const loaderReducer: IErrorReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case IErrorsActions.SET_ERROR:
        draft.title = action.payload.title;
        draft.subtitle = action.payload.subtitle;
        draft.isActvieModal = true;
        break;
      case IErrorsActions.RESET_ERROR:
        draft.title = '';
        draft.subtitle = '';
        draft.isActvieModal = false;
        break;

      default:
    }
  });

export default loaderReducer;
