import React from "react";
import ModalContainer from "../modal-container/modal-container";
import { ModalErrorStyle } from "./modal-error-style";
import { IModalErrorProps } from './modal-error-types';
import { useSelector } from 'react-redux';
import { paymentSelector } from "../../../store/payment/selectors";

const ModalErrorGlobalCard: React.FC<IModalErrorProps> = (props: IModalErrorProps) => {
  const { modalConfig, title, subtitle, } = props;
  if(title === 'Ya tienes una compra activa con Slightpay') return(
    <ModalContainer type="light" {...modalConfig}>
    <ModalErrorStyle>
      <div className="modal-error-title">
      <>
        <p>
          Slightpay se compromete a asegurarte que cumplas con tu
          plan de pagos, es por eso que cada solicitud de compra lleva
          un proceso de validación antes de aprobar. Una vez que termines
          de pagar alguna compra "activa", podrás realizar una nueva compra.
        </p>
        <br/>
        <p>
          Entre más uses Slightpay, podemos confirmar que tienes cumplimiento
          con tus planes de pago e irás aumentando la cantidad de compras activas
          permitidas por nuestro sistema.
        </p>
        <br/>
        <p>
          Sabemos que esto puede ser frustrante, pero lo hacemos por que estamos
          comprometidos con nuestros clientes para fomentar el gasto y compras responsables.
        </p>
      </>
      </div>
      <div className="modal-error-text">
        <p>Por favor, si el problema persiste pongase en contacto con un administrador de Slightpay</p>
      </div>
    </ModalErrorStyle>
  </ModalContainer>
  )
  return (
    <ModalContainer type="light" {...modalConfig}>
      <ModalErrorStyle>
        <div className="modal-error-title">
          <p>{ title}</p>
        </div>
        <div className="modal-error-text">
          <p>{subtitle}</p>
        </div>
      </ModalErrorStyle>
    </ModalContainer>
  );
};
export default ModalErrorGlobalCard;
