import { IBaseAction } from '../base-action.interface';

export enum ILoadingActions {
  LOADING = 'LOADING',
  LOADED = 'LOADED',
  LOADING_GLOBAL = 'LOADING_GLOBAL',
  LOADED_GLOBAL = 'LOADED_GLOBAL',
}

export interface ILoadingState {
  readonly loading: boolean;
  readonly loadingGlobal: boolean;
}

export interface ILoadingReducer {
  (state: ILoadingState, action: ILoadingActionsTypes): ILoadingState;
}

export interface ILoadingAction extends IBaseAction {
  type: ILoadingActions.LOADING;
}

export interface ILoadedAction extends IBaseAction {
  type: ILoadingActions.LOADED;
}

export interface ILoadingActionGlobal extends IBaseAction {
  type: ILoadingActions.LOADING_GLOBAL;
}

export interface ILoadedActionGlobal extends IBaseAction {
  type: ILoadingActions.LOADED_GLOBAL;
}

export type ILoadingActionsTypes = ILoadingAction | ILoadedAction | ILoadingActionGlobal | ILoadedActionGlobal;
