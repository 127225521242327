import React, { useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import { arrowDownBlue, arrowDownGrey } from "../../assets/images/images";
import { ERROR_MESSAGES } from "../../constants/errorMessages";
import { SelectBoxContainer } from "./select-box-style";
import { ISelectBoxProps, IUseSelectType } from "./select-box-types";

const useSelect: IUseSelectType = (
  initialValue: string,
  props: ISelectBoxProps
) => {
  const [value, setValue] = useState(initialValue);
  const [showList, setShowList] = useState(false);

  const handleElementClick = (show: boolean, val: string) => {
    if (props.optionsArray) {
      setValue(val);
    } else if (props.options) {
      setValue(props.options[val]);
    }

    setShowList(show);

    if (props.onChange) {
      props.onChange(val, props.name);
    }
  };

  return {
    value,
    showList,
    handleElementClick
  };
};

const SelectBox: React.FC<ISelectBoxProps> = (props: ISelectBoxProps) => {
  const {
    className,
    disabled,
    errorCode,
    labelText,
    options,
    optionsArray,
    required,
    value,
    rightLabel,
    tempValue
  } = props;

  const actions = useSelect(tempValue || "Seleccionar", props);

  const errorText = errorCode ? ERROR_MESSAGES[errorCode] : "";

  return (
    <SelectBoxContainer
      className={`${errorCode ? "error" : ""} ${className} ${
        actions.showList ? "show-list" : ""
      } ${
        disabled ? "disabled" : ""
      } ${required ? "required" : ""} ${errorCode ? "error" : ""} ${
        actions.value !== "" ? "complete" : ""
      }`}
    >
      <div className="select-box-topbar">
        <div className="select-box-topbar-label">
          <p>{labelText}</p>
        </div>
        {rightLabel && 
        <div className="select-box-topbar-label right" onClick={()=> rightLabel && rightLabel.onClick()}>
          <p>{rightLabel.text}</p>
        </div>}
      </div>

      <div
        className="select-box-main"
        onClick={() =>
          actions.handleElementClick(!actions.showList, actions.value)
        }
      >
        <div className="select-box-main-field">
          <p>{actions.value}</p>
        </div>
        <div className="select-box-icon">
          <img
            src={disabled ? arrowDownGrey : arrowDownBlue}
            alt="down arrow"
          />
        </div>
      </div>
      <ul className="select-box-main-list">
        <Scrollbars
          renderTrackVertical={props => (
            <div {...props} className="track-vertical" />
          )}
          renderThumbVertical={props => (
            <div {...props} className="thumb-vertical" />
          )}
          style={{ width: "100%" }}
          autoHide={true}
          autoHideTimeout={200}
          autoHideDuration={200}
          autoHeight={true}
          autoHeightMin={60}
          autoHeightMax={200}
        >
          {optionsArray &&
            optionsArray.map((option: string, index) => (
              <li
                key={index}
                onClick={() => actions.handleElementClick(false, option)}
              >
                {option}
              </li>
            ))}

          {options &&
            Object.keys(options).map((key: string, index) => (
              <li
                key={index}
                onClick={() => actions.handleElementClick(false, key)}
              >
                {options[key]}
              </li>
            ))}
        </Scrollbars>
      </ul>

      <div className="select-box-error">{errorCode && errorText}</div>
    </SelectBoxContainer>
  );
};

export default SelectBox;
