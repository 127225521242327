import React, { useEffect, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { useSelector, useDispatch } from 'react-redux';
import { toastSelector } from '../../store/toasts/selectors';
import { toastActions } from '../../store/toasts/actions';
import { IToast } from '../../store/toasts/types';

export const useToaster = () => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const lastToast = useSelector(toastSelector.lastToast());

  const triggerToastsSuccess = () => {
    dispatch(toastActions.success('Esta es un error de prueba'));
  };
  const triggerToastsWarning = () => {
    dispatch(toastActions.warning('Esta es un error de prueba'));
  };
  const triggerToastsError = () => {
    dispatch(toastActions.error('Esta es un error de prueba'));
  };

  const handleToastNotification = (notification: IToast) => {
    if (notification) addToast(notification.message, notification.options);
  };

  return {
    triggerToastsSuccess,
    triggerToastsWarning,
    triggerToastsError,
    handleToastNotification,
    lastToast,
  };
};

const Toaster: React.FC = () => {
  const { lastToast, handleToastNotification } = useToaster();
  const initState = { handleNoti: handleToastNotification };
  const [toastHandler] = useState(initState);

  useEffect(() => {
    if (lastToast) toastHandler.handleNoti(lastToast);
  }, [toastHandler, lastToast]);

  return <></>;
};

export default Toaster;
