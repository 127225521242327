import React from "react";
import { modalSuccess, modalPrepending } from "../../../assets/images/images";
import CardImageShadow from "../../card-image-shadow/card-image-shadow";
import ModalContainer from "../modal-container/modal-container";
import { ModalSuccessStyle } from "./modal-success-style";
import { IModalSuccessProps } from './modal-sucess-types';

const ModalSuccess: React.FC<IModalSuccessProps> = (props: IModalSuccessProps) => {
  const { modalConfig, purchase } = props;
  return (
    <ModalContainer
      type="light"
      {...modalConfig}
    >
      <ModalSuccessStyle>
        <div className="modal-success-title">
          {purchase?.status === 'PREPENDING' ?
             <p>
             <span className="special">¡Estamos terminando de validar tu compra!</span> <br />
             <p style={{fontSize: "20px"}}>Ponte cómodo… recibirás un correo con el status final de tu compra en un máximo de 24 horas.</p><br />
             <span className="black">Si no es posible verificar alguno de los datos que están pendientes, la reserva que se generó en tu tarjeta será eliminada y el dinero aparecerá nuevamente en tu cuenta bancaria</span>
           </p>
            : <p>
              <span className="special">¡Wooww!</span> <br />
              Has pagado tu primera cuota y tu plan de pagos con <span>Slightpay</span> se ha
              programado con éxito!<br /> <br />
              <span className="black">Recibirás un correo con el ticket de compra</span>
            </p>
          }
        </div>
        <div className="modal-success-text">
          <p>Puedes cerrar esta pantalla.</p>
        </div>
        <CardImageShadow image={purchase?.status === 'PREPENDING' ? modalPrepending : modalSuccess} />
      </ModalSuccessStyle>
    </ModalContainer>
  );
};

export default ModalSuccess;
