import styled from "styled-components/macro";
import { colors } from "../../assets/colors/colors";
import { InputColors } from "../../assets/colors/input-colors/input-colors";

export const PasswordBoxContainer = styled.div`
  .password-box-topbar {
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 9;
    margin-bottom: 8px;
  }

  .password-box-topbar-label {
    color: ${colors["TX–001-30"]};
    text-transform: uppercase;
  }

  .password-box-topbar-icon {
    position: relative;
    z-index: 9;
    display: inherit;
    img {
      max-width: 18px;
      cursor: pointer;
    }
  }

  .password-box-main-field {
    position: relative;
    margin-bottom: 4px;
  }

  input {
    font-family: "gilroy";
    line-height: 24px;
    font-size: 16px;
    width: 100%;
    padding: 12px 0 11px;
    padding-right: 30px;
    transition: all 0.2s ease-in-out;
    color: ${InputColors.value.default};
    color: ${InputColors.input.default};
    min-height: 24px;
    border-bottom: 1px solid ${InputColors.input.default};

    &::placeholder  {
      color: ${InputColors.placeholder.default};

      &:focus {
        color: ${InputColors.placeholder.focus};
      }
    }

    &:focus {
      color: ${InputColors.value.focus};
      border-bottom: 1px solid ${InputColors.input.focus};
    }

    &:hover {
      color: ${InputColors.value.hover};
      border-bottom: 1px solid ${InputColors.input.hover};

      &::placeholder  {
        color: ${InputColors.placeholder.hover};
      }
    }

    &:disabled {
      color: ${InputColors.value.disabled};
      background: white;
      border-bottom: 1px solid ${InputColors.input.disabled};

      &:focus {
        color: ${InputColors.value.disabled};
        border-bottom: 1px solid ${InputColors.input.disabled};
      }
    }
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .password-box-main {
    position: relative;
  }

  .password-box-icon {
    position: absolute;
    top: 10px;
    right: 0px;
    max-width: 30px;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.5;

    &.active {
      opacity: 1;
    }
    img {
      cursor: pointer;
    }
  }

  .password-box-tooltip {
    position: absolute;
    top: calc(100% + 12px);
    z-index: 29;
    transform: translateX(-50%);
    width: 260px;
    right: 0;
  }

  .password-box-error {
    color: ${colors["ER-001-100"]};
  }

  &.error {
    input {
      color: ${InputColors.value.error};
      border-bottom: 1px solid ${InputColors.input.error};

      &::placeholder {
        color: ${InputColors.placeholder.error};
      }
    }

    .password-box-error {
      color: ${InputColors.errorText};
    }

    .password-box-icon {
      filter: hue-rotate(90deg);
    }
  }

  .password-box-format-rules {
    margin-top: 8px;
    display: flex;
    justify-content: space-between;
  }

  .password-box-format-rules-text {
    color: ${colors["PR––001-100"]};
    p {
      margin-bottom: 8px;
    }
    li {
      margin-bottom: 4px;

      &.ok {
        color: ${colors["SU–001-100"]};
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .password-box-format-rules-level-container {
    display: flex;
    align-items: flex-end;
    height: 16px;
  }

  .password-box-format-rules-level-item {
    background: ${colors["SC–001-10"]};
    width: 4px;
    margin-right: 3px;

    &.ok {
      background: ${colors["SU–001-100"]};
    }

    &.bar-1 {
      height: 25%;
    }

    &.bar-2 {
      height: 50%;
    }

    &.bar-3 {
      height: 75%;
    }

    &.bar-4 {
      height: 100%;
    }

    &:last-child {
      margin-right: 0;
    }
  }
`;
