import styled from "styled-components";

export const ModalAdressStyle = styled.div`
  
  
  max-width: 436px;

  .recomendation{
    background: #F7F5F5;
    color: black;
    padding: 5px;
    margin-bottom: 25px;
    text-align: center;
    box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
    font-size: 12px
     .banks {
       color: #1A4CCD;
     }
  }
  
  .credit-card-duplicated{
    background: rgba(255,0,0,0.5);
    color: white;
    padding: 5px;
    margin: 10px 0px;
    text-align: center;
    box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  }
  .credit-card-back {
    font-size: 14px;
    line-height: 24px;
    font-weight: 300;
    text-decoration: underline;
    color: #1A4CCD;
    margin-top: 42px;
    cursor: pointer;
  }
    
  .modal-adress-title {
    margin-bottom: 16px;
  }
  .descriptionModalBank{
    @media (max-width: 768px) {
      font-size: 15px;
    }
  }


  .update-address-title {
    width: 100%;
    font-family: "Gilroy";
    font-size: 20px;
    line-height: 26px;
    color: #01030C;
    margin-bottom: 20px; 

    span {
        color: #1A4CCD;
    }
  }
`;
