export const icons: any = {
  arrowBack: require('./files/arrow-back.svg'),
  iconClose: require('./files/icon-close.svg'),
  iconCloseError: require('./files/icon-close-error.svg'),
  iconCloseSuccess: require('./files/icon-close-success.svg'),
  iconCloseWarning: require('./files/icon-close-warning.svg'),
  alertError: require('./files/alert-error.svg'),
  alertSuccess: require('./files/alert-success.svg'),
  alertWarning: require('./files/alert-warning.svg'),
  logoFullBack: require('./files/logo-full-black.svg'),
};
