import React from 'react';
import { HashRouter as Router, Redirect, Route, Switch } from 'react-router-dom';

// components
import MainLayout from '../components/main-layout/main-layout';
import Login from '../containers/public/login/login';
import Registre from '../containers/public/registre/registre';
import { paymentSelector } from '../store/payment/selectors';
import { IPurchase } from '@aluxion-lightpay/interfaces';
import { useSelector } from 'react-redux';
import InfoQR from '../containers/public/info-qr/info-qr';

const AuthorizationRoutes: React.FC = () => {
  const purchasesData = useSelector(paymentSelector.purchasesData()) as IPurchase;

  return (
      <Router>
        <MainLayout purchases={purchasesData}>
          <Switch>
            {/* Aquí irán las rutas de login, register y todas las rutas para autorizar al user */}
            <Route exact path="/registre" component={Registre} />
            <Route exact path="/info" component={InfoQR} />
            <Route exact path="/:token" component={Login} />
            <Route path="/not-found" component={() => <h1>NOT FOUND AUTH</h1>} />
            <Route render={() => <Redirect to={`/${sessionStorage.getItem('buyToken')}`} />} />
            {/* ${sessionStorage.getItem('buyToken')} */}
          </Switch>
        </MainLayout>
      </Router>
  );
};

export default AuthorizationRoutes;
