import { store } from '../index';
import { toastActions } from './../store/toasts/actions';
import { paymentActions } from '../store/payment/actions';

export const handleReasonsError = (data: any) => {
  if (data.reasons) {
    store.dispatch<any>(paymentActions.addPurchaseError(data.reasons));
    data.reasons.forEach( reason => {
      store.dispatch<any>(
        toastActions.error( reason.message  || 'Ocurrió un error inesperado. Intente más tarde.'),
      );
    })
  }
  else if (data.message)
    if(data.message === 'Email not verified') 
    store.dispatch<any>(
      toastActions.error( 'Verifica tu email antes de continuar.' ),
    )
    else
    store.dispatch<any>(
      toastActions.error( data.message),
    )
  else 
    store.dispatch<any>(
      toastActions.error('Ocurrió un error inesperado. Intente nuevamente más tarde.'),
    )
}