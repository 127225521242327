import styled from "styled-components";

export const RegistreStyle = styled.div`
  padding: 40px;
  .main-form-wrapper {
    margin: 0 auto;
    max-width: 438px;
  }

  .form-ul {
    .form-ul-input {
      margin-bottom: 18px;

      li {
        margin-bottom: 0px;
      }
      &__item {
        margin-top: 12px;
      }
    }
    li {
      margin-bottom: 18px;
      display: flex;
      justify-content: space-between;
    }
  }

  .new-credit-card-title {
    margin-bottom: 24px;
    color: #1A4CCD;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
  }
  .new-credit-card-form {
    margin-bottom: 40px;
    &__section {
      margin-bottom: 32px;
    }
  }
  .new-credit-card-text {
    margin-bottom: 42px;
    font-weight: 300;
    font-size: 14px;
    line-height: 22px;
    p{
      margin-bottom: 24px;
      span {
        color: #1A4CCD;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .new-credit-card-button {
    margin-bottom: 42px;
  }

  .new-credit-card-back {
    font-size: 14px;
    line-height: 24px;
    font-weight: 300;
    text-decoration: underline;
    color: #1A4CCD;
    margin-bottom: 32px;
    cursor: pointer;
  }

`;
