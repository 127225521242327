import { combineReducers } from 'redux';

import loaderReducer from './loader/reducer';
import authReducer from './auth/reducer';
import toastsReducer from './toasts/reducer';
import cardReducer from './card/reducer';
import modalReducer from './modal/reducer';
import paymentReducer from './payment/reducer';
import errorsReducer from './errors/reducer';

export default combineReducers({
  auth: authReducer,
  toasts: toastsReducer,
  load: loaderReducer,
  card: cardReducer,
  modal: modalReducer,
  payment: paymentReducer,
  errors: errorsReducer
});