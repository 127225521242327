import produce from 'immer';

import { ILoadingActions, ILoadingState, ILoadingReducer } from './types';

export const initialState: ILoadingState = {
  loading: false,
  loadingGlobal: false
};

const loaderReducer: ILoadingReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case ILoadingActions.LOADING:
        draft.loading = true;
        break;
      case ILoadingActions.LOADED:
        draft.loading = false;
        break;
      case ILoadingActions.LOADING_GLOBAL:
        draft.loadingGlobal = true;
        break;
      case ILoadingActions.LOADED_GLOBAL:
        draft.loadingGlobal = false;
        break;

      default:
    }
  });

export default loaderReducer;
