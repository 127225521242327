import React, { useEffect, useState } from "react";
import MainButton from "../../main-button/main-button";
import PasswordBox from "../../password-box/password-box";
import ModalContainer from "../modal-container/modal-container";
import { ModalCodeStyle } from "./modal-code-style";
import { IModalCodeProps } from './modal-code-types'
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { CODE_NUMBER_INPUT_NAME, getRegisterInputMetadata } from "../../../utils/login-input-names";
import { getRegisterFormErrors } from "../../../utils/login-input-validations";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../../../store/auth/actions";
import { modalActions } from "../../../store/modal/actions";
import amplitude from 'amplitude-js'
import { authSelectors } from "../../../store/auth/selectors";
import SendApiConversion from "../../../constants/apiConversionFb";
import { paymentSelector } from "../../../store/payment/selectors";


const ModalCode: React.FC<IModalCodeProps> = ({ modalConfig, onClick, number, setShowScoring }) => {
  const { register, handleSubmit, errors, control } = useForm();
  const purchasesData: any = useSelector(paymentSelector.purchasesData());
  const dispatch = useDispatch();
  const history = useHistory();
  const phoneNumber = useSelector(authSelectors.getUserPhone());
  const me = useSelector(authSelectors.getMe());
  //const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [flag, setFlag] = useState<boolean>(true);
  const closeModal = async () => {
    await dispatch(modalActions.hide());
  };

  const openModal = async (id: number | string) => {
    await dispatch(modalActions.show(id));
  };

  const handleCodeSent = async (data: any) => {
    try {
      await dispatch(authActions.getCodePhone());
    } catch (error) {
      // here goes modal
    }
  }

  const handleData = async (data: any) => {
    try {
      await dispatch(authActions.checkPhone(data[CODE_NUMBER_INPUT_NAME]));
      await dispatch(authActions.updatePhoneVerified());
      dispatch(authActions.setJwt(sessionStorage.getItem('token')));
      //amplitude.getInstance().logEvent('phone verified');
      setShowScoring && setShowScoring(true)
      window.location.reload();
    } catch (error) {
      console.log(error)
      // here goes modal
      amplitude.getInstance().logEvent('error encountered', { 'error_type': 'verify phone', 'error_reason': JSON.stringify(error) });
    }
  }

  const modifyPhone = () => {
    openModal("ModifyPhone");
  }

  return (
    <ModalContainer {...modalConfig}>
      <ModalCodeStyle>
        <div className="modal-code-title">
          <span>¡Bienvenido a SlightPay!</span>
          <p>Te hemos enviado un código de verificación por SMS al numero <span>{phoneNumber}</span>, por favor,
            introdúcelo a continuación:
          </p>
        </div>

        <div className="modal-code-text">
          <p>
            Además, en tu email te espera un link para que puedas acceder a tu cuenta de SlightPay.
          </p>
        </div>
        <div className="modal-code-input">
          <PasswordBox
            name={CODE_NUMBER_INPUT_NAME}
            labelText="código de verificación"
            onKeyPress={handleSubmit(handleData)}
            inputRef={register(
              getRegisterInputMetadata(CODE_NUMBER_INPUT_NAME)
            )}
            errorCode={getRegisterFormErrors(errors, CODE_NUMBER_INPUT_NAME)}
          />
        </div>
        <div className="modal-code-send">
          <div className="modal-code-send__text">
            <p>¿No te ha llegado el código? </p>
          </div>
          <div
            className="modal-code-send__button"
            onClick={handleCodeSent}
          >
            <p>Reenviar código</p>
            <div
            onClick={modifyPhone}
          >
            <p>Número incorrecto</p>
          </div>
          </div>

        </div>
        <div className="modal-code-button">
          <MainButton
            text="Verificar y registrar"
            type="primary"
            onClick={handleSubmit(handleData)}
          />
        </div>
      </ModalCodeStyle>
    </ModalContainer>
  );
};
export default ModalCode;
