import styled from 'styled-components';


export const WalletsStyle = styled.div`
  padding: 100px 40px;
  width: 100%;
  .main-form-wrapper {
    margin: 0 auto;
    max-width: 438px;
  }
  .credit-card-title {
    font-size: 18px;
    line-height: 22px;
    font-weight: 600;
  }
  .credit-card-button {
    margin-bottom: 42px;
  }

  .credit-card-back {
    font-size: 14px;
    line-height: 24px;
    font-weight: 300;
    text-decoration: underline;
    color: #1A4CCD;
    margin-top: 45px;
    cursor: pointer;
  }

  .back-to-pay{
    font-size: 14px;
    line-height: 24px;
    font-weight: 300;
    text-decoration: underline;
    color: #1A4CCD;
    margin-top: 20px;
    cursor: pointer;
  }

  .margin{
    margin-bottom: 10px;
  }
  
  .payment-method-item {
    margin: 10px;
    background: #1A4CCD;
    color: white;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    cursor: pointer;
    @media (max-width: 540px) {
      width: 93% !important;
    } 
  }

  .wallets-items {
    flex: 1 1 30px;
    flex-direction: column;
    margin: 15px;
    color: white;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    cursor: pointer;
    width: 50%;
  }
  .wallets-items_text {
    text-align: center; 
    color: gray;
    font-size: 10px;
    line-height: 10px;
    margin-top: 10px;
  }

  .wallet-img {
    width: 80px;
    height: 80px;
  }

  .logoutButton {
    margin: 5px;
    background: #1A4CCD;
    color: white;
    border-radius: 5px;
    cursor: pointer;
  }

  .logoutButton:hover {
    background: #306aff;
  }

  .payment-method-item:hover {
    background: #306aff;
  }

  .final-buttons {
    cursor: pointer;
    width: 100%;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    background: #1A4CCD;
    border: 1px solid #1A4CCD;
    color: white;
    margin-bottom: 20px;
  }
@media (max-width: 1024px) {
  padding: 80px 20px;
}  
`