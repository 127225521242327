import { HOW_KNEW_ABOUT_OPENPAY, STATES } from "@aluxion-lightpay/interfaces";
import React, { useCallback, useEffect, useState } from "react";
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga';
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useStore, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { logo } from "../../../assets/images/images";
import Attach from "../../../components/attach/attach";
import { IDefaultAttachImagesObjType } from "../../../components/attach/doble-image-attach/doble-image-attach-types";
import CalendarBox from "../../../components/calendar-box";
import Checkbox from "../../../components/checkbox/checkbox";
import { FormGridRow } from "../../../components/form-grid-row/form-grid-row";
import InputBox from "../../../components/input-box/input-box";
import MainButton from "../../../components/main-button/main-button";
import ModalCode from "../../../components/modals/modal-code/modal-code";
import PasswordBox from "../../../components/password-box/password-box";
import SelectBox from "../../../components/select-box/select-box";
import SlideCheckbox from "../../../components/slide-checkbox/slide-checkbox";
import { authActions } from "../../../store/auth/actions";
import { IRegisterForm } from "../../../store/auth/types";
import { LightpayApi, IFormatError } from '../../../store/restclient';
import { toastActions } from '../../../store/toasts/actions';
import {
  AUTO_LOAN_INPUT_NAME,
  BIRTHDATE_INPUT_NAME,
  CITY_INPUT_NAME,
  COLONY_INPUT_NAME,
  CP_INPUT_NAME,
  FIRST_NAME_INPUT_NAME,
  getRegisterInputMetadata,
  INE_INPUT_NAME,
  LAST_NAME_INPUT_NAME,
  SECOND_LAST_NAME_INPUT_NAME,
  LIGHTPAY_TERMS_POLICY_INPUT_NAME,
  MORTGAGE_LOAN_INPUT_NAME,
  OWNS_TDC_INPUT_NAME,
  PASSWORD_INPUT_NAME,
  REGISTER_EMAIL_INPUT_NAME,
  REPEAT_PASSWORD_INPUT_NAME,
  RFC_INPUT_NAME,
  STATE_INPUT_NAME,
  STREET_INPUT_NAME,
  TDC_INPUT_NAME,
  CONFIRM_PASSWORD_INPUT_NAME,
  PHONE_NUMBER_INPUT_NAME,
  GENDER_INPUT_NAME
} from "../../../utils/login-input-names";
import { getRegisterFormErrors } from "../../../utils/login-input-validations";
import { selectCity } from "../../../utils/selectCity";
import { isValidImage, isValidSize } from "../../../utils/validation";
import { RegistreStyle } from "./registre-style";
import LogoIcon from "../../../assets/icons/Logo/logo-icon";
import { authSelectors } from "../../../store/auth/selectors";
import { paymentSelector } from "../../../store/payment/selectors";
import ModalInfo from "../../../components/modals/modal-info/modal-info";
import { modalActions } from "../../../store/modal/actions";
import { modalSelector } from "../../../store/modal/selectors";
import useWindowManager from "../../../utils/windows-manager";
import { loaderActions } from "../../../store/loader/actions";
import { loadSelector } from "../../../store/loader/selectors";
import Loader from "../../../components/loader/loader";
import { paymentActions } from "../../../store/payment/actions";
import amplitude from 'amplitude-js'
import ModalModifyPhone from "../../../components/modals/modal-modify-phone/modal-modify-phone";
import SendApiConversion from "../../../constants/apiConversionFb";
import GAEventTracker from "../../../hooks/useGoogleTrack";

const Registre = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const store = useStore();
  const {
    register,
    handleSubmit,
    errors,
    watch,
    setValue,
    control
  } = useForm();
  const passwordInput = watch("password");
  const stateSelected = watch(STATE_INPUT_NAME);
  // const tdcSlider = watch(OWNS_TDC_INPUT_NAME);
  // const mortgageLoanSlider = watch(MORTGAGE_LOAN_INPUT_NAME);
  // const autoLoanSlider = watch(AUTO_LOAN_INPUT_NAME);
  const isModal = useSelector(modalSelector.isShowing());
  const purchaseData = useSelector(paymentSelector.purchasesData())
  const loading = useSelector(loadSelector.isLoading());
  const [disabled, setDisabled] = useState(true)
  const { closeWindow } = useWindowManager();
  let [hasErrorRFC, setHasErrorRFC] = useState<string>('')
  let [hasErrorEmail, setHasErrorEmail] = useState<string>('')
  let [colonies, setColonies] = useState<any>([]);
  let maxlen: number = 30;
  const [bdate, setBirthDate] = useState("");
  const userToken = useSelector(authSelectors.getCurrentJWT());
  const [numberFlag, setNumberFlag] = useState<string>('false')

  const cpInput = watch(CP_INPUT_NAME) as string;

  let callBackCP = useCallback(async (cp = '0') => {
    if (cp.length === 5) {
      let result = await authActions.getDataPostalCode(cp);

      let arrayColony: any = []
      result.map((colony: any) => {
        arrayColony.push(colony?.NomAsentamiento);
      });

      console.log(arrayColony, 'enviado a setColonies')
      setColonies(arrayColony)

      setValue(STATE_INPUT_NAME, result[0]?.CatMunicipio?.CatEstado?.NomEstado);
      setValue(CITY_INPUT_NAME, result[0]?.CatMunicipio?.NomMunicipio);
      //setDisabled(!disabled);
      if (arrayColony.length === 0) {
        console.log('si es 0')
        setDisabled(false);
      } else {
        setDisabled(true);
        setValue(STATE_INPUT_NAME, result[0]?.CatMunicipio?.CatEstado?.NomEstado);
        setValue(CITY_INPUT_NAME, result[0]?.CatMunicipio?.NomMunicipio);
      }
    }
  }, []);

  //Verificar el RFC con el dia de nacimiento
  const RFCInput = watch(RFC_INPUT_NAME) as string;

  const BDRFC = useCallback((rfc: string = '0') => {
    if (rfc.length === 13) {
      //RFCInput.valueOf();
      let dateRfc = rfc.substr(4, 6)
      let year = Number(dateRfc.substr(0, 2)) <= 99 && Number(dateRfc.substr(0, 2)) >= 21 ? String(1900 + Number(dateRfc.substr(0, 2))) : String(2000 + Number(dateRfc.substr(0, 2)))
      let month = dateRfc.substr(2, 2)
      let day = dateRfc.substr(4, 2)
      let bd = `${day}/${month}/${year}`;
      console.log(bd);
      setBirthDate(bd);
    } else if(rfc.length === 12) {
      let dateRfc = rfc.substr(3, 6)
      let year = Number(dateRfc.substr(0, 2)) <= 99 && Number(dateRfc.substr(0, 2)) >= 21 ? String(1900 + Number(dateRfc.substr(0, 2))) : String(2000 + Number(dateRfc.substr(0, 2)))
      let month = dateRfc.substr(2, 2)
      let day = dateRfc.substr(4, 2)
      let bd =`${day}/${month}/${year}`;
      console.log(bd);
      setBirthDate(bd);
    }
  }, []);


  useEffect(() => {
    BDRFC(RFCInput);
  }, [RFCInput]);

  useEffect(() => {
    callBackCP(cpInput)
  }, [cpInput]);


  const municipalitySelect = selectCity(stateSelected);

  const validatePassword = (value: string) => {
    return value === passwordInput;
  };

  const validateIneIfUploaded = () => {
    const currentIneStatus = store.getState().auth.ine;
    if (!currentIneStatus) return false;
    if (!currentIneStatus.front || !currentIneStatus.back) return false;
    if (!isValidImage(currentIneStatus.front)) return false;
    if (!isValidImage(currentIneStatus.back)) return false;
    if (!isValidSize(currentIneStatus.front)) return false;
    if (!isValidSize(currentIneStatus.back)) return false;

    return true;
  };

  const handleIneAdded = (img: any) => {
    const images: IDefaultAttachImagesObjType = img[0];
    const ine = {
      front: images.ImgIneFront.fakeUrl,
      back: images.ImgIneBack.fakeUrl
    };

    dispatch(authActions.addIneImages(ine));
  };

  // TODO: Refact this
  const openModal = async (id: number | string) => {
    await dispatch(modalActions.show(id));
  };


  const closeModal = async () => {
    await dispatch(modalActions.hide());
  };


  const handleAuthorization = async (data: any) => {
    try {
      const registerFormData = {
        name: data[FIRST_NAME_INPUT_NAME],
        surname: data[LAST_NAME_INPUT_NAME],
        secondSurname: data[SECOND_LAST_NAME_INPUT_NAME],
        email: data[REGISTER_EMAIL_INPUT_NAME],
        rfc: data[RFC_INPUT_NAME],
        birthDate: bdate,
        phoneNumber: data[PHONE_NUMBER_INPUT_NAME].replace(/\s/g, ''),
        termsAndCondition: data[LIGHTPAY_TERMS_POLICY_INPUT_NAME],
        address: {
          cp: data[CP_INPUT_NAME],
          colony: data[COLONY_INPUT_NAME],
          municipality: data[CITY_INPUT_NAME],
          street: data[STREET_INPUT_NAME],
          state: data[STATE_INPUT_NAME],
        }
      };
      const personalData = {
        name: data[FIRST_NAME_INPUT_NAME],
        surname: data[LAST_NAME_INPUT_NAME],
        secondSurname: data[SECOND_LAST_NAME_INPUT_NAME],
      }
      amplitude.getInstance().logEvent('checkout started', {
        'personalData': personalData,
        'address': registerFormData.address,
        'rfc': registerFormData.rfc,
        'mobile': registerFormData.phoneNumber,
        'birthDate': bdate,
        'termsAndCondition': registerFormData.termsAndCondition
      })

      amplitude.getInstance().logEvent('account profile submitted', registerFormData);
      //SendApiConversion('account profile submitted',[registerFormData.email],[registerFormData.phoneNumber],purchaseData?.commerce?.name);
      //setPhoneNumber(data[PHONE_NUMBER_INPUT_NAME])
      setHasErrorRFC('')
      setHasErrorEmail('')
      /* let rfc = data[RFC_INPUT_NAME]
      let dateRfc = rfc.substr(4, 6)
      let year = Number(dateRfc.substr(0, 2)) <= 99 && Number(dateRfc.substr(0, 2)) >= 21 ? String(1900 + Number(dateRfc.substr(0, 2))) : String(2000 + Number(dateRfc.substr(0, 2)))
      let month = dateRfc.substr(2, 2)
      let day = dateRfc.substr(4, 2)

      let arrayBirth = data[BIRTHDATE_INPUT_NAME].split('/')

      if (year !== arrayBirth[2]) throw new Error('La fecha de nacimiento no coincide con el RFC');
      if (month !== arrayBirth[1]) throw new Error('La fecha de nacimiento no coincide con el RFC');
      if (day !== arrayBirth[0]) throw new Error('La fecha de nacimiento no coincide con el RFC'); */

      // console.log(data[RFC_INPUT_NAME], data[BIRTHDATE_INPUT_NAME])

      try {
        await dispatch(authActions.checkData(data[REGISTER_EMAIL_INPUT_NAME], data[RFC_INPUT_NAME]));
        const email = data[REGISTER_EMAIL_INPUT_NAME];
        const name = data[FIRST_NAME_INPUT_NAME];
        const surname = data[LAST_NAME_INPUT_NAME];
        const secondSurname = data[SECOND_LAST_NAME_INPUT_NAME]
        // await dispatch(authActions.registerEmail(email, name, surname, secondSurname, purchaseData.commerce.name || null));
        amplitude.getInstance().logEvent('account profile validated', { 'type': 'frontend validation' });
        amplitude.getInstance().logEvent('checkout started', { 'type': 'button create account' });
        openModal('authorization');
      } catch (error) {
        console.log(error, error.message, 'testing')
        error.message === 'RFC_NO_VALID' && setHasErrorRFC(error.message)
        error.message === 'EMAIL_NO_VALID' && setHasErrorEmail(error.message)
        amplitude.getInstance().logEvent('error encountered', { 'error_type': 'validation information account', 'error_reason': JSON.stringify(error) });
      }
    } catch (error) {
      console.log(error, 'el error del handle')
      setHasErrorRFC('invalid-date-rfc')
      amplitude.getInstance().logEvent('error encountered', { 'error_type': 'create account', 'error_reason': 'La fecha de nacimiento no coincide con el RFC' });
    }
  }

  const handleForm = async (data: any) => {
    amplitude.getInstance().logEvent('credit score consent');

    // DEFINE SCHEMA TO FORMS
    console.log(data[CONFIRM_PASSWORD_INPUT_NAME]);
    const registerFormData: IRegisterForm = {
      name: data[FIRST_NAME_INPUT_NAME],
      surname: data[LAST_NAME_INPUT_NAME],
      secondSurname: data[SECOND_LAST_NAME_INPUT_NAME],
      password: data[PASSWORD_INPUT_NAME],
      repeatPassword: data[REPEAT_PASSWORD_INPUT_NAME],
      termsAndCondition: data[LIGHTPAY_TERMS_POLICY_INPUT_NAME],
      email: data[REGISTER_EMAIL_INPUT_NAME],
      rfc: data[RFC_INPUT_NAME],
      cp: data[CP_INPUT_NAME],
      colony: data[COLONY_INPUT_NAME],
      municipality: data[CITY_INPUT_NAME],
      street: data[STREET_INPUT_NAME],
      state: data[STATE_INPUT_NAME],
      birthDate: bdate,
      haveTdc: data[OWNS_TDC_INPUT_NAME],
      tdc: data[TDC_INPUT_NAME],
      mortgageLoan: data[MORTGAGE_LOAN_INPUT_NAME],
      autoLoan: data[AUTO_LOAN_INPUT_NAME],
      phoneNumber: data[PHONE_NUMBER_INPUT_NAME].replace(/\s/g, ''),
      gender: data[GENDER_INPUT_NAME]
    };

    try {
      await dispatch(loaderActions.loading());
      await dispatch(authActions.addPhoneNumber(data[PHONE_NUMBER_INPUT_NAME].replace(/\s/g, '')));
      await dispatch(authActions.registerUser(registerFormData));
      amplitude.getInstance().logEvent('account created', { 'account_type': 'shopper', 'location': 'pagos.slightpay' });
      SendApiConversion('Client Lead',[data[REGISTER_EMAIL_INPUT_NAME]],[data[PHONE_NUMBER_INPUT_NAME]]);
      GAEventTracker("Client Signup","(Growth) Usuario Registro",`Se registró el usuario con el email ${data[REGISTER_EMAIL_INPUT_NAME]}`)
      await dispatch(authActions.sendBuroDeCredito(purchaseData));
      await dispatch(loaderActions.loaded());
      setNumberFlag("true")
      openModal('SmsCode')
    } catch (error) {
      await dispatch(loaderActions.loaded());
      console.log(error);
      amplitude.getInstance().logEvent('error encountered', { 'error_type': 'create account', 'error_reason': JSON.stringify(error) });
      // Here we would have
    }
  };

  useEffect(() => {
    ReactGA.pageview('https://pagos.slightpay.com/registro');
    ReactPixel.pageView();
    amplitude.getInstance().logEvent('create account started');
    //SendApiConversion('Create account started');
  }, [])

  return (
    <>
      {
        loading && <><Loader /></>
      }
      <RegistreStyle>
        <div className="main-form-wrapper">
          <div className="main-layout-logo">
            <LogoIcon scale="1.3" fill="" />
          </div>
          <div className="new-credit-card-title">
            <p>Datos personales</p>
          </div>

          <div className="new-credit-card-form">
            <FormGridRow pattern="1fr 1fr" respPattern="1fr">
              <InputBox
                labelText="Nombre"
                name={FIRST_NAME_INPUT_NAME}
                inputRef={register(
                  getRegisterInputMetadata(FIRST_NAME_INPUT_NAME)
                )}
                errorCode={getRegisterFormErrors(errors, FIRST_NAME_INPUT_NAME)}
              />
              <InputBox
                labelText="Apellido Paterno"
                name={LAST_NAME_INPUT_NAME}
                inputRef={register(
                  getRegisterInputMetadata(LAST_NAME_INPUT_NAME)
                )}
                errorCode={getRegisterFormErrors(errors, LAST_NAME_INPUT_NAME)}
              />
              <InputBox
                labelText="Apellido Materno"
                name={SECOND_LAST_NAME_INPUT_NAME}
                inputRef={register(
                  getRegisterInputMetadata(SECOND_LAST_NAME_INPUT_NAME)
                )}
                errorCode={getRegisterFormErrors(errors, SECOND_LAST_NAME_INPUT_NAME)}
              />
              <Controller
                as={
                  <SelectBox
                    className="col-sm-6 form-input"
                    name={GENDER_INPUT_NAME}
                    optionsArray={['Masculino', 'Femenino']}
                    labelText="Género"
                  />
                }
                name={GENDER_INPUT_NAME}
                control={control}
              />
            </FormGridRow>
          </div>
          <div className="new-credit-card-title">
            <p>Dirección</p>
          </div>
          <div className="new-credit-card-form">
            <FormGridRow pattern="1fr">
              <InputBox
                labelText="Calle y Número"
                name={STREET_INPUT_NAME}
                inputRef={register(getRegisterInputMetadata(STREET_INPUT_NAME))}
                errorCode={getRegisterFormErrors(errors, STREET_INPUT_NAME)}
                maxLength={maxlen}
              />
            </FormGridRow>
            <FormGridRow pattern="1fr 1fr" respPattern="1fr">
              <InputBox
                labelText="CP"
                name={CP_INPUT_NAME}
                inputRef={register(getRegisterInputMetadata(CP_INPUT_NAME))}
                errorCode={getRegisterFormErrors(errors, CP_INPUT_NAME)}
              />
              {disabled ?
                <Controller
                  as={
                    <SelectBox
                      className="col-sm-6 form-input"
                      name={COLONY_INPUT_NAME + 'inner'}
                      optionsArray={colonies}
                      labelText="Colonia"
                      disabled={!cpInput}
                    />
                  }
                  name={COLONY_INPUT_NAME}
                  control={control}
                // rules={getPersonalDataInputMetadata(COLONY_INPUT_NAME)}
                /> :
                <InputBox
                  labelText="Colonia"
                  name={COLONY_INPUT_NAME}
                  inputRef={register(getRegisterInputMetadata(COLONY_INPUT_NAME))}
                  errorCode={getRegisterFormErrors(errors, COLONY_INPUT_NAME)}
                />}
            </FormGridRow>
            <FormGridRow pattern="1fr 1fr" respPattern="1fr">
              {disabled ?
                <InputBox
                  className="col-sm-6 form-input"
                  labelText="estado"
                  name={STATE_INPUT_NAME}
                  inputRef={register(getRegisterInputMetadata(STATE_INPUT_NAME))}
                  errorCode={getRegisterFormErrors(errors, STATE_INPUT_NAME)}
                  disabled
                /> :
                <Controller
                  as={
                    <SelectBox
                      name={STATE_INPUT_NAME + "inner"}
                      labelText="Estado"
                      optionsArray={STATES}
                      errorCode={getRegisterFormErrors(errors, STATE_INPUT_NAME)}
                    />
                  }
                  name={STATE_INPUT_NAME}
                  control={control}
                  rules={getRegisterInputMetadata(STATE_INPUT_NAME)}
                />
              }

              {
                disabled ?
                  <InputBox
                    className="col-sm-6 form-input"
                    name={CITY_INPUT_NAME}
                    labelText={'Municipio'}
                    inputRef={register(getRegisterInputMetadata(CITY_INPUT_NAME))}
                    errorCode={getRegisterFormErrors(errors, CITY_INPUT_NAME)}
                    disabled
                  />
                  :
                  <Controller
                    as={
                      <SelectBox
                        name={CITY_INPUT_NAME + "inner"}
                        labelText={
                          stateSelected === "Ciudad de México"
                            ? "DELEGACIÓN / MUNICIPIO"
                            : "Municipio"
                        }
                        optionsArray={
                          municipalitySelect &&
                          municipalitySelect.sort((a: string, b: string) =>
                            a.localeCompare(b)
                          )
                        }
                        errorCode={getRegisterFormErrors(errors, CITY_INPUT_NAME)}
                        disabled={
                          stateSelected === undefined ||
                          stateSelected === "Seleccionar"
                        }
                      />
                    }
                    name={CITY_INPUT_NAME}
                    control={control}
                    rules={getRegisterInputMetadata(CITY_INPUT_NAME)}
                  />
              }

            </FormGridRow>
          </div>
          <div className="new-credit-card-title">
            <p>Información de contacto</p>
          </div>
          <div className="new-credit-card-form">
            <FormGridRow pattern="1fr">
              <InputBox
                labelText="correo electrónico"
                name={REGISTER_EMAIL_INPUT_NAME}
                inputRef={register(
                  getRegisterInputMetadata(REGISTER_EMAIL_INPUT_NAME)
                )}
                errorCode={getRegisterFormErrors(
                  errors,
                  REGISTER_EMAIL_INPUT_NAME
                ) || hasErrorEmail}
              />
            </FormGridRow>
            <FormGridRow pattern="1fr">
              <InputBox
                labelText="RFC"
                name={RFC_INPUT_NAME}
                inputRef={register(getRegisterInputMetadata(RFC_INPUT_NAME))}
                errorCode={getRegisterFormErrors(errors, RFC_INPUT_NAME) || hasErrorRFC}
                rightLabel={{
                  onClick: () => {
                    window.open('https://www.mi-rfc.com.mx/consulta-rfc-homoclave', '_blank')?.focus()
                  },
                  text: 'Consultar mi RFC'
                }}
              />
              {/* <Controller
               as={
                 <Attach
                   className="col-sm-12 form-input"
                   labelText="INE"
                   name={INE_INPUT_NAME + "inner"}
                   // initialValue={form.ineFront.value || form.ineBack.value}
                   errorCode={getRegisterFormErrors(errors, INE_INPUT_NAME)}
                 />
               }
               onChange={(e: any) => handleIneAdded(e)}
               name={INE_INPUT_NAME}
               control={control}
               rules={getRegisterInputMetadata(INE_INPUT_NAME, {
                 ineNotUploaded: validateIneIfUploaded
               })}
             /> */}
            </FormGridRow>
            <FormGridRow pattern="1fr">
              <Controller
                as={
                  <InputBox
                    labelText="Nº de Télefono"
                    name={PHONE_NUMBER_INPUT_NAME}
                    errorCode={getRegisterFormErrors(errors, PHONE_NUMBER_INPUT_NAME)}
                    cleaveFormat={{
                      phone: true,
                      // phoneRegionCode: 'MX',
                    }}
                    placeholder="+52"
                    initialValue="+52"
                  />
                }
                name={PHONE_NUMBER_INPUT_NAME}
                control={control}
                rules={getRegisterInputMetadata(PHONE_NUMBER_INPUT_NAME)}
              />
              {/* <Controller
                as={
                  <CalendarBox
                    onChange={value => {
                      console.log(
                        "This will be override for the <Controller/> onChange",
                        value
                      );
                    }}
                    className="col-sm-12 form-input"
                    initialValue={""}
                    labelText="Fecha de nacimiento"
                    placeholder="día/mes/año"
                    minAge={18}
                    errorCode={getRegisterFormErrors(
                      errors,
                      BIRTHDATE_INPUT_NAME
                    )}
                  />
                }
                onChange={data => {
                  return data[0];
                }}
                name={BIRTHDATE_INPUT_NAME}
                control={control}
                rules={getRegisterInputMetadata(BIRTHDATE_INPUT_NAME)}
              /> */}
            </FormGridRow>
          </div>
          <div className="new-credit-card-title">
            <p>Información de cuenta</p>
          </div>
          <div className="new-credit-card-text">
            <p>
              Al crear esta cuenta has abierto una puerta hacia la libertad
              financiera. Nuestro sistema de pagos en cuatro cuotas te permite
              estar al control de tu dinero en todo momento. ¡Bienvenido a una
              manera mejor de comprar!
            </p>

            {/* <p>
             Make it simple.<span> Shop it LightPay.</span>{" "}
           </p> */}
          </div>
          <div className="new-credit-card-form">
            <div className="new-credit-card-form__section">
              <FormGridRow pattern="1fr 1fr" respPattern="1fr">
                <PasswordBox
                  labelText="contraseña"
                  name={PASSWORD_INPUT_NAME}
                  inputRef={register(
                    getRegisterInputMetadata(PASSWORD_INPUT_NAME)
                  )}
                  errorCode={getRegisterFormErrors(errors, PASSWORD_INPUT_NAME)}
                />
                <PasswordBox
                  labelText="repetir contraseña"
                  name={REPEAT_PASSWORD_INPUT_NAME}
                  inputRef={register(
                    getRegisterInputMetadata(REPEAT_PASSWORD_INPUT_NAME, {
                      passwordMissmatch: validatePassword
                    })
                  )}
                  errorCode={getRegisterFormErrors(
                    errors,
                    REPEAT_PASSWORD_INPUT_NAME
                  )}
                />
              </FormGridRow>
            </div>
            {/* 
           <ul className="form-ul">
             <div className="form-ul-input">
               <li>
                 <p>¿Posee tarjeta de crédito?</p>

                 <Controller
                   as={
                     <SlideCheckbox
                       name={OWNS_TDC_INPUT_NAME}
                       value={tdcSlider ? tdcSlider : false}
                       errorCode={getRegisterFormErrors(
                         errors,
                         OWNS_TDC_INPUT_NAME
                       )}
                     />
                   }
                   onClick={(e: any) => {
                     setValue(OWNS_TDC_INPUT_NAME, e);
                   }}
                   name={OWNS_TDC_INPUT_NAME}
                   control={control}
                   rules={getRegisterInputMetadata(OWNS_TDC_INPUT_NAME)}
                 />
               </li>

               {tdcSlider && (
                 <div className="form-ul-input__item">
                   <InputBox
                     className="col-sm-12"
                     labelText="últimos 4 digitos de la tdc"
                     name={TDC_INPUT_NAME}
                     inputRef={register(
                       getRegisterInputMetadata(TDC_INPUT_NAME)
                     )}
                     errorCode={getRegisterFormErrors(errors, TDC_INPUT_NAME)}
                     maxLength={4}
                   />
                 </div>
               )}
             </div>
             <li>
               <p>¿Posee préstamo Hipotecario?</p>
               <Controller
                 as={
                   <SlideCheckbox
                     name={MORTGAGE_LOAN_INPUT_NAME}
                     value={mortgageLoanSlider ? mortgageLoanSlider : false}
                     errorCode={getRegisterFormErrors(
                       errors,
                       MORTGAGE_LOAN_INPUT_NAME
                     )}
                   />
                 }
                 onClick={(e: any) => {
                   setValue(MORTGAGE_LOAN_INPUT_NAME, e);
                 }}
                 name={MORTGAGE_LOAN_INPUT_NAME}
                 control={control}
                 rules={getRegisterInputMetadata(MORTGAGE_LOAN_INPUT_NAME)}
               />
             </li>
             <li>
               <p>¿Posee préstamo automotriz?</p>
               <Controller
                 as={
                   <SlideCheckbox
                     name={AUTO_LOAN_INPUT_NAME}
                     value={autoLoanSlider ? autoLoanSlider : false}
                     errorCode={getRegisterFormErrors(
                       errors,
                       AUTO_LOAN_INPUT_NAME
                     )}
                   />
                 }
                 onClick={(e: any) => {
                   setValue(AUTO_LOAN_INPUT_NAME, e);
                 }}
                 name={AUTO_LOAN_INPUT_NAME}
                 control={control}
                 rules={getRegisterInputMetadata(AUTO_LOAN_INPUT_NAME)}
               />
             </li>
           </ul>
         */}
          </div>
          <div className="new-credit-card-check">
            <Checkbox
              inputRef={register(
                getRegisterInputMetadata(LIGHTPAY_TERMS_POLICY_INPUT_NAME)
              )}
              name={LIGHTPAY_TERMS_POLICY_INPUT_NAME}
              errorCode={getRegisterFormErrors(
                errors,
                LIGHTPAY_TERMS_POLICY_INPUT_NAME
              )}
              labelTextOptions={[
                {
                  text: 'He leído y acepto las condiciones de uso',
                  url: 'https://slightpay.com/terminos-y-condiciones',
                },
                {
                  text: 'y la política de privacidad.',
                  url: 'https://slightpay.com/politica-de-privacidad',
                },
              ]}
            />
          </div>
          <div className="new-credit-card-button">
            <MainButton
              className="form-button"
              type="primary"
              text="Pagar y crear cuenta"
              onClick={handleSubmit(handleAuthorization)}
            />
          </div>

          <div className="new-credit-card-back" onClick={closeWindow}>
            <p>Cancelar y volver a mi carrito</p>
          </div>
        </div>
      </RegistreStyle>
      {/* MODALS */}
      <ModalInfo
        modalConfig={{
          onClose: closeModal,
          active: (isModal?.show && isModal?.idModal === 'authorization') || false,
        }}
        password={passwordInput}
        title={'Antes de formalizar tu registro...'}
        text="Por este conducto autorizo expresamente a YUBO SAPI DE CV, para que
               por conducto de sus funcionarios facultados lleve a cabo Investigaciones, sobre mi
               comportamiento Crediticio en las Sociedades de Información Crediticia que estime conveniente."

        text2="Así mismo, declaro que conozco la naturaleza y alcance de la información que se solicitará,
               del use que YUBO SAPI DE CV hará de tal información y de que ésta podrá realizar
               consultas periódicas de mi historial crediticio, consintiendo que esta autorización se encuentre
               vigente por un período de 3 años contados a partir de la fecha de su expedición y en todo caso durante 
               el tiempo que mantegamos relación jurídica."

        onClick={handleSubmit(handleForm)}
      />

      <ModalCode
        modalConfig={{
          active: (isModal?.show && isModal?.idModal === 'SmsCode') || false,
        }}
        onClick={handleSubmit(handleForm)}
        number={numberFlag}
      />
      <ModalModifyPhone
        modalConfig={{
          active: (isModal?.show && isModal?.idModal === 'ModifyPhone') || false,
        }}
      />
    </>
  );
};

export default Registre;
