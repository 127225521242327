import Cleave from 'cleave.js/react';
import "cleave.js/dist/addons/cleave-phone.mx";
import 'cleave.js/src/addons/phone-type-formatter.i18n';

import React, { useState } from 'react';

// Constants
import { ERROR_MESSAGES } from '../../constants/errorMessages';

// Components
import { LabelText } from '../components-text/label-text/label-text';
import { NoteText } from '../components-text/note-text/note-text';

// Styles
import { InputBankBoxContainer } from './input-box-styleBank';

// Types
import ErrorText from '../components-text/error-text/error-text';
import { IInputBoxProps, IUseInputType } from './input-box-types';

const useInput: IUseInputType = (props: IInputBoxProps) => {
  // fix later
  const [value, setValue] = useState(props.value || props.initialValue || '');
  const [name, setName] = useState(props.name);

  const handleChange = (e: any) => {
    setValue(e.target.value);
    setName(e.target.name);
    if (props.onChange) {
      props.onChange(e, props.name);
    }
  };

  const handleBlur = (e: any) => {
    if (!value.includes('+') && props.cleaveFormat?.phone) {
      setValue(`+52${e.target.rawValue}`);
    } else setValue(e.target.value);
    if (props.onBlur) {
      props.onBlur(e.target.value, props.name);
    }
  };

  const handleKeyPressed = (e: any) => {
    const { onKeyPress, onKeyPressEvent } = props;
    // console.log(onKeyPress?.name)
    // TODO: LOOK FOR A BETTER WAY TO HANDLE THIS
    /* eslint-disable */
    if (e.key === 'Enter') {
      onKeyPress ? onKeyPress() : null;
      onKeyPressEvent ? onKeyPressEvent(e.target) : null;
    }
  };

  return {
    handleChange,
    handleBlur,
    value,
    handleKeyPressed,
    name,
  };
};

const InputBoxBank: React.FC<IInputBoxProps> = (props: IInputBoxProps) => {
  const {
    disabled,
    className,
    icon,
    iconDisabled,
    required,
    labelText,
    errorCode,
    placeholder,
    message,
    onIconClick,
    cleaveFormat,
    inputRef,
    value,
    initialValue,
    maxLength,
    rightLabel,
  } = props;

  const inputActions = useInput(props);
  const errorText = errorCode ? ERROR_MESSAGES[errorCode] : '';

  return (
    <InputBankBoxContainer
      className={`${errorCode ? 'error' : ''} ${className} ${disabled ? 'disabled' : ''} ${errorCode ? 'error' : ''}`}
    >
      {labelText && (
        <div className="input-box-topbar">
          <div className="input-box-topbar-label">
            <LabelText>
              <p>{labelText}</p>
            </LabelText>
          </div>
          {rightLabel && 
        <div className="input-box-topbar-label right" onClick={()=> rightLabel && rightLabel.onClick()}>
          <p>{rightLabel.text}</p>
        </div>}
        </div>
      )}
      <div className="input-box-main">
        <div className={`input-box-main-field ${required ? 'required' : ''}`}>
          {cleaveFormat && (
            <Cleave
              className="input"
              type="text"
              value={inputActions.value || initialValue} //This was commented to avoid conflicts with react hook form
              name={inputActions.name}
              onChange={e => inputActions.handleChange(e)}
              onBlur={e => inputActions.handleBlur(e)}
              options={cleaveFormat}
              placeholder={placeholder}
              disabled={disabled}
              htmlRef={inputRef}
              maxLength={maxLength}
              onKeyPress={(e: any) => inputActions.handleKeyPressed(e)}
            />
          )}

          {!cleaveFormat && (value || value === '') && (
            <input
              className="input"
              type="text"
              value={inputActions.value}
              name={inputActions.name}
              onChange={e => inputActions.handleChange(e)}
              onBlur={e => inputActions.handleBlur(e)}
              placeholder={placeholder}
              disabled={disabled}
              ref={inputRef}
              maxLength={maxLength}
              onKeyPress={(e: any) => inputActions.handleKeyPressed(e)}
            />
          )}

          {!cleaveFormat && !value && value !== '' && (
            <input
              className="input"
              type="text"
              name={inputActions.name}
              onChange={e => inputActions.handleChange(e)}
              onBlur={e => inputActions.handleBlur(e)}
              placeholder={placeholder}
              disabled={disabled}
              ref={inputRef}
              maxLength={maxLength}
              onKeyPress={(e: any) => inputActions.handleKeyPressed(e)}
            />
          )}
        </div>
        <div className="input-box-icon" onClick={onIconClick}>
          {(icon || iconDisabled) && <img src={disabled ? iconDisabled : icon} alt="input icon" />}
        </div>
      </div>
      <ErrorText>{errorCode && errorText}</ErrorText>

      {message && (
        <div className="input-box-message">
          <NoteText>
            <p>{`* ${message}`}</p>
          </NoteText>
        </div>
      )}
    </InputBankBoxContainer>
  );
};

export default InputBoxBank;
