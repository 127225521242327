import classnames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { icons } from "../../../assets/themes";
import { ModalContainerStyle } from "./modal-container-userinfo-style";

export interface IModalContainerProps {
  children?: any;
  onClose?: () => void;
  active: boolean;
  goBack?: () => void;
  type?: string;
  color?: string;
  step?: number;
  isBanks?: boolean;
}
const ModalContainer: React.FC<IModalContainerProps> = (
  props: IModalContainerProps
) => {
  const { children, onClose, active, goBack, type } = props;
  const [modalLarge, setModalLarge] = useState(false);
  const ModalContentRef: any = useRef(null);

  const modalPositionListener = () => {
    if (ModalContentRef.current && ModalContentRef.current.offsetHeight > window.innerHeight - 200) {
      setModalLarge(true);
    } else setModalLarge(false);
  };

  useEffect(() => {
    modalPositionListener();
    window.addEventListener("resize", () => modalPositionListener());
    return window.removeEventListener("resize", () => modalPositionListener());
  }, []);
  console.log()
  return (
    <ModalContainerStyle 
      className={classnames({
        large: !!modalLarge,
        active: !!active,
        light: type === "light"
      })}
    >
      <div className="modal-head">
        <div className="modal-head__item">
          {goBack && (
            <div
              className="modal-head-back"
              onClick={() => (goBack ? goBack() : "")}
            >
              <img src={icons.arrowBack} alt="" />
            </div>
          )}
        </div>
        <div className="modal-head__item">
          {onClose && (
            <div
              className="modal-container-close"
              onClick={() => (onClose ? onClose() : "")}
            >
              <img src={icons.iconClose} alt="" />
            </div>
          )}
        </div>
      </div>

      <div className={`modal-container-content`} ref={ModalContentRef}>
        {<div className="modal-container-children">{children}</div>}
      </div>
    </ModalContainerStyle>
  );
};

export default ModalContainer;
