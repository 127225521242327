import React, { useState, useEffect } from 'react'
import ModalSelectPaymentStyle from './modal-select-payment-style'
import ModalContainer from '../modal-container/modal-container'
import { paymentActions } from "../../../store/payment/actions";
import { useSelector, useDispatch } from 'react-redux'
import * as firebase from "firebase";
import { authActions } from '../../../store/auth/actions';
import { paymentSelector } from '../../../store/payment/selectors';
import { loaderActions } from "../../../store/loader/actions";

const ModalSelectPaymentMethod: React.FC<any> = (props) => {
  const { modalConfig, onClick, payment, purchase, setWalletScreen, walletScreen, setFinalMessage, finalMessage } = props
  const dispatch = useDispatch();
  const [wallets, setWallets] = useState<any>([])
  const purchasesData = useSelector(paymentSelector.purchasesData());

  useEffect(() => {
    if (sessionStorage.getItem('justwallets') && purchasesData?.id) {
      let database = firebase.database();

      //Logic to listening Firebase and then login
      let purchasesInfo = database.ref('compras/' + purchasesData.id);
      purchasesInfo.on('value', async function (snapshot) {
        let values = snapshot.val()
        if (values?.logout) {
          console.log(values, 'cerrar sesion...')
          // Logic to login and set the properties...
          dispatch(authActions.logout());
        }
      });
    }
  }, [purchasesData])

  useEffect(() => {
    !sessionStorage.getItem('justlogin') && dispatch(paymentActions.getDappWallets(setWallets))
    sessionStorage.getItem('justwallets') && setWalletScreen(true)
  }, [])

  useEffect(() => {
    console.log(wallets)
  }, [wallets])


  const generateCodiTransaction = async (qrType: number) => {
    let paymentSelected
    purchase.payments.map((payment) => {
      if (payment.paymentIndex === 1) {
        paymentSelected = payment
      }
    })

    try {
      await dispatch(paymentActions.createCodiTransaction(purchase.id, 0, qrType, paymentSelected.id))

      setFinalMessage(true)
    } catch (e) {
      console.log(e)
    }
  }

  const logoutRemote = () => {
    let purchaseRef = firebase.database().ref('compras/' + purchasesData?.id);
    purchaseRef.remove()
      .then(function () {
        console.log("Remove succeeded.")
      })
      .catch(function (error) {
        console.log("Remove failed: " + error.message)
      });

    purchaseRef.set({
      logout: true
    })

    dispatch(authActions.logout());
  }

  return (
    <ModalContainer {...modalConfig} type="light">
      <ModalSelectPaymentStyle>
        {
          finalMessage ?
            <>
              <div className="modal-to-paid-content" style={{ width: '55%' }}>
                <div style={{ height: '150px', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                  <p style={{ textAlign: 'center', marginBottom: '10px' }}>Para verificar que se haya realizado de forma correcta, cierra esta ventana y busca en tu correo la confirmación de tu compra.</p>
                  <div className='payment-method-item' onClick={() => {
                    setFinalMessage(false)
                    setWalletScreen(true)
                  }}>
                    <p style={{ margin: '25px 50px', textAlign: 'center' }}>Volver a elegir CoDi</p>
                  </div>
                </div>
              </div>
            </>
            :
            !walletScreen ?
              <>
                <div className="modal-to-paid-description">
                  <p>Elige un método de pago</p>
                </div>
                <div className="modal-to-paid-content">
                  <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', height: '320px', justifyContent: 'center', alignItems: 'center ' }}>
                    <div className='payment-method-item' onClick={onClick}>
                      <p style={{ margin: '25px 50px', textAlign: 'center' }}>PAGO CON TARJETA</p>
                    </div>
                    <div className='payment-method-item' onClick={() => { setWalletScreen(true) }}>
                      <p style={{ margin: '25px 50px', textAlign: 'center' }}>WALLET</p>
                    </div>
                  </div>
                </div>
              </> :
              <>
                <div className="modal-to-paid-description">
                  <p>Elige una wallet</p>
                </div>
                <div className="modal-to-paid-content" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                  {
                    !sessionStorage.getItem('justlogin') ?
                      <>
                        <p style={{ textAlign: 'center' }}>Selecciona cualquiera de estas opciones para desplegar el código QR y proceder con tu pago.</p>
                        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', height: '320px', justifyContent: 'center', alignItems: 'center ' }}>
                          {
                            wallets.map((wallet: any, key: number) => {
                              return (
                                <div key={key} className='payment-method-item' onClick={() => {
                                  generateCodiTransaction(wallet.qr)
                                }}>
                                  <p style={{ margin: '25px 50px', textAlign: 'center' }}>{wallet.name}</p>
                                </div>
                              )
                            })
                          }
                        </div>
                      </> : <>
                        <p style={{ textAlign: 'center', marginBottom: '25px' }}>1. Indicale al cajero la Wallet con la que deseas pagar.</p>
                        <p style={{ textAlign: 'center', marginBottom: '25px' }}>2. Abre la aplicación de tu Wallet y escanea el QR que se muestra en la pantalla del cajero.</p>
                        <p style={{ textAlign: 'center', marginBottom: '25px' }}>3. Una vez que hayas pagado puedes presionar el boton de abajo para cerrar sesion en el cajero.</p>
                        <div className='logoutButton' onClick={logoutRemote}>
                          <p style={{ margin: '25px 50px', textAlign: 'center' }}>Cerrar Sesión</p>
                        </div>
                      </>
                  }
                </div>
              </>
        }
      </ModalSelectPaymentStyle>

    </ModalContainer >
  )
}

export default ModalSelectPaymentMethod
