import React from "react";
import { HashRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import MainLayout from "../components/main-layout/main-layout";
import CreditCard from "../containers/private/credit-card/credit-card";
import { paymentSelector } from "../store/payment/selectors";
import { IPurchase } from "@aluxion-lightpay/interfaces";
import { useSelector } from 'react-redux';
import Payments from "../containers/private/wallets/wallets"


const AppRoutes: React.FC = () => {
  const purchasesData = useSelector(paymentSelector.purchasesData()) as IPurchase;

  return (
    <Router>
      <MainLayout purchases={purchasesData}>
        <Switch>
          <Route exact path='/select-payment/:token' component={Payments}></Route>
          <Route exact path='/credit-card/:token' component={CreditCard} />
          <Route render={() => <Redirect to={`/select-payment/${sessionStorage.getItem('buyToken')}/`} />} />
        </Switch>
      </MainLayout>
    </Router>
  );
};

export default AppRoutes;
