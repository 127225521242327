import React from 'react';
import { useState } from 'react';
import styled from 'styled-components';

// import LoaderContainer from './loader-style';

const Loader = (props?: any) => {
  console.log(props?.opacity)

  const LoaderContainer = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color:  rgba(255,255,255,${props?.opacity || 0.8});
  color: #424242;
  z-index: 99999999;
  top: 0px;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  .messageInfo{
    margin-top:40px;
  }

  .loader-animation {
    width: 180px;
    height: 180px;
    position: absolute;

    .big-letter-container {
      position: relative;
      top: -120px;
      left: 4px;
      text-align: center;

      .big-letter {
        font-size: 22px;
        position: relative;
        font-weight: bold;
        text-align: center;
        &.up {
          top: -3px;
        }
        &.down {
          top: 3px;

        }

      }
  
    }

  }

  .loader-animation:before {
    content: "";
    display: block;
    width: 164px;
    height: 164px;
    margin: 8px;
    border-radius: 50%;
    border: 4px solid #1A4CCD;
    border-color: #1A4CCD transparent #1A4CCD transparent;
    animation: loader-animation 1.3s linear infinite;
  }


  @keyframes loader-animation {
    0% {
      transform: rotate(0deg);
      opacity: 1;
    }
    25% {
      opacity: 0.4;
    }
    50% {
      opacity: 1;
    }
    75% {
      opacity: 0.4;
    }
    100% {
      transform: rotate(360deg);
      opacity: 1;
    }
  }
`;

  const [counter, setCounter] = useState(0);

  React.useEffect(() => {
    counter >= 0 && setTimeout(() => setCounter(counter + 1), 1000);
  }, [counter]);

  const contentMessage = () => {
    if (counter >= 0 && counter < 15) {
      return (
        <p className='messageInfo'>Cargando...</p>
      )
    }
    if (counter >= 15 && counter < 45) {
      return (
        <p className='messageInfo'>Verificando Información...</p>
      )
    }
    if (counter >= 45) {
      return (
        <p className='messageInfo'>Procesando...</p>
      )
    }

  }

  return (
    <LoaderContainer>
      <div className="loader-animation" >
        <div className="big-letter-container">
          <span className="big-letter up">
            slight
          </span>
          <span className="big-letter down">
            pay
          </span>

        </div>
      </div>
      <div className="loader-text">
        {
          contentMessage()
        }
      </div>
    </LoaderContainer>
  );
};

export default Loader;
