import { Dispatch } from 'redux';

import { CardActions, CardActionsTypes, IUserCards } from './types';
import { ILoadingActionsTypes, ILoadingActions } from '../loader/types';
import { LightpayApi, IFormatError } from '../restclient';

interface IServerResponseCard {
  data: IUserCards
}

const addCard = (id:number, cardForm: any, tokenCardId: string, purchaseId?,cards?: any) => async (dispatch: Dispatch<CardActionsTypes | ILoadingActionsTypes>, getState: any) => {
  if(cards.includes(cardForm.cardNumber.slice(-4))){
    console.log('ya está registrada')
  } else {
    try {
      await dispatch({ type: ILoadingActions.LOADING });
        const deviceSessionId = await (window as any).OpenPay.deviceData?.setup();
  
      const response = await LightpayApi.post(`/users/${id}/cards`, {
        cvv2: cardForm.cvv2,
        cardToken: tokenCardId,
        deviceSessionId,
        purchaseId,
        cards
      }, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `bearer ${sessionStorage.getItem('token')}`,
        }
      });
      await dispatch({ type: CardActions.ADD_CARD_DATA, payload: cardForm  });
      await dispatch({ type: ILoadingActions.LOADED });
    } catch(error){
      const formatError: IFormatError = LightpayApi.errorHandler(error);
      dispatch({ type: ILoadingActions.LOADED });
      throw formatError;
    }
  }
};

const getCardData = (id:number) => async (dispatch: Dispatch<CardActionsTypes | ILoadingActionsTypes>) => {
  try {
    await dispatch({ type: ILoadingActions.LOADING });
    const cardData = await LightpayApi.get<IServerResponseCard>(`/users/${id}/cards`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `bearer ${sessionStorage.getItem('token')}`,
      },
    });
    await dispatch({ type: CardActions.ADD_CARD_DATA, payload: cardData.data  });
    await dispatch({ type: ILoadingActions.LOADED });
   }catch(error) {
    const formatError: IFormatError = LightpayApi.errorHandler(error);
    dispatch({ type: ILoadingActions.LOADED });
    throw formatError;
   }
}

export const cardActions = {
  addCard,
  getCardData,
};
