import React from 'react';

// Styles
import CardImageShadowContainer from './card-image-shadow-style';

// Types
import { ICardImageShadowProps } from './card-image-shadow-types';

const CardImageShadow: React.FC<ICardImageShadowProps> = (props: ICardImageShadowProps) => {
  const { image, shadowColor, prepending } = props;

  return (
    <CardImageShadowContainer colorShadow={shadowColor}>
      <div className="image-container">
        <img src={image} style={{ height: prepending ? '80%' : '100%' }} alt="Card shadow" />
        <div className="shadow-image" />
      </div>
    </CardImageShadowContainer>
  );
};

export default CardImageShadow;
