import { Dispatch } from 'redux';

import {
  AuthActions,
  AuthActionsTypes,
  IRegisterForm,
  IRegisterFormIneFormat,
} from './types';

import { IUser } from '@aluxion-lightpay/interfaces';
import { ILoadingActions, ILoadingActionsTypes } from '../loader/types';
import { LightpayApi, IFormatError } from '../restclient';
import { ApiErrorCodes } from '../http-errors';
import moment from 'moment';
import { store } from '../..';
import { toastActions } from '../toasts/actions';
import { IPurchase } from '@aluxion-lightpay/interfaces';
import amplitude from 'amplitude-js'
import * as firebase from "firebase";

interface IServerResponsePurchases {
  data: IPurchase
}
interface IServerResponseRegister {
  data: {
    user: IUser;
    token: string;
  };
}

export interface IServerResponseCheckEmail {
  data: {
    exists: boolean;
    statusCode: number;
    error: boolean;
  };
}

interface IServerResponseVerifyEmail {
  data: {
    sent: boolean;
  };
}

interface IServerResponsePhone {
  data: {
    verified: boolean;
  }
}

const handleCustomErrors = (action: 'LOGIN' | 'FORGOT' | 'CHECK' | 'REGISTER', code?: string): string => {
  let message = '';
  if (code) {
    const messageByCode: string = ApiErrorCodes[action][code] || 'error';
    message = messageByCode;
  }
  return message;
};

const addIneImages = (ineImage: IRegisterFormIneFormat) => async (dispatch: Dispatch<AuthActionsTypes>) => {
  try {
    dispatch({ type: AuthActions.REGISTER_FORM_INE_MODAL_DATA_ADDED, payload: ineImage });
    dispatch({ type: AuthActions.REGISTER_FORM_INE_MODAL_CLOSE });
  } catch (error) {
    console.log('Error al agregar images del INE', error);
    dispatch({ type: AuthActions.REGISTER_FORM_INE_MODAL_CLOSE });
  }
};

const addPhoneNumber = (phone: string) => async (dispatch: Dispatch<AuthActionsTypes | ILoadingActionsTypes>) => {
  try {
    dispatch({ type: ILoadingActions.LOADING });
    // heres is going to be the call to sent the code verifaction
    dispatch({ type: AuthActions.REGISTER_FORM_PHONE_DATA_ADDED, payload: phone });
    dispatch({ type: ILoadingActions.LOADED });
  } catch (error) {
    // TODO: change this later
    const formatError: IFormatError = LightpayApi.errorHandler(error, 'LOGIN');
    formatError.message = handleCustomErrors('LOGIN', formatError.code) || formatError.message;
    await dispatch({
      type: AuthActions.LOGIN_FAIL,
      error: formatError,
    });
    dispatch({ type: ILoadingActions.LOADED });
    throw formatError;
  }
}


const registerUser = (registerFormData: IRegisterForm) => async (dispatch: Dispatch<AuthActionsTypes | ILoadingActionsTypes>, getState: any) => {
  try {
    dispatch({ type: ILoadingActions.LOADING });
    // const ineForm = getState().auth.ine;
    let data: any = {
      // ineFront: ineForm.front,
      // ineBack: ineForm.back,
      email: registerFormData.email,
      firstName: registerFormData.name,
      // howKnewAboutLightpay: null,
      password: registerFormData.password,
      lastName: registerFormData.surname,
      secondLastName: registerFormData.secondSurname,
      municipality: registerFormData.municipality,
      state: registerFormData.state,
      street: registerFormData.street,
      suburb: registerFormData.colony,
      zipCode: registerFormData.cp,
      birthDate: new Date(
        moment(registerFormData.birthDate, 'DD/MM/YYYY').utc().format('') || '08/09/1993',
      ).toISOString(),
      ownsAutoLoan: registerFormData.autoLoan ? true : false,
      ownsCreditCard: registerFormData.haveTdc ? true : false,
      ownsMortgageLoan: registerFormData.mortgageLoan ? true : false,
      rfc: registerFormData.rfc,
      phoneNumber: registerFormData.phoneNumber.replace(/\s/g, ""),
      gender: registerFormData.gender
    };

    if (registerFormData.tdc) data = { ...data, creditCardLastDigits: registerFormData.tdc }
    const formData = new FormData();

    for (const key in data) {
      formData.append(key, data[key]);
    }

    // const res = await LightpayApi.post<IServerResponseCheckEmail>('/auth/check-email', {
    //   email: registerFormData.email,
    // });

    // if (res.data.exists) throw new Error('EMAIL_EXIST');


    // const resRFC =  await LightpayApi.post<IServerResponseCheckEmail>('/auth/check-rfc', {
    //   rfc: registerFormData.rfc,
    // });

    // if(resRFC.data.exists){
    //   throw new Error('RFC_EXIST')
    // }

    const response: IServerResponseRegister = await LightpayApi.post<IServerResponseRegister>(
      '/auth/payer-register',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: '',
        },
      },
    );
    sessionStorage.setItem('token', response.data.token);
    dispatch({ type: AuthActions.REGISTER_USER_SUCCESS, payload: response.data.user });
    dispatch({ type: ILoadingActions.LOADED });
    console.log({ buyToken: sessionStorage.getItem('buyToken') })
    const purchasesData = await LightpayApi.get<IServerResponsePurchases>(`/purchases/details`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `bearer ${sessionStorage.getItem('buyToken')}`,
      },

    });

    LightpayApi.post('/purchases/suscribe-user-to-purchase', {
      purchaseId: purchasesData.data.id
    }, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `bearer ${sessionStorage.getItem('token')}`,
      }
    })

  } catch (error) {
    console.log(error)
    const formatError: IFormatError = LightpayApi.errorHandler(error, 'REGISTER');
    dispatch({ type: AuthActions.REGISTER_USER_FAIL, error: formatError });
    dispatch({ type: ILoadingActions.LOADED });

    throw formatError;
  }
};

const checkPhone = (phoneNumberVerificationCode: string) => async (
  dispatch: Dispatch<AuthActionsTypes | ILoadingActionsTypes>
) => {
  try {
    dispatch({ type: ILoadingActions.LOADING });
    const response = await LightpayApi.post<IServerResponsePhone>(
      '/auth/verify-phone-number',
      {
        phoneNumberVerificationCode,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `bearer ${sessionStorage.getItem('token')}`,
        },
      },
    );
    if (!response.data.verified) {
      store.dispatch<any>(
        toastActions.error('El código no es valido'),
      )
      throw new Error('El código no es valido');
    }

    dispatch({ type: ILoadingActions.LOADED });

  } catch (error) {
    const formatError: IFormatError = LightpayApi.errorHandler(error);
    dispatch({ type: AuthActions.REGISTER_USER_FAIL, error: formatError });
    dispatch({ type: ILoadingActions.LOADED });

    throw formatError;
  }

}

const getCodePhone = () => async (
  dispatch: Dispatch<AuthActionsTypes | ILoadingActionsTypes>
) => {
  try {
    dispatch({ type: ILoadingActions.LOADING });
    await LightpayApi.post('/auth/request-verification-code',
      {
        method: 'sms'
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `bearer ${sessionStorage.getItem('token')}`,
        },
      })
    dispatch({ type: ILoadingActions.LOADED });
  } catch (error) {
    const formatError: IFormatError = LightpayApi.errorHandler(error);
    dispatch({ type: AuthActions.REGISTER_USER_FAIL, error: formatError });
    dispatch({ type: ILoadingActions.LOADED });

    throw formatError;
  }
}

const updatePhoneNumber = (phoneNumber: string) => async (dispatch: Dispatch<AuthActionsTypes | ILoadingActionsTypes>) => {
  dispatch({ type: ILoadingActions.LOADING });
  const responsePhone: any = await LightpayApi.put(`/users/update-phone-number`,
    { phoneNumber: phoneNumber.replace(/\s/g, ''), },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `bearer ${sessionStorage.getItem('token')}`,
      },
    }
  );
  dispatch({ type: ILoadingActions.LOADED });
  console.log(responsePhone);
  return responsePhone.data;
  //let responsePhone: any = await LightpayApi.post(`/users/get-phone-verified`, { headers: { 'Content-Type': 'application/json' } });
  //console.log(responseScoringScorce, responseScoringScorce.data.data.aceptado, 'scorce')
  //if (!responseScoringScorce.data.data.aceptado) throw new Error('EMAIL_NO_VALID')
}
const getPhoneNumber = () => async (dispatch: Dispatch<AuthActionsTypes | ILoadingActionsTypes>) => {
  dispatch({ type: ILoadingActions.LOADING });
  const responsePhone: any = await LightpayApi.post(`/users/get-phone-number`,
    {},
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `bearer ${sessionStorage.getItem('token')}`,
      },
    }
  );
  dispatch({ type: ILoadingActions.LOADED });
  //console.log("getPhone" + responsePhone);
  return responsePhone.data;
  //let responsePhone: any = await LightpayApi.post(`/users/get-phone-verified`, { headers: { 'Content-Type': 'application/json' } });
  //console.log(responseScoringScorce, responseScoringScorce.data.data.aceptado, 'scorce')
  //if (!responseScoringScorce.data.data.aceptado) throw new Error('EMAIL_NO_VALID')
}


const checkData = (email: string, rfc: string) => async (
  dispatch: Dispatch<AuthActionsTypes | ILoadingActionsTypes>
) => {
  try {
    dispatch({ type: ILoadingActions.LOADING });

    // Checking if user existsup
    const res = await LightpayApi.post<IServerResponseCheckEmail>('/auth/check-email', { email });
    if (res.data.exists) throw new Error('EMAIL_EXIST');

    const resRFC = await LightpayApi.post<IServerResponseCheckEmail>('/auth/check-rfc', { rfc });
    if (resRFC.data.exists) throw new Error('RFC_EXIST')
    if (!resRFC.data.exists && resRFC.data.error) throw new Error('RFC_NO_VALID')

    // Validations to new user
    let responseScoringScorce: any = await LightpayApi.post(`/scoring/check-email`, { email }, { headers: { 'Content-Type': 'application/json' } });
    console.log(responseScoringScorce, responseScoringScorce.data.aceptado, 'scorce')
    if (!responseScoringScorce.data.data.aceptado) throw new Error('EMAIL_NO_VALID')

    let responseScoringNinja: any = await LightpayApi.post(`/scoring/check-rfc`, { rfc }, { headers: { 'Content-Type': 'application/json' } });
    console.log(responseScoringNinja, responseScoringNinja.data.aceptado, 'ninja')
    if (!responseScoringNinja.data.data.aceptado) throw new Error('RFC_NO_VALID')
    amplitude.getInstance().logEvent('RFC verified');

    dispatch({ type: ILoadingActions.LOADED });

  } catch (error) {
    console.log('error que sale en el catch', error)
    const formatError: IFormatError = LightpayApi.errorHandler(error, 'REGISTER');
    dispatch({ type: AuthActions.REGISTER_USER_FAIL, error: formatError });
    dispatch({ type: ILoadingActions.LOADED });

    throw error;
  }
}

const login = (user: string, pass: string, justLogin: any) => async (
  dispatch: Dispatch<AuthActionsTypes | ILoadingActionsTypes>,
) => {
  try {
    await dispatch({ type: ILoadingActions.LOADING });

    const response = await LightpayApi.post<IServerResponseRegister>('/auth/login?relations=address', {
      email: user,
      password: pass,
    });
    if (!response.data.user.rfc) {
      throw new Error('ECOMMERCE_LOGIN');
    }
    LightpayApi.setJWT(response.data.token);
    sessionStorage.setItem('token', response.data.token);
    //await dispatch({ type: AuthActions.SET_JWT_TOKEN, payload: response.data.token });

    await LightpayApi.post<IServerResponseRegister>('/purchases/send-instructions', {
      email: user,
      commerce: JSON.parse(sessionStorage.getItem('paymentState') || '{}').purchases.commerce.name
    }, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `bearer ${sessionStorage.getItem('token')}`,
      }
    });

    console.log(response.data)

    await dispatch({
      type: AuthActions.LOGIN_SUCCESS,
      payload: response.data.user,
    });
    await dispatch({ type: ILoadingActions.LOADED });
    const purchasesData = await LightpayApi.get<IServerResponsePurchases>(`/purchases/details`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `bearer ${sessionStorage.getItem('buyToken')}`,
      },

    });
    LightpayApi.post('/purchases/suscribe-user-to-purchase', {
      purchaseId: purchasesData.data.id
    }, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `bearer ${sessionStorage.getItem('token')}`,
      }
    })

    if (justLogin) {
      firebase.database().ref('compras/' + purchasesData.data.id).set({
        token: response.data.token,
        user: response.data.user,
        payment: 0,
        mobile: true
      })

      sessionStorage.setItem('justlogin', 'true');
    }

  } catch (error) {

    const formatError: IFormatError = LightpayApi.errorHandler(error);
    formatError.message = handleCustomErrors('LOGIN', formatError.code) || formatError.message;
    await dispatch({
      type: AuthActions.LOGIN_FAIL,
      error: formatError,
    });
    await dispatch({ type: ILoadingActions.LOADED });
    throw formatError;
  }
};

const loginRemote = (token: string, user: any) => async (
  dispatch: Dispatch<AuthActionsTypes | ILoadingActionsTypes>,
) => {
  try {
    await dispatch({ type: ILoadingActions.LOADING });

    LightpayApi.setJWT(token);
    sessionStorage.setItem('token', token);
    sessionStorage.setItem('justwallets', 'true');

    await dispatch({
      type: AuthActions.LOGIN_SUCCESS,
      payload: user,
    });
  } catch (error) {

    const formatError: IFormatError = LightpayApi.errorHandler(error);
    formatError.message = handleCustomErrors('LOGIN', formatError.code) || formatError.message;
    await dispatch({
      type: AuthActions.LOGIN_FAIL,
      error: formatError,
    });
    await dispatch({ type: ILoadingActions.LOADED });
    throw formatError;
  }
};

const verifyEmail = (jwt: any) => async (dispatch: Dispatch<AuthActionsTypes | ILoadingActionsTypes>) => {
  try {
    await dispatch({ type: ILoadingActions.LOADING });
    await LightpayApi.post('/auth/verify-email', '', {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `bearer ${jwt}`,
      },
    });
    await dispatch({ type: ILoadingActions.LOADED });
  } catch (error) {
    console.log(error);
  }
};

const requestVerifyEmail = () => async (dispatch: Dispatch<ILoadingActionsTypes>) => {
  try {
    await dispatch({ type: ILoadingActions.LOADING });
    await LightpayApi.get<IServerResponseVerifyEmail>('/auth/verify-email', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `bearer ${sessionStorage.getItem('token')}`,
      },
    });
    await dispatch({ type: ILoadingActions.LOADED });
  } catch (error) {
    console.log(error);
  }
};

const isItVerified = (isIt: boolean) => async (dispatch: Dispatch<AuthActionsTypes | ILoadingActionsTypes>) => {
  dispatch({ type: AuthActions.VERIFY_EMAIL, payload: isIt });
};

const setJwt = (jwt?: string | null) => async (dispatch: Dispatch<AuthActionsTypes>) => {
  try {
    // This sould be refacroted
    if (jwt) await dispatch({ type: AuthActions.SET_JWT_TOKEN, payload: jwt });
  } catch (error) {
    console.log(error);
  }
};

const setBuyJwt = (jwt?: string | null) => async (dispatch: Dispatch<AuthActionsTypes>) => {
  try {
    // This sould be refacroted
    if (jwt) await dispatch({ type: AuthActions.SET_BUY_JWT_TOKEN, payload: jwt });
  } catch (error) {
    console.log(error);
  }
};

const jwtLogin = (jwt: string) => async (dispatch: Dispatch<AuthActionsTypes | ILoadingActionsTypes>) => {
  try {
    await dispatch({ type: ILoadingActions.LOADING });
    LightpayApi.setJWT(jwt);
    const { data } = await LightpayApi.get('/auth/validate');
    await dispatch({
      type: AuthActions.LOGIN_SUCCESS,
      payload: data.user,
    });
    await dispatch({ type: ILoadingActions.LOADED });
  } catch (error) {
    const formatError: IFormatError = LightpayApi.errorHandler(error);
    await dispatch({
      type: AuthActions.LOGIN_FAIL,
      error: formatError,
    });
    await dispatch({ type: ILoadingActions.LOADED });
    throw formatError;
  }
};

const recoverPassword = (email: string) => async (dispatch: Dispatch<AuthActionsTypes | ILoadingActionsTypes>) => {
  try {
    await dispatch({ type: ILoadingActions.LOADING });
    await dispatch({ type: AuthActions.RECOVER_PASSWORD, payload: email });
    const response = await LightpayApi.post('/auth/reset-password/request', {
      email,
      site: "pagador"
    });
    await dispatch({ type: ILoadingActions.LOADED });

    return response;
  } catch (error) {
    const formatError: IFormatError = LightpayApi.errorHandler(error);
    formatError.message = handleCustomErrors('FORGOT', formatError.code) || formatError.message;
    await dispatch({ type: ILoadingActions.LOADED });
    await dispatch({
      type: AuthActions.RECOVER_PASSWORD_FAIL,
      error: formatError,
    });
    throw formatError;
  }
};

// tslint:disable-next-line: max-line-length
const resetPassword = (password: string, token: string | undefined) => async (
  dispatch: Dispatch<AuthActionsTypes | ILoadingActionsTypes>,
) => {
  try {
    await dispatch({ type: ILoadingActions.LOADING });
    await dispatch({ type: AuthActions.RESET_PASSWORD });
    const response = await LightpayApi.post(
      '/auth/reset-password',
      {
        password,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `bearer ${token}`,
        },
      },
    );
    await dispatch({ type: ILoadingActions.LOADED });
    return response;
  } catch (error) {
    const formatError: IFormatError = LightpayApi.errorHandler(error);
    await dispatch({ type: ILoadingActions.LOADED });
    await dispatch({
      type: AuthActions.RECOVER_PASSWORD_FAIL,
      error: formatError,
    });
    throw formatError;
  }
};

const logout = () => async (dispatch: Dispatch<AuthActionsTypes>, getState: any) => {
  let fullState = getState()
  if (fullState?.modal?.options?.idModal === 'extraBankData') {
    return
  }
  sessionStorage.removeItem('token');
  await dispatch({ type: AuthActions.LOGOUT });
};

const registerEmail = (email?: string | null | undefined, name?: string, surname?: string, secondSurname?: string, commerceName?: string) => async (dispatch: Dispatch<AuthActionsTypes>) => {

  await LightpayApi.post(`/auth/retargeting`, { email, name, surname, secondSurname, origin: 'pagos.slightpay', commerceName },
    { headers: { 'Content-Type': 'application/json' } });
};

const updateUser = (registerFormData: any) => async (dispatch: Dispatch<AuthActionsTypes | ILoadingActionsTypes>, getState: any) => {
  try {
    dispatch({ type: ILoadingActions.LOADING });
    let data: any = {
      firstName: registerFormData.name,
      lastName: registerFormData.surname,
      secondLastName: registerFormData.secondSurname,
      municipality: registerFormData.municipality,
      state: registerFormData.state,
      street: registerFormData.street,
      suburb: registerFormData.colony,
      zipCode: registerFormData.cp,
      rfc: registerFormData.rfc,
      birthDate: `${registerFormData.birthDate.split('/').reverse()[0]}/${registerFormData.birthDate.split('/').reverse()[1]}/${registerFormData.birthDate.split('/').reverse()[2]}`
    };

    const response: any = await LightpayApi.put(
      '/auth/payer-update',
      data,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      },
    );
    console.log('response update user', response)
    await dispatch({
      type: AuthActions.LOGIN_SUCCESS,
      payload: { ...response.data.userUpdate, address: { ...response.data.addressUpdate[0] } },
    });
    dispatch({ type: ILoadingActions.LOADED });
  } catch (error) {
    console.log(error)
    const formatError: IFormatError = LightpayApi.errorHandler(error, 'REGISTER');
    // dispatch({ type: AuthActions.REGISTER_USER_FAIL, error: formatError });
    dispatch({ type: ILoadingActions.LOADED });

    throw formatError;
  }
};

const getDataPostalCode = async (cp: string) => {
  console.log(cp, 'getDataPostalCode')
  try {
    let result: any = await LightpayApi.post(`/scoring/postal-code`, { cp },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `bearer ${sessionStorage.getItem('token')}`,
        }
      });

    console.log(result.data, 'actions')

    return result.data
  } catch (e) {
    console.log(e)
  }
};

const sendBuroDeCredito = (purchase) => async (dispatch: Dispatch<AuthActionsTypes | ILoadingActionsTypes>) => {
  try {
    // dispatch({ type: ILoadingActions.LOADING });
    console.log('token...', sessionStorage.getItem('token'))
    let result: any = await LightpayApi.post(`/scoring/buro-credito`,
      purchase,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `bearer ${sessionStorage.getItem('token')}`,
        }
      }
    )

    console.log(result, 'el resultado de scoring')
    dispatch({ type: AuthActions.SET_SCORING_RESULT, payload: result.data });

    // dispatch({ type: ILoadingActions.LOADED });

    return result
  } catch (error) {
    console.log("Send Error", error)
    const formatError: IFormatError = LightpayApi.errorHandler(error);
    // dispatch({ type: ILoadingActions.LOADED });
    throw formatError;
  }
}

const setFinerioSuccess = () => async (dispatch: Dispatch<AuthActionsTypes | ILoadingActionsTypes>) => {
  dispatch({
    type: AuthActions.SET_SCORING_RESULT, payload: {
      status: 'SUCCESS',
      message: 'El usuario ha sido aprobado exitosamente'
    }
  });
}

const checkPhoneVerified = async (email?: string) => {
  const responsePhone: any = await LightpayApi.post('/users/get-phone-verified',
    {},
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `bearer ${sessionStorage.getItem('token')}`,
      },
    }
  );
  console.log(responsePhone);
  return responsePhone.data;
  //let responsePhone: any = await LightpayApi.post(`/users/get-phone-verified`, { headers: { 'Content-Type': 'application/json' } });
  //console.log(responseScoringScorce, responseScoringScorce.data.data.aceptado, 'scorce')
  //if (!responseScoringScorce.data.data.aceptado) throw new Error('EMAIL_NO_VALID')
}

const updatePhoneVerified = () => async (dispatch: Dispatch<AuthActionsTypes | ILoadingActionsTypes>) => {
  const responsePhone: any = await LightpayApi.put(`/users/update-phone-verified`,
    {},
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `bearer ${sessionStorage.getItem('token')}`,
      },
    }
  );
  console.log(responsePhone);
  return responsePhone.data;
  //let responsePhone: any = await LightpayApi.post(`/users/get-phone-verified`, { headers: { 'Content-Type': 'application/json' } });
  //console.log(responseScoringScorce, responseScoringScorce.data.data.aceptado, 'scorce')
  //if (!responseScoringScorce.data.data.aceptado) throw new Error('EMAIL_NO_VALID')
}

export const authActions = {
  addIneImages,
  addPhoneNumber,
  checkData,
  registerUser,
  login,
  setJwt,
  jwtLogin,
  setBuyJwt,
  recoverPassword,
  resetPassword,
  verifyEmail,
  requestVerifyEmail,
  isItVerified,
  logout,
  handleCustomErrors,
  checkPhone,
  getCodePhone,
  registerEmail,
  updateUser,
  getDataPostalCode,
  loginRemote,
  sendBuroDeCredito,
  setFinerioSuccess,
  updatePhoneNumber,
  getPhoneNumber,
  checkPhoneVerified,
  updatePhoneVerified
};
