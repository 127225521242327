import React from 'react'
import LoaderBankContainer from './loaderSuccessStyle';


const LoaderSuccess = (props: any) => {
  return (
   <LoaderBankContainer>
    <div className="containerConected">
    <div className="lds-ring">
      <div></div>
      <div></div>
      <div></div>
    </div>
    </div>
   </LoaderBankContainer>
  )
}

export default LoaderSuccess
