import React from "react";
import MainButtonContainer from "./main-button-style";
import { IMainButtonProps } from "./main-button-types";

const MainButton: React.FC<IMainButtonProps> = (props: IMainButtonProps) => {
  const { text, type, icon, iconImg, onClick, className, disabled } = props;

  return (
    <MainButtonContainer
      className={`${className} ${type} ${disabled ? "disabled" : ""}`}
      onClick={() => (onClick && !disabled ? onClick() : "")}
    >
      {icon && (
        <div className="main-button-icon">
          <img src={iconImg ? iconImg : ""} alt="main button icon" />
        </div>
      )}

      <p className="main-button-text">{text}</p>
    </MainButtonContainer>
  );
};

export default MainButton;
