interface IInterfaceErrorMessage {
  required: string;
  'invalid-format': string;
  'invalid-prefix': string;
  'invalid-email': string;
  'invalid-date': string;
  [key: string]: string;
}

export const ERROR_MESSAGES: IInterfaceErrorMessage = {
  required: 'Campo obligatorio',
  'too-short-name': 'Nombre muy corto',
  'too-short-surname': 'Apellido muy corto',
  'too-short-password': 'La contraseña es demasiado corta, debe contener un mínimo de 8 caracteres',
  'too-short-password-bank': 'La contraseña es demasiado corta',
  'too-short-tdc': 'Debe ingresar 4 dígitos',
  'too-short-description': 'La descripción es muy corta',
  'too-short-address': 'Dirección muy corta',
  'too-short-clabe': 'La cuenta CLABE debe ser de 18 dígitos',
  'too-heavy-file': 'El archivo debe pesar un máximo de 6MB',
  'too-long-turn': 'La descripción del giro comercial debe ser de máximo 40 caracteres',
  'too-short-cvv': 'El código de seguridad de la tarjeta debe ser de mínimo 3 dígitos',
  'too-short-cvv-mx': 'El código de seguridad de la tarjeta debe ser de mínimo 4 dígitos',
  'too-short-month': 'El mes y año debe ser de 2 dígitos.',
  'no-match': 'La contraseña no coincide',
  'no-strong':
    'La contraseña es débil, debe contener al menos una mayúscula, una minúscula, un signo de puntuación y un número',
  'accept-terms': 'Debes aceptar los términos y condiciones.',
  'both-ine-images-needed': 'Debe agregar ambas imágenes, y estas deben ser de extención .png o .jpeg',
  'invalid-format': 'Formato no válido',
  'invalid-url': 'La url debe contener https://, ser valida y un mínimo de 2 letras',
  'invalid-name': 'El nombre y apellido no pueden contener símbolos o números',
  'invalid-rfc': 'El número que ha ingresado no es válido',
  'invalid-prefix': 'Prefijo no válido',
  'invalid-phone': 'Teléfono no válido, por favor indique un formato del siguiente tipo: +52 923659832',
  'invalid-email': 'Email no válido',
  'invalid-date': 'Fecha no válida',
  'invalid-password': 'La contraseña es insegura',
  'invalid-image': 'La extensión es erronea, solo se acepta .png, .jpeg',
  'invalid-pdf': 'La extensión es erronea, solo se acepta .pdf',
  'invalid-xml': 'La extensión es erronea, solo se acepta .xml',
  'invalid-nationality': 'Este campo no puede contener símbolos o números',
  'invalid-month': 'Mes no válido.',
  'invalid-year': 'Año no válido.',
  'invalid-card': 'El número de tarjeta no es correcto.',
  'email-not-found': 'No existe ese correo en nuestra base de datos',
  'invalid-date-rfc': 'La fecha de nacimiento no coincide con el RFC',
  'EMAIL_NO_VALID': 'No es posible registrarse con este email, verifica la direccion de correo',
  'RFC_NO_VALID': 'El RFC no es válido, por favor verifiquelo y vuelva a intentar.'
};
