import Cleave from 'cleave.js/react';
import React, { Component } from 'react';
import CalendarItem from './calendar-item';
import { CalendarBoxContainer } from './calendar-box-style';
import { calendarIcon } from '../../assets/images/images';
import { LabelText } from '../components-text/label-text/label-text';
import { ERROR_MESSAGES } from '../../constants/errorMessages';
import ErrorText from '../components-text/error-text/error-text';
import moment from 'moment';

interface ICalendarBoxProps {
  className?: string;
  placeholder?: string;
  disabled?: boolean;
  icon?: string;
  iconDisabled?: string;
  errorCode?: string;
  rangeSelect?: boolean;
  multiSelect?: boolean;
  forbidFutureDates?: boolean;
  forbidPastDates?: boolean;
  minAge?: number;
  maxAge?: number;
  required?: boolean;
  initialValue: string;
  labelText?: string;
  initialMultiValues?: string[];
  onChange: (value: string, error?: boolean, secondValue?: string) => void;
  onChangeMulti?: (values: string[]) => void;
  clearValue?: boolean;
  withTooltip?: boolean;
  tooltipTitle?: string;
  tooltipText?: string;
}

interface ICalendarBoxState {
  focus: boolean;
  showCalendar: boolean;
  showTooltip: boolean;
  value: string;
  secondValue?: string;
  multiValues?: string[];
}

class CalendarBox extends Component<ICalendarBoxProps, ICalendarBoxState> {
  public static defaultProps = {
    icon: calendarIcon,
  };
  private toggleContainer: any;

  constructor(props: ICalendarBoxProps) {
    super(props);
    this.state = {
      focus: false,
      showCalendar: false,
      showTooltip: false,
      value: '',
      secondValue: '',
      multiValues: [],
    };
    this.toggleContainer = React.createRef();

    this.onClickOutsideHandler = this.onClickOutsideHandler.bind(this);
  }

  public componentDidMount() {
    const { value } = this.state;
    const { initialValue, rangeSelect } = this.props;
    if (initialValue && value === '') {
      if (rangeSelect) {
        this.setState({ value: initialValue, secondValue: initialValue });
      } else {
        this.setState({ value: initialValue });
      }
    }
    window.addEventListener('click', this.onClickOutsideHandler);
  }

  public componentDidUpdate(prevProps: ICalendarBoxProps) {
    const { initialValue, initialMultiValues, clearValue, rangeSelect } = this.props;
    if (initialValue && initialValue !== prevProps.initialValue) {
      if (rangeSelect) {
        this.setState({ value: initialValue, secondValue: initialValue });
      } else {
        this.setState({ value: initialValue });
      }
    } else if (clearValue) {
      this.setState({ value: '' });
    }
    if (initialMultiValues && initialMultiValues !== prevProps.initialMultiValues) {
      this.setState({
        multiValues: initialMultiValues.map(value => moment(value).format( 'DD/MM/YYYY')),
      });
    }
  }

  public componentWillUnmount() {
    window.removeEventListener('click', this.onClickOutsideHandler);
  }

  public onClickOutsideHandler(event: Event) {
    if (this.state.showCalendar && !this.toggleContainer.current.contains(event.target)) {
      this.onToogle(false);
    }
  }

  public onToogle(show: boolean) {
    this.setState({ showCalendar: show });
  }

  public onToggleCalendar(show: boolean, firstValue?: string, secondVal?: string) {
    const { secondValue, value } = this.state;
    const { onChange } = this.props;
    const secondValueAux = secondVal !== null && secondVal !== undefined ? secondVal : secondValue;
    const firstValueAux = firstValue !== null && firstValue !== undefined ? firstValue : value;
    this.setState({ value: firstValueAux, secondValue: secondValueAux, showCalendar: show }, () => {
      if (!show) {
        onChange(firstValueAux, false, secondValueAux);
      }
    });
  }

  public render() {
    const {
      disabled,
      placeholder,
      className,
      icon,
      iconDisabled,
      minAge,
      maxAge,
      labelText,
      errorCode,
      required,
      rangeSelect,
      multiSelect,
    } = this.props;
    const {
      showCalendar,

      value,
      secondValue,
      multiValues,
    } = this.state;
    const errorText = errorCode ? ERROR_MESSAGES[errorCode] : '';
    return (
      <CalendarBoxContainer
        ref={this.toggleContainer}
        className={`${className} ${showCalendar ? 'show-calendar' : ''}
        ${disabled ? 'disabled' : ''} ${errorCode ? 'error' : ''}
        ${value !== '' ? 'complete' : ''} ${required ? 'required' : ''} ${rangeSelect ? 'range' : ''} `}
      >
        <div className="input-box-topbar">
          <div className="input-box-topbar-label">
            <LabelText>{labelText}</LabelText>
          </div>
        </div>
        <div className="input-box-main">
          {!multiSelect ? (
            <div className="input-box-main-field">
              <Cleave
                className="calendar-box-fix-position"
                placeholder={placeholder || ''}
                value={value}
                options={{ date: true }}
                onBlur={e => {
                  this.onToggleCalendar(false, e.target.value, undefined);
                }}
              />
              {secondValue && (
                <>
                  {'-'}
                  <Cleave
                    placeholder={placeholder || ''}
                    value={secondValue}
                    options={{ date: true }}
                    onBlur={e => {
                      this.onToggleCalendar(false, undefined, e.target.value);
                    }}
                  />
                </>
              )}
            </div>
          ) : (
            <div className="input-box-main-field">
              {multiValues &&
                multiValues.map((multiValue, index) => (
                  <>
                    {multiValue}
                    {index !== multiValues.length - 1 && ', '}
                  </>
                ))}
            </div>
          )}
          <div className="input-box-icon" onClick={() => this.onToogle(!showCalendar)}>
            <img src={disabled ? iconDisabled : icon} />
          </div>
        </div>
        <div className="calendar-popup">
          <CalendarItem
            className="select"
            minAge={minAge}
            maxAge={maxAge}
            initialValue={value}
            rangeSelect={rangeSelect}
            multiSelect={multiSelect}
            // tslint:disable-next-line:no-shadowed-variable
            onSelect={(value, closeCalendar) => {
              this.onToggleCalendar(!closeCalendar, value, secondValue);
            }}
          />
        </div>

        <ErrorText>{errorCode && errorText}</ErrorText>
      </CalendarBoxContainer>
    );
  }
}

export default CalendarBox;
