import styled from 'styled-components/macro';
import { InputColors } from '../../assets/colors/input-colors/input-colors';


export const InputBoxContainer = styled.div`
  .input-box-topbar {
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 9;
    margin-bottom: 8px;
  }

  .input-box-topbar-label {
    color: ${InputColors.label};
    text-transform: uppercase;
    &.right {
      color: #1A4CCD;
      cursor: pointer;
      font-size: 12px;
      line-height: 14px;
    }
  }

  .input-box-topbar-icon {
    position: relative;
    z-index: 9;
    display: inherit;
    img {
      max-width: 18px;
      cursor: pointer;
    }
  }

  .input-box-main-field {
    position: relative;
    margin-bottom: 4px;
  }

  input {
    font-family: 'gilroy';
    line-height: 24px;
    font-size: 16px;
    width: 100%;
    font-weight: 'regular';
    padding: 12px 0 11px;
    transition: all 0.2s ease-in-out;
    color: ${InputColors.value.default};
    min-height: 24px;
    border-bottom: 1px solid ${InputColors.input.default};

    &::placeholder  {
      color: ${InputColors.placeholder.default};
      &:focus {
        color: ${InputColors.placeholder.focus};
      }
    }

    &:focus {
      color: ${InputColors.value.focus};
      border-bottom: 1px solid ${InputColors.input.focus};
    }

    &:hover {
      color: ${InputColors.value.hover};
      border-bottom: 1px solid ${InputColors.input.hover};
      &::placeholder  {
        color: ${InputColors.placeholder.hover};
      }
    }

    &:disabled {
      color: ${InputColors.value.disabled};
      border-bottom: 1px solid ${InputColors.input.disabled};

      &:focus {
        color: ${InputColors.value.disabled} !important;
        border-bottom: 1px solid ${InputColors.input.disabled};
      }
    }
  }

  .input-token {
    border: 0;
    font-family: 'gilroy';
    line-height: 24px;
    font-size: 16px;
    width: 100%;
    font-weight: 'regular';
    padding: 12px 0 11px;
    padding-left: 30px;
    transition: all 0.2s ease-in-out;
    color: ${InputColors.value.default};
    min-height: 24px;
    border-radius: 15px;
    background: white;
    &::placeholder  {
      color: ${InputColors.placeholder.default};
      &:focus {
        color: ${InputColors.placeholder.focus};
      }
    }

    &:focus {
      color: ${InputColors.value.focus};
      border: 1px solid ${InputColors.input.focus};
    }

    &:hover {
      color: ${InputColors.value.hover};
      border: 1px solid ${InputColors.input.hover};
      &::placeholder  {
        color: ${InputColors.placeholder.hover};
      }
    }

    &:disabled {
      color: ${InputColors.value.disabled};
      border: 1px solid ${InputColors.input.disabled};

      &:focus {
        color: ${InputColors.value.disabled} !important;
        border: 1px solid ${InputColors.input.disabled};
      }
    }
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .input-box-main {
    position: relative;
  }

  .input-box-icon {
    position: absolute;
    top: 13px;
    right: 6px;
    width: 20px;
    z-index: 10;
    img {
      max-width: 18px;
      cursor: pointer;
    }
  }

  .input-box-tooltip {
    position: absolute;
    top: calc(100% + 12px);
    z-index: 29;
    transform: translateX(-50%);
    width: 260px;
    right: 0;
  }

  .input-box-error {
    /* color: ${props => props.theme.colors.onError}; */
    color: ${InputColors.input.error};
  }

  &.error {
    input {
      color: ${InputColors.value.error};
      border-bottom: 1px solid ${InputColors.input.error};

      &::placeholder {
        color: ${InputColors.placeholder.error};
      }
    }

    .input-box-error {
      color: ${InputColors.errorText};
    }
  }
`;
