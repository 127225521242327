import React, { useEffect, useState } from "react";
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams, useLocation, Link } from 'react-router-dom';
import InputBox from "../../../components/input-box/input-box";
import MainButton from "../../../components/main-button/main-button";
import ModalRecoveyPass from "../../../components/modals/modal-recovery-pass/modal-recovery-pass";
import PasswordBox from "../../../components/password-box/password-box";
import { authActions } from '../../../store/auth/actions';
import { getLoginInputMetada, LOGIN_EMAIL_INPUT_NAME, LOGIN_PASSWORD_INPUT_NAME } from '../../../utils/login-input-names';
import { getLoginFormErrors } from '../../../utils/login-input-validations';
import { LoginStyle } from "./login-style";
import { paymentActions } from "../../../store/payment/actions";
import LogoIcon from "../../../assets/icons/Logo/logo-icon";
import useModal from "../../../hooks/useModal/use-modal";
import { loadSelector } from "../../../store/loader/selectors";
import { loaderActions } from "../../../store/loader/actions";
import Loader from "../../../components/loader/loader";
import amplitude from 'amplitude-js'
// import ModalErrorCard from "../../../components/modals/modal-error/modal-error";
// import { loadSelector } from  '../../../store/loader/selectors';
import { paymentSelector } from '../../../store/payment/selectors';
// import { useSelector } from 'react-redux';
// import { IPurchase } from '@aluxion-lightpay/interfaces';
// import { formatMoney } from '../../../utils/utils';
import * as firebase from "firebase";
import QRCode from 'qrcode.react'
import Accordion from "../../../components/accordion/accordion";
import ModalModifyPhone from "../../../components/modals/modal-modify-phone/modal-modify-phone";
import ModalBankData from "../../../components/modals/modal-bank-data/modal-bank-data";
import SendApiConversion from "../../../constants/apiConversionFb";
import { authSelectors } from "../../../store/auth/selectors";

const Login: React.FC = () => {
  const tokenURL: any = window.location.pathname.includes('ey') ? window.location.pathname.slice(1) : sessionStorage.getItem('buyToken')

  if(tokenURL) {
    window.location.href = `${window.location.origin}/#/${tokenURL}`
  }

  const { openModal, closeModal, isModal } = useModal();
  // const loading = useSelector(loadSelector.isLoading());
  const purchasesData: any = useSelector(paymentSelector.purchasesData());
  const me : any = useSelector(authSelectors.getMe())
  // const [isNaNError, setIsNaNError] = useState(true)
  const dispatch = useDispatch();
  const history = useHistory();
  const { register, handleSubmit, errors } = useForm();
  const { token } = useParams();
  const query = new URLSearchParams(useLocation().search);
  const loading = useSelector(loadSelector.isLoading());
  const [presentialOrder, setPO] = useState<boolean>(false);
  const commerceBlock = useSelector(paymentSelector.getCommerceBlock());
  // const handleIsNaN = (val) => formatMoney(val) === '$NaN'
  console.log(loading, "FormLoading");

  const handleForm = async (data: any) => {
    try {
      await dispatch(loaderActions.loading());
      await dispatch(authActions.login(data[LOGIN_EMAIL_INPUT_NAME], data[LOGIN_PASSWORD_INPUT_NAME], query.get("justlogin")));
      dispatch(authActions.setJwt(sessionStorage.getItem('token')));
      await dispatch(loaderActions.loaded());
      amplitude.getInstance().logEvent('logged in', { 'account_type': 'shopper', 'email': data[LOGIN_EMAIL_INPUT_NAME], 'location': 'pagos.slightpay', 'merchantName': purchasesData?.commerce.name },);
      //SendApiConversion('logged in',[data[LOGIN_EMAIL_INPUT_NAME]],me ? [me?.phoneNumber] : ['12345643'],purchasesData?.commerce.name);
      //setPO(false);
      //history.push(`/select-payment/${token}`);
    } catch (error) {
      if (error.statusCode === 403) {
        openModal('errorLogin');
      }
      amplitude.getInstance().logEvent('error encountered', { 'error_type': 'login', 'error_reason': JSON.stringify(error) });
      await dispatch(loaderActions.loaded());
      console.log(error);
    }
  };

  useEffect(() => {
    if (!sessionStorage.getItem('buyToken') && !sessionStorage.getItem('query')) {
      sessionStorage.setItem('buyToken', token);
      query.get('sandbox') === 'true' ? sessionStorage.setItem('query', 'true') : sessionStorage.setItem('query', 'false')
    }
    dispatch(authActions.setBuyJwt(token));
    const getData = async () => {
      await dispatch(paymentActions.getPaymentData(token));
    }
    if (sessionStorage.getItem('buyToken') === 'null') {
      sessionStorage.setItem('buyToken', token);
      getData();
    } else {
      getData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, token])

  useEffect(() => {
    let size = purchasesData?.products?.length
    amplitude.getInstance().logEvent('checkout started', { "totalCartValue": purchasesData?.subtotal, "cartSize": size, 'merchantName': purchasesData?.commerce.name });
    console.log("PD Remote "+purchasesData)

    if (!query.get("justlogin") && purchasesData?.id) {
      setPO(true)
      let database = firebase.database();

      //Logic to listening Firebase and then login
      let purchasesInfo = database.ref('compras/' + purchasesData.id);
      purchasesInfo.on('value', async function (snapshot) {
        let values = snapshot.val()
        if (values?.token && values?.user) {
          // Logic to login and set the properties...
          console.log(values?.token, values?.user)
          dispatch(authActions.loginRemote(values?.token, values?.user))
          await dispatch(loaderActions.loaded());
          dispatch(authActions.setJwt(sessionStorage.getItem('token')));
        } else if (values?.logout) {
          purchasesInfo.remove()
            .then(function () {
              console.log("Remove succeeded.")
            })
            .catch(function (error) {
              console.log("Remove failed: " + error.message)
            });
        }
      });
    }
  }, [purchasesData])

  return (
    <>
      {loading && <><Loader /></>}
      <LoginStyle>
        {/* {presentialOrder ?
            <div className="login-container">
            <div className="main-layout-logo">
              <LogoIcon scale="1.3" fill="" />
            </div>
            <div className="">
              <p style={{ marginBottom: '20px' }}>Para finalizar el pago de tu compra en <b style={{ fontWeight: 800 }}>{purchasesData?.merchantName ? purchasesData?.merchantName : purchasesData?.commerce?.name}</b>, inicia o crea tu cuenta SlightPay.</p>
              <div className="steps">
                <p className="step-number">01</p>
                <p className="step-text">El cliente deberá escanear el siguiente código QR para seguir con la operación.</p>
              </div>
              <div className="qr-code">
                <QRCode value={`${window.location.href}?justlogin=true`} />
              </div>
            </div>
            <div className="">
              <div className="steps">
                <p className="step-number">02</p>
                <p className="step-text">El cliente debe entrar a su cuenta o registrarse desde su celular.</p>
              </div>
            </div>
            <div className="">
              <div className="steps">
                <p className="step-number">03</p>
                <p className="step-text">El cliente debe seleccionar Pago con Wallet y seguir con los pasos del pago.</p>
              </div>
            </div>
          </div>
        : */}
        
        <div className="login-container">
          <div className="main-layout-logo">
            <LogoIcon scale="1.3" fill="" />
          </div>
          {presentialOrder &&
            <div style={{marginBottom : "50px", boxShadow: "8px 8px 6px -1px rgb(0 0 0 / 0.1)"}}> 
            <Accordion title="Pago en tienda física">
            <div className="">
              <div className="steps">
                <p className="step-number">01</p>
                <p className="step-text">El cliente deberá escanear el siguiente código QR para seguir con la operación.</p>
              </div>
              <div className="qr-code">
                <QRCode value={`${window.location.href}?justlogin=true`} />
              </div>
            </div>
            <div className="">
              <div className="steps">
                <p className="step-number">02</p>
                <p className="step-text">Entrar a su cuenta o registrarse desde su celular.</p>
              </div>
            </div>
            <div className="">
              <div className="steps">
                <p className="step-number">03</p>
                <p className="step-text">Seleccionar su método de pago y seguir con los pasos.</p>
              </div>
            </div>
            </Accordion>
          </div>
          }
          <div className="login-form">
            <div className="form-row">
              <p style={{ marginBottom: '20px' }}>Para finalizar el pago de tu compra en <b style={{ fontWeight: 800 }}>{purchasesData?.merchantName ? purchasesData?.merchantName : purchasesData?.commerce?.name}</b>, inicia o crea tu cuenta SlightPay.</p>
              <p style={{ marginBottom: '50px' }}>Recuerda que si no concluyes el pago, la tienda cancelará tu orden.</p>
            </div>
            <div className="form-row">
              <InputBox
                name={LOGIN_EMAIL_INPUT_NAME}
                labelText="correo electrónico"
                inputRef={register(getLoginInputMetada(LOGIN_EMAIL_INPUT_NAME))}
                errorCode={getLoginFormErrors(errors, LOGIN_EMAIL_INPUT_NAME)}
                onKeyPress={handleSubmit(handleForm)}
              />
            </div>
            <div className="form-row">
              <PasswordBox
                name={LOGIN_PASSWORD_INPUT_NAME}
                labelText="contraseña"
                inputRef={register(getLoginInputMetada(LOGIN_PASSWORD_INPUT_NAME))}
                errorCode={getLoginFormErrors(errors, LOGIN_PASSWORD_INPUT_NAME)}
                onKeyPress={handleSubmit(handleForm)}
              />
            </div>
          </div>
          <div
            className="login-recovery-pass"
            onClick={() => openModal('recoverPassword')}
          >
            <p>He olvidado mi contraseña</p>
          </div>
          <div className="login-submit">
            <MainButton
              type="primary"
              text="Iniciar sesión"
              onClick={handleSubmit(handleForm)}
              disabled= {commerceBlock ? true : false}
            />
          </div>
          <div className="login-split">
            <div className="login-split__line" />
            <div className="login-split__text">
              <p>o</p>
            </div>
            <div className="login-split__line" />
          </div>
          <div className="login-registre">
            <MainButton
              type="secondary"
              text="Crear cuenta nueva"
              onClick={ () => history.push('/registre')}
              disabled= {commerceBlock ? true : false}
            />
          </div>
          <a href='mailto:soporte@slightpay.com'>
            <p style={{ color: '#1A4CCD', textAlign: 'center', marginTop: '30px', fontSize: '14px' }}>¿Dudas sobre tu pago con SlightPay?</p>
          </a>
        {/*  {!sessionStorage.getItem("justlogin") &&
          <div onClick={() => setPO(true)}>
            <p style={{ color: '#1A4CCD', textAlign: 'center', marginTop: '30px', fontSize: '14px' ,cursor: 'pointer'}}>Compra física</p>
          </div>} */}

        </div>
      </LoginStyle>
      {/* MODALS */}
      <ModalRecoveyPass
        modalConfig={{
          onClose: closeModal,
          active: (isModal?.show && isModal?.idModal === 'recoverPassword') || false,
        }}
      />
      {/* <ModalErrorCard
          modalConfig={{
            onClose: closeModal,
            active: (isModal?.show && isModal?.idModal === 'errorLogin') || false,
          }}
          importantTitle="Esta ingresando con una cuenta de comercio, por favor registrate nuevamente como cliente."
        /> */}
      {/* 
        {!loading && (
          <ModalErrorCard
          isNaNError={true}
          modalConfig={{
            onClose: ()=>{
              setIsNaNError(false)
              closeModal();
            },
            active:  isNaNError && purchasesData === null,
          }}
          importantTitle="Estamos teniendo dificultades tecnicas, por favor intente mas tarde"
        />
        )} */}
      {/* {!loading && !!purchasesData && (
          <ModalErrorCard
          isNaNError={true}
          modalConfig={{
            onClose: ()=>{
              setIsNaNError(false)
              closeModal();
            },
            active:  isNaNError && !!purchasesData &&  (handleIsNaN(purchasesData.payments[3].amount) ||handleIsNaN(purchasesData.payments[2].amount) ||handleIsNaN(purchasesData.payments[1].amount) ||handleIsNaN(purchasesData.payments[0].amount) || handleIsNaN(purchasesData.tax) || handleIsNaN(purchasesData.tax)),
          }}
          importantTitle="Hay un error en la cantidad del producto verifícalo con tu comercio."
        />
        )} */}
      {/* 
        <ModalSms 
          modalConfig={{
            onClose: closeModal,
            active: (isModal?.show && isModal?.idModal === 'SMS') || false,
          }}
          onClick= {() => openModal('SmsCode')}
        />

        <ModalModifyPhone 
          modalConfig={{
            onClose: closeModal,
            active: (isModal?.show && isModal?.idModal === 'ModalPhone') || false,
          }}
        /> */}
    </>
  );
};
export default Login;
