import styled from 'styled-components';

const ErrorBankStyles = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-content: center;
  flex-direction: column;
  align-items: center;

  .containerConected{
  margin-top: 1rem;
  background-color: #F7F7F7;
  width: 70%;
  height: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 15px;
}
.contentTitle{
  background-color: #fff;
  border-radius: 15px;
  width: 85%;
  text-align: center;
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 0 10px;
}


`

export default ErrorBankStyles;