import { IBaseAction } from '../base-action.interface';
import { IPurchase } from '@aluxion-lightpay/interfaces';

export enum IPaymentActions {
  GET_PURCHASE = 'GET_PURCHASE',
  PURCHASE_ERROR = 'PURCHASE_ERROR',
  IS_ONESHOT = 'IS_ONESHOT',
  TYPE = 'TYPE',
  COMMERCE_BLOCK = 'COMMERCE_BLOCK'
}

export interface IPurchaseErrors{
  message: string;
  path?: string;
  value?: string;
  validation?: string;
}

export interface IPaymentState {
  readonly purchases: Partial<IPurchase> | null;
  readonly purchaseError: Partial<IPurchaseErrors> | null;
  readonly isOneShot: boolean | null;
  readonly type: string
  readonly commerceBlock: boolean;
}

export interface IPaymentReducer {
  (state: IPaymentState, action: IPaymentActionsTypes): IPaymentState;
}

export interface IGetPurchaseAction extends IBaseAction {
  type: IPaymentActions.GET_PURCHASE;
  payload: IPurchase
}

export interface IGetPurchaseErrorAction extends IBaseAction {
  type: IPaymentActions.PURCHASE_ERROR;
  error: IPurchaseErrors
}
export interface IOneShot extends IBaseAction {
  type: IPaymentActions.IS_ONESHOT;
  payload: boolean
}

export interface IPaymentMethod extends IBaseAction {
  type: IPaymentActions.TYPE;
  payload: string
}

export interface ICommerceBlock extends IBaseAction {
  type: IPaymentActions.COMMERCE_BLOCK;
  payload: boolean
}

export type IPaymentActionsTypes = IGetPurchaseAction | IGetPurchaseErrorAction | IOneShot | IPaymentMethod | ICommerceBlock;