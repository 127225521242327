import styled from 'styled-components';

export const ItemResumeStyle = styled.div`
display: flex;
align-items: center;
.item-resume-graph {
  margin-right: 12px;
  width: 24px;
  height: 24px;
  display : flex;
  justify-content: center;
  align-items: center;
}
.item-resume-fee {
  font-size: 14px;
  line-height: 22px;
  color: #01030C;
  margin-right: 12px;
  width: 30%;
}
.item-resume-date {
  font-size: 14px;
  line-height: 22px;
  color: #01030C;
  margin-right: 12px;
  font-weight: 300;
  width: 30%;
}
.item-resume-amount {
  font-size: 14px;
  line-height: 22px;
  font-weight: 600;
  text-align: right;
  color: #1A4CCD;
  width: 30%;
}
.item-resume-amount-information{
  font-size: 14px;
  line-height: 22px;
  font-weight: 600;
  color: #1A4CCD;
  width: 32%;
  display: flex;
}

.tooltip {
	font-weight: normal;
	color: #3c48e5;
	text-decoration: none;
	position: relative;
}

.tooltip:hover {
	text-decoration: underline;
}

.tooltip:hover .tooltip-box {
	display: inline-block;
}

.tooltip-box {
	display: none;
	position: absolute;
	background-color: #fff;
	line-height: 20px;
	z-index: 500;
	text-align: center;
	color: gray;
	font-size: 14px;
	padding: 15px 10px;
	border-radius: 5px;
	left: -270px;
	right: 30px;
	bottom: -40px;
  box-shadow: 20px 20px 50px;
}
`