import React, { useState, useEffect, useCallback } from "react";
import { FormGridRow } from "../../../components/form-grid-row/form-grid-row";
import InputBox from "../../../components/input-box/input-box";
import MainButton from "../../../components/main-button/main-button";
import ModalBuyResume from "../../../components/modals/modal-buy-resume/modal-buy-resume";
import ModalErrorCard from "../../../components/modals/modal-error/modal-error";
import ModalSuccess from "../../../components/modals/modal-success/modal-success";
import SelectBox from "../../../components/select-box/select-box";
import { CreditCardStyle } from "./credit-card-style";
import { authSelectors } from "../../../store/auth/selectors";
import { useSelector, useDispatch } from 'react-redux';
import { cardActions } from "../../../store/card/actions";
import { cardSelector } from "../../../store/card/selectors";
import { Controller, useForm } from "react-hook-form";
import { SELECT_CARDS_INPUT_NAME, getCardInputMetadata, ADRESS_CARD_INPUT_NAME } from "../../../utils/login-input-names";
import { getCardFormErrors } from "../../../utils/login-input-validations";
import ModalAdress from "../../../components/modals/modal-adress/modal-adress";
import ModalToSelectPaymentMethod from "../../../components/modals/modal-to-paid/modal-select-payment";
import { paymentActions } from "../../../store/payment/actions";
import { useParams,useHistory } from "react-router-dom";
import { paymentSelector } from "../../../store/payment/selectors";
import { errorsActions } from '../../../store/errors/actions'

import { IPurchase } from "@aluxion-lightpay/interfaces";
import useWindowManager from "../../../utils/windows-manager";
import LogoIcon from "../../../assets/icons/Logo/logo-icon";
import useModal from "../../../hooks/useModal/use-modal";
import ModalBankData from "../../../components/modals/modal-bank-data/modal-bank-data";
import ModalModifyUserData from "../../../components/modals/modal-modify-user-info/modal-modify-user-info";
import { ErrorsSelector } from "../../../store/errors/selectors";
import { authActions } from "../../../store/auth/actions";
import { loadSelector } from "../../../store/loader/selectors";
import { loaderActions } from "../../../store/loader/actions";
import Loader from "../../../components/loader/loader";
import { modalActions } from "../../../store/modal/actions";
import amplitude from 'amplitude-js'
import * as firebase from "firebase";
import SendApiConversion from "../../../constants/apiConversionFb";
import TagManager from "react-gtm-module"
import GAEventTracker from "../../../hooks/useGoogleTrack";

const CreditCard: React.FC = () => {
  const [closeTimer, setCloseTimer] = useState(setTimeout(() => { }, 0));
  const history = useHistory();
  const dispatch = useDispatch();
  const { token } = useParams() as any;
  const { id }: any = useSelector(authSelectors.getMe());
  const me: any = useSelector(authSelectors.getMe());

  // const  id  = 234567;//useSelector(authSelectors.getMe());
  const purchasesData: any = useSelector(paymentSelector.purchasesData());
  const { isModal, closeModal } = useModal();
  const { items: creditCardData } = useSelector(cardSelector.getCardData());
  const { register, errors, handleSubmit, control, watch } = useForm();
  const cardSelected = watch(SELECT_CARDS_INPUT_NAME);
  const [address, setAdress] = useState<string>('Seleccione una tarjeta');
  const [idCard,  setIdCard] = useState<number | null>(null);
  const [error, setError] = useState<string>('')
  const [times, setTimes] = useState<number>(0);
  const [walletScreen, setWalletScreen] = useState<boolean>(false)
  const [finalMessage, setFinalMessage] = useState<boolean>(false)
  const { closeWindow } = useWindowManager();
  const errorsData = useSelector(ErrorsSelector.catchErrors());
  const loading  = useSelector(loadSelector.isLoading());
  const isOneShot: any = useSelector(paymentSelector.isOneShot())
  const type = useSelector(paymentSelector.type())
  const [cardName,  setCardName] = useState<any>('');
  const [lastDigits,  setLastDigits] = useState<any>('');

  useEffect(() => {
    amplitude.getInstance().init("1a071dc4782cad551bb9d7d47cfa6cf5");
    amplitude.getInstance().setUserId(`${id}SLIGHTPAY`);
  }, [id])

  const openModal = async (id: number | string) => { await dispatch(modalActions.show(id)) };
 /*  useEffect(() => {
    console.log(isModal?.idModal, errorsData)
    if (errorsData.title.includes('Debe proporcionar cierta información para autorizar su préstamo')) {
      // if (errorsData.title.includes('manual')) dispatch(paymentActions.createUserByFinerio())
      amplitude.getInstance().logEvent('open bankings scoring starts', purchasesData);
      dispatch(errorsActions.resetErrors())
      openModal('extraBankData')
    }
    else if (errorsData.title.includes('Debe proporcionar información personal correcta para comprobar su score, por favor revise sus datos')) {
       openModal('updateUserData')
      dispatch(errorsActions.resetErrors())
    }
    else if ((isModal?.idModal.length === 0 || isModal?.idModal === 'confirmBuy' ||  isModal?.idModal === 'selectPaymentMethod') && errorsData.title !== '') openModal('errorBuy');
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorsData]) */

  useEffect(() => {
    if (isModal?.idModal !== 'successBuy') {
      clearTimeout(closeTimer);
    }
  }, [closeTimer, isModal])

  let creditCards: any = [
    {
      cardName: '',
      cardDirection: '',
      id: null,
      bankName: '',
    }
  ]
  let creditCardDigits: any = []
  if (creditCardData && creditCardData.length > 0) {
    creditCards = creditCardData.map(item => {
      return {
        cardName: `Tarjeta ${item.brand} **** ${item.lastDigits} - ${item.expirationDate}`,
        cardDirection: ` ${item.address.state}, ${item.address.city}`, // ${item?.address?.line1}
        id: item.id,
        bankName: item.bankName,
      }
    })
    creditCardDigits = creditCardData.map(item => {
      return item.lastDigits      
    })
  }
  //console.log(creditCardsDigits)

  const getData = useCallback(async () => {
    await dispatch(cardActions.getCardData(id));
    // await dispatch(paymentActions.getPaymentData(token));
  }, [dispatch, id])


  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    let addressCard: any = '';
    if (cardSelected !== 'Seleccionar') {
      addressCard = creditCards.filter(item => item.cardName === cardSelected);
      if (addressCard[0] && addressCard[0]?.cardDirection) {
        setAdress(addressCard[0].cardDirection);
        setIdCard(addressCard[0].id)
        setCardName(addressCard[0].cardName);
        setLastDigits(addressCard[0].bankName)
      }
    }
  }, [cardSelected, creditCards])


  
  const handlePurchase = async (data: any) => {
    try {
      //if (isModal?.idModal === 'extraBankData'){} return;
      await dispatch(paymentActions.getPaymentData(token));
      let size = purchasesData?.products?.length
      amplitude.getInstance().logEvent('checkout started', { merchantName: purchasesData?.commerce?.name, totalCartValue: purchasesData?.totalAmount, cartSize: size, totalDiscounts: purchasesData?.extraDetails?.discountCode?.giftAmount, email: me.email });
      SendApiConversion('Initiate Checkout',[me?.email],[me?.phoneNumber],purchasesData?.commerce?.name);
      openModal('confirmBuy');
    } catch (error) {
      // fire error modal
      console.log(error, "HANDLE")
      error.reasons && setError(error.reasons[0].message)
      openModal('errorBuy')
    }

  }

  const confirmBuy = async () => {
    amplitude.getInstance().logEvent('order submitted', purchasesData);
    SendApiConversion('Client_OrderSubmited',[me?.email],[''],purchasesData?.commerce?.name,{"Total_cart" : purchasesData?.totalAmount, "Promo_code" : purchasesData?.extraDetails?.discountCode?.code,"order_id": purchasesData?.id});
    try {
      dispatch(loaderActions.loading());
      if (idCard) {
        await dispatch(paymentActions
          .confirmPurchase(idCard, purchasesData?.id,isOneShot, type,purchasesData?.totalAmount,cardName,lastDigits, purchasesData));
        setTimeout(() => {
          dispatch(loaderActions.loaded());
          openModal('successBuy');
        }, 2000)
        amplitude.getInstance().logEvent('order completed', purchasesData);
        SendApiConversion('Client_OrderCompleted',[me?.email],[''],purchasesData?.commerce?.name,{"Total_cart" : purchasesData?.totalAmount, "Promo_code" : purchasesData?.extraDetails?.discountCode?.code,"order_id": purchasesData?.id});
        GAEventTracker("Client Purchase","(Growth) Purchase",`La compra con el id ${purchasesData?.id} en el comercio ${purchasesData?.commerce?.name} ha sido exitosa`)
        setCloseTimer(
          setTimeout(() => {
            closeWindow();
          }, 7000)
        )

      }
    } catch (error) {
      console.log(error, 'existe un error')
      dispatch(loaderActions.loaded());
      openModal('errorBuy')
      // if(error.reasons) {
      //   console.log("ON1");
      //   setError(error.reasons[0].validation)
      //   if (error.reasons[0].value === false && error.reasons[0].path === 'user.approved') {
      //     console.log("ON2");
      //     openModal('extraBankData')
      //   } else if (error.reasons[0].value === 'PENDING' && error.reasons[0].path === 'purchase.status') {
      //     console.log("ON3");
      //     openModal('errorBuy')
      //     console.error('error', error.reasons[0].message)
      //   } else {
      //     console.log("ON4");
      //     closeModal();
      //     console.error(error.reasons[0].message)
      //   }
      // }else{
      //   openModal('extraBankData')
      // }
    }
  }
  return (
    <>
      {loading && <><Loader /></>}
      <CreditCardStyle>
        <div className="main-form-wrapper">
          <div className="main-layout-logo">
            <LogoIcon scale="1.3" fill="" />
          </div>
          <div className="credit-card-form">
            <FormGridRow pattern="1fr">
              <Controller
                as={
                  <SelectBox
                    name={SELECT_CARDS_INPUT_NAME}
                    labelText="Nº tarjeta"
                    optionsArray={creditCards.map(item => item.cardName)}
                    errorCode={getCardFormErrors(errors, SELECT_CARDS_INPUT_NAME)}
                    rightLabel={{
                      text: "Añadir nueva +",
                      onClick: () => {
                        amplitude.getInstance().logEvent('add payment card', { totalCartValue: purchasesData?.totalAmount, cartSize: purchasesData?.products?.length, totalDiscounts: purchasesData?.extraDetails?.discountCode?.giftAmount, email: me?.email, merchantName: purchasesData?.commerce.name });
                        SendApiConversion('Add Payment Card',[me?.email],[me?.phoneNumber],purchasesData?.commerce?.name);
                        openModal('addCard')
                      }
                    }}
                  />
                }
                onChange={value => {
                  if (value[0].includes('Tarjeta')) amplitude.getInstance().logEvent('selects payment card', { payment_method: value[0], totalCartValue: purchasesData?.subtotal, cartSize: purchasesData?.products?.length, totalDiscounts: purchasesData?.extraDetails?.discountCode?.giftAmount, email: me?.email, merchantName: purchasesData?.commerce?.name });
                  return value[0]
                }}
                name={SELECT_CARDS_INPUT_NAME}
                control={control}
                rules={getCardInputMetadata(SELECT_CARDS_INPUT_NAME)}
              />
            </FormGridRow>
            <FormGridRow pattern="1fr">
              <InputBox
                disabled
                placeholder={address}
                labelText="dirección de tarjeta"
                name={ADRESS_CARD_INPUT_NAME}
                inputRef={register(getCardInputMetadata(ADRESS_CARD_INPUT_NAME))}
                errorCode={getCardFormErrors(errors, ADRESS_CARD_INPUT_NAME)}
              />
            </FormGridRow>
          </div>
          <div className="credit-card-button">
            <MainButton type="primary" text="Realizar pago" onClick={handleSubmit(handlePurchase)} />
          </div>
          <div className="credit-card-back" onClick={() => { dispatch(authActions.logout()); }}>
            <p>Cancelar y volver a mi carrito</p>
          </div>
          <div className="credit-card-back" onClick={() => { 
            try {
              let database = firebase.database();
              let purchasesInfo = database.ref('compras/' + purchasesData.id);
              const updates = {};
              updates['/payment'] = 0;
              purchasesInfo.update(updates);
              purchasesInfo.set({
                goback: true
              })
              //purchasesInfo.push("prueba" + payment);
             } catch (error) {
               console.log(error)
             }
            history.push(`/select-payment/${token}?goback=true`) }}>
            <p>Volver a elegir método de pago</p>
          </div>
        </div>
      </CreditCardStyle>
      {/* MODALS */}
      <ModalAdress
        modalConfig={{
          onClose: closeModal,
          active: (isModal?.show && isModal?.idModal === 'addCard') || false,
        }}
        onClick={getData}
        creditCardsDigits= {creditCardDigits}
      />


      <ModalBankData
        modalConfig={{
          onClose: closeModal,
          active: (isModal?.show && isModal?.idModal === 'extraBankData') || false,
        }}
        idCard={idCard!}
        show={errorsData.title === ''}
      />

      <ModalBuyResume
        modalConfig={{
          onClose: closeModal,
          active: (isModal?.show && isModal?.idModal === 'confirmBuy') || false,
        }}
        onClick={confirmBuy}
        onCancel={closeModal}
      />

      <ModalSuccess
        modalConfig={{
          // onClose: closeWindow,
          active: (isModal?.show && isModal?.idModal === 'successBuy') || false,
        }}
        purchase={purchasesData}
      />

      <ModalModifyUserData
        modalConfig={{
          onClose: closeModal,
          active: (isModal?.show && isModal?.idModal === 'updateUserData') || false,
        }}
        onClick={() => { openModal('extraBankData') }}
        times={times}
        idCard={idCard!}
      />

      <ModalToSelectPaymentMethod
        modalConfig={{
          active: (isModal?.show && isModal?.idModal === 'selectPaymentMethod') || false,
          onClose: () => {
            closeModal()
            setFinalMessage(false)
          }
        }}
        setWalletScreen={setWalletScreen}
        walletScreen={walletScreen}
        setFinalMessage={setFinalMessage}
        finalMessage={finalMessage}
        onClick={() => {
          closeModal()
        }}
        purchase={purchasesData}
      />
      {/* 
        {/* 
       <ModalErrorCard
          modalConfig={{
            onClose: closeModal,
            active: (isModal?.show && isModal?.idModal === 'scoringTry') || false,
          }}
          title={'Has superado el número de intentos para revisar tu información errores'}
        />
     
        <ModalErrorCard
          modalConfig={{
            onClose: closeModal,
            active: (isModal?.show && isModal?.idModal === 'errorBank') || false,
          }}
          title={'error bank'}
        /> */}
      {/* END OF MODALS */}
    </>
  );
};

export default CreditCard;
