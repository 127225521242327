import styled from 'styled-components';


export const CreditCardStyle = styled.div`
  padding: 100px 40px;
  width: 100%;

  .main-form-wrapper {
    margin: 0 auto;
    max-width: 438px;
  }
  .credit-card-title {
    font-size: 18px;
    line-height: 22px;
    font-weight: 600;
    span {
    }
  }
  .credit-card-button {
    margin-bottom: 42px;
  }

  .credit-card-back {
    font-size: 14px;
    line-height: 24px;
    font-weight: 300;
    text-decoration: underline;
    color: #1A4CCD;
    margin-bottom: 32px;
    cursor: pointer;
  }

@media (max-width: 1024px) {
  padding: 80px 20px;
}  
`