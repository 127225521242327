import React from 'react'
import { ILoaderBankProps } from './loaderBank-type'
import americanexpress from "../../assets/images/banks/americanexpress.svg";
import banorte from "../../assets/images/banks/banorte.svg";
import citinanamex from "../../assets/images/banks/citibanamex.svg";
import santander from "../../assets/images/banks/santander.svg";
import azteca from "../../assets/images/banks/bancoazteca.svg";
import banregio from "../../assets/images/banks/banregio.svg";
import invex from "../../assets/images/banks/invex.svg";
import scotia from "../../assets/images/banks/scotiabank.svg";
import bancoppel from "../../assets/images/banks/bancoppel.svg";
import bbva from "../../assets/images/banks/bbva.svg";
import liverpool from "../../assets/images/banks/liverpool.svg";
import heybanco from "../../assets/images/banks/heybanco.svg";
import LoaderBankContainer from './loaderBankStyle';


const banksImgs = {
  americanexpress: americanexpress,
  banorte: banorte,
  citibanamex: citinanamex,
  santander: santander,
  bancoazteca: azteca,
  banregio: banregio,
  invex: invex,
  scotiabank: scotia,
  bancoppel: bancoppel,
  bbvabancomer: bbva,
  liverpool: liverpool,
  heybanco: heybanco
}

const banksImgsSize = {
  americanexpress: { height: '90px', width: '150px' },
  banorte: { height: '90px', width: '150px' },
  santander: { height: '90px', width: '150px' },
  citibanamex: { height: '90px', width: '150px' },
  bancoazteca: { height: '90px', width: '150px' },
  banregio: { height: '90px', width: '150px' },
  invex: { height: '90px', width: '150px' },
  scotiabank: { height: '90px', width: '150px' },
  bancoppel: { height: '90px', width: '150px' },
  bbvabancomer: { height: '90px', width: '150px' },
  liverpool: { height: '90px', width: '150px' },
  heybanco: { height: '90px', width: '150px' },
}


const LoaderBank = (props: any) => {
  const { currentBank } = props
  let imgName = currentBank && currentBank.toLowerCase().replace(" ", "") 
  return (
   <LoaderBankContainer>
     
    <div className="containerConected">
    <div className="lds-ring">
      <div></div>
      <div></div>
      <div></div>
    </div>
      <p className="descriptionTitle">Estableciendo conexión segura</p>
      <div className="bank-logo">
                {banksImgs[imgName] ? <img src={banksImgs[imgName] ? banksImgs[imgName] : invex} style={{ cursor: 'pointer', ...banksImgsSize[imgName], margin: 'auto' }} alt={currentBank} /> : (<p>{currentBank}</p>)}
      </div>
    </div>
   </LoaderBankContainer>
  )
}

export default LoaderBank
