import { IPurchase } from "@aluxion-lightpay/interfaces";
import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import { openpayLogo } from "../../../assets/images/images";
import { paymentSelector } from "../../../store/payment/selectors";
import MainButton from "../../main-button/main-button";
import ModalContainer from "../modal-container/modal-container";
import { ModalBuyResumeStyle } from "./modal-buy-resume-style";
import { IModalBuyResumeProps } from './modal-buy-resume-types';
import LogoIcon from "../../../assets/icons/Logo/logo-icon";
import { formatMoney } from "../../../utils/utils";
import moment from 'moment';
import Checkbox from "../../checkbox/checkbox";
import { useForm } from "react-hook-form";
import { getModalBuyErrors } from "../../../utils/login-input-validations";
import amplitude from 'amplitude-js'
import { authSelectors } from "../../../store/auth/selectors";
import SendApiConversion from "../../../constants/apiConversionFb";

const ModalBuyResume: React.FC<IModalBuyResumeProps> = (props: IModalBuyResumeProps) => {
  const type = useSelector(paymentSelector.type())
  const { modalConfig, onClick, onCancel } = props;
  const purchasesData: any = useSelector(paymentSelector.purchasesData());
  const isOneShot = useSelector(paymentSelector.isOneShot())
  const { handleSubmit, register, errors } = useForm();
  const [discount, setDiscount] = useState<number>(0);
  const [installments, setInstallments] = useState<any>([]);
  const [totalAmount, setTotalAmount] = useState<number>(0);
  const { id, email, phoneNumber }: any = useSelector(authSelectors.getMe());

  useEffect(() => {
    if (modalConfig.active) {
      amplitude.getInstance().logEvent('show order review', purchasesData);
    }
  }, [modalConfig.active])

  useEffect(() => {
    purchasesData?.extraDetails?.paymentDiscountCode?.amount && setDiscount(purchasesData?.extraDetails?.paymentDiscountCode?.amount)
    //setDiscount(purchasesData?.extraDetails?.paymentDiscountCode?.amount)
  }, [])

  useEffect(() => {
    const paymentPartitions = purchasesData?.payments?.filter((payment) => {
      if (payment.type === type) {
        return payment
      }
    })

    if(type === '4 Cuotas') {
      setTotalAmount(purchasesData?.totalAmount)
    } else if(type === '6 Cuotas') {
      setTotalAmount(purchasesData?.totalAmount6)
    } else if(type === '8 Cuotas') {
      setTotalAmount(purchasesData?.totalAmount8)
    }

    setInstallments(paymentPartitions)
  }, [type])

  return (
    <ModalContainer
      type="light"
      {...modalConfig}
    >
      <ModalBuyResumeStyle>
        <div className="modal-buy-card">
          <div className="modal-buy-card-top">
            <div className="modal-buy-card-top__logo">
              {/* Cambiar por logo de la marca */}
              {/* <img src={logo} alt="" /> */}
              <LogoIcon scale="1.2" fill="" />
            </div>
            <div className="modal-buy-card-top__order">
              <div className="modal-buy-card-top__order__label">
                <p>Nº orden</p>
              </div>
              <div className="modal-buy-card-top__order__number">
                <p>{purchasesData?.id}</p>
              </div>
            </div>
          </div>
          <div className="modal-buy-card-resume">
            <div className="modal-buy-card-resume-products">
              <div className="modal-buy-card-resume-products__subtitle">
                <p>{purchasesData?.commerce.name}</p>
              </div>
              <div className="modal-buy-card-resume-products__title">
                <p>Resumen de pago <span>con Slightpay</span></p>
              </div>
              <div className="modal-buy-card-resume-products">
                {purchasesData?.products.map((item, index) => {
                  return (
                    <div className="modal-buy-card-resume-products__amount" key={index}>
                      <div className="modal-buy-card-resume-products__amount_product">
                        <p>{item.name}</p>
                        <span>{`${formatMoney(item.amount)} 
                              x ${item.quantity}`}</span>
                      </div>
                      <p>{formatMoney(item.amount * item.quantity)}</p>
                    </div>
                  )
                })}
              </div>
            </div>
            <div className="modal-buy-card-resume-row">
              <div className="modal-buy-card-resume-row__title">
                <p>Tu compra</p>
              </div>
              < div className="modal-buy-card-resume-row__submain-amount">
                {
                  isOneShot ?
                    <p>{formatMoney(purchasesData.totalOneShot - purchasesData.shippingFee - purchasesData.tax)}</p>
                    :
                    <p>{formatMoney(purchasesData?.subtotal + purchasesData?.discountCommerce - purchasesData?.shippingFee + discount)}</p>
                /* {
                  // purchasesData.extraDetails.discountCode ?
                  //(purchases?.extraDetails.paymentDiscountCode?.amount)
                  //   purchasesData.extraDetails.discountCode.isShippingFee ?
                      <p>{purchasesData.extraDetails.paymentDiscountCode ? formatMoney(purchasesData?.subtotal) : formatMoney(purchasesData?.subtotal - purchasesData?.shippingFee)}</p>
                      :
                      <p>{formatMoney(purchasesData?.subtotal - purchasesData?.shippingFee + purchasesData.extraDetails.discountCode.giftAmount)}</p>
                    :
                    <p>{purchasesData.extraDetails.paymentDiscountCode ? formatMoney(purchasesData?.subtotal) : formatMoney(purchasesData?.subtotal - purchasesData?.shippingFee)}</p>
                } */}
              </div>
            </div>
            <div className="modal-buy-card-resume-row">
              <div className="modal-buy-card-resume__subtitle">
                <p>Gastos de envio</p>
              </div>
              <div className="modal-buy-card-resume-row__amount">
                <p>{purchasesData.extraDetails.paymentDiscountCode && purchasesData.extraDetails.discountCode?.isShippingFee ? formatMoney(0) : formatMoney(purchasesData?.shippingFee)}</p>
              </div>
            </div>
            {
              purchasesData.extraDetails.discountCode && !isOneShot ?
                purchasesData.extraDetails.discountCode.isShippingFee ?
                  null :
                  <div className="modal-buy-card-resume-row">
                    <div className="modal-buy-card-resume-row__subtitle">
                      <p>Codigo de descuento: {purchasesData.extraDetails.discountCode.code}</p>
                    </div>
                    <div className="modal-buy-card-resume-row__amount">
                      <p>-{formatMoney(purchasesData?.extraDetails?.paymentDiscountCode?.amount)}</p>
                    </div>
                  </div> :
                null
            }
            {!isOneShot &&
              <div className="modal-buy-card-resume-row">
                <div className="modal-buy-card-resume-row__subtitle">
                  <p>Comisión por gestión de cobro</p>
                </div>
                <div className="modal-buy-card-resume-row__amount">
                  <p>{formatMoney(purchasesData?.userFeeIva + purchasesData?.userFee)}</p>
                </div>
              </div>
            }
            {
              purchasesData?.discountCommerce &&
              <div className="modal-buy-card-resume-row">
                <div className="modal-buy-card-resume-row__subtitle">
                  <p>Descuento del comercio</p>
                </div>
                <div className="modal-buy-card-resume-row__amount">
                  <p>{formatMoney(purchasesData?.discountCommerce)}</p>
                </div>
              </div>
            }
            <div className="modal-buy-card-resume-row">
              <div className="modal-buy-card-resume-row__main-title">
                <p>Total a pagar</p>
              </div>
              <div className="modal-buy-card-resume-row__main-amount">
                {isOneShot ?
                  <p>{formatMoney(purchasesData?.totalOneShot)}</p>
                  :
                  <p>{formatMoney(totalAmount)}</p>
                }
              </div>
            </div>
            <div className="modal-buy-card-resume-total">
              <div className="modal-buy-card-resume-total__title">
                {!isOneShot && <p>A pagar ahora</p>}
              </div>
              <div className="modal-buy-card-resume-total__amount">
                {!isOneShot &&
                  <>
                    {installments?.length !== 0 && <p className="title">{formatMoney(installments[0].amount)}</p>}
                    <div className="modal-buy-card-resume-total__amount-items">
                      {
                        installments?.length !== 0 && installments?.map((installment, key) => {
                          if(key !== 0) {
                            return (
                              <p>
                                <span className="date">{moment(installment.dueDate).format('L')}</span>
                                <span className="item">Cuota {key + 1}: </span>
                                {formatMoney(installment.amount)}
                              </p>
                            )
                          }
                        })
                      }
                    </div>
                  </>
                }
              </div>
            </div>
            <Checkbox
              inputRef={register({
                required: { value: true, message: 'required' },
              })}
              name='checkTerms'
              errorCode={getModalBuyErrors(
                errors,
                'checkTerms'
              )}
              labelTextOptions={[
                {
                  text: 'He leído y acepto las condiciones de uso de compraventa',
                  url: 'https://slightpay.com/terminos-y-condiciones-compraventa',
                },
              ]}
            />
            {!isOneShot &&
              <div className="modal-buy-card-resume_message">
                <p>
                  *Se programarán los pagos de las cuotas restantes en tu misma tarjeta para las fechas
                  mostradas en tu plan de pagos, si quisieras cambiar el método de pago podrás realizarlo
                  desde tu cuenta.
                </p>
              </div>}
          </div>
          <div className="logo-openpay">
            <img src={openpayLogo} alt="openpay-logo" />
          </div>
        </div>
        <div className="modal-buy-card-buttons">
          <div className="modal-buy-card-buttons__item">
            <MainButton
              text="Cancelar"
              type="ghost"
              onClick={onCancel}
            />
          </div>
          <div className="modal-buy-card-buttons__item">
            <MainButton
              text="Si"
              type="primary"
              onClick={handleSubmit(onClick)}
            />
          </div>
        </div>
      </ModalBuyResumeStyle>
    </ModalContainer >
  );
};

export default ModalBuyResume;
