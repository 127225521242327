import React, { useEffect, useState } from "react";
import MainButton from "../../main-button/main-button";
import PasswordBox from "../../password-box/password-box";
import ModalContainer from "../modal-container/modal-container";
import { ModalModifyPhoneStyle } from "./modal-modify-phone-style";
import { IModalCodeProps } from './modal-modify-phone-types'
import { Controller, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { PHONE_NUMBER_INPUT_NAME, getRegisterInputMetadata } from "../../../utils/login-input-names";
import { getRegisterFormErrors } from "../../../utils/login-input-validations";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../../../store/auth/actions";
import { modalActions } from "../../../store/modal/actions";
import amplitude from 'amplitude-js'
import { authSelectors } from "../../../store/auth/selectors";
import InputBox from "../../input-box/input-box";

const ModalModifyPhone: React.FC<IModalCodeProps> = ({ modalConfig, onClick }) => {
  const { register, handleSubmit, errors, control } = useForm();
  const dispatch = useDispatch();
  const history = useHistory();
  const me = useSelector(authSelectors.getMe());
  const phoneNumber: any = useSelector(authSelectors.getUserPhone());
  //const [phoneNumber, setPhoneNumber] = useState<string>("");

  const closeModal = async () => {
    await dispatch(modalActions.hide());
  };
  const openModal = async (id: number | string) => {
    await dispatch(modalActions.show(id));
  };
  
  const handleData = async (data:any) => {
    try {
      await dispatch(authActions.addPhoneNumber(data[PHONE_NUMBER_INPUT_NAME].replace(/\s/g, '')));
      await dispatch(authActions.updatePhoneNumber(data[PHONE_NUMBER_INPUT_NAME]));
      closeModal();
      openModal("SmsCode")
    }catch (error) {
      // here goes modal
      amplitude.getInstance().logEvent('error encountered', {'error_type': 'verify phone', 'error_reason': JSON.stringify(error)});
    }
  }

  return (
    <ModalContainer {...modalConfig}>
      <ModalModifyPhoneStyle>
        <div className="modal-code-title">
          <span>¡Bienvenido a SlightPay!</span>
          <p>
            Ingresa correctamente tu número de teléfono, recibirás un SMS para confirmarlo.
          </p>
        </div>
        <div className="modal-code-input">
        <Controller
          as={
            <InputBox
              labelText="Nº de Télefono"
              name={PHONE_NUMBER_INPUT_NAME}
              errorCode={getRegisterFormErrors(errors, PHONE_NUMBER_INPUT_NAME)}
              cleaveFormat={{
                phone: true,
                // phoneRegionCode: 'MX',
              }}
              placeholder="+52"
              initialValue={phoneNumber}
            />
          }
          name={PHONE_NUMBER_INPUT_NAME}
          control={control}
          rules={getRegisterInputMetadata(PHONE_NUMBER_INPUT_NAME)}
        />
        </div>
        <div className="modal-code-button">
          <MainButton
            text="Verificar"
            type="primary"
            onClick={handleSubmit(handleData)}
          />
        </div>
        <div
            className="modal-code-cancel"
            onClick={() => {
              closeModal(); openModal("SmsCode")
            }}
          >
            <p>Cancelar</p>
          </div>
      </ModalModifyPhoneStyle>
    </ModalContainer>
  );
};
export default ModalModifyPhone;
