import { format } from 'date-fns';
import moment from 'moment';

export const parseStringToDate = (value: string, dateDelimiter: string): Date => {
  let dateValue = new Date();

  if (value.indexOf(dateDelimiter) !== -1) {
    const dateZones = value.split(dateDelimiter).length;
    const NUMBER_DATE_ZONES = 3;
    const YEAR_LENGTH = 4;
    if (dateZones === NUMBER_DATE_ZONES && value.split(dateDelimiter)[2].length === YEAR_LENGTH) {
      const year = Number(value.split(dateDelimiter)[2]);
      const month: number = Number(value.split(dateDelimiter)[1]) - 1;
      const day = Number(value.split(dateDelimiter)[0]);

      dateValue = new Date(year, month, day);
    }
  } else {
    throw new Error('The value dont has the delimiter');
  }
  return dateValue;
};

export const parseDateToString = (value: Date, formats: string): string => {
  return format(value, formats);
};

export const copyOf = (value: any) => {
  return JSON.parse(JSON.stringify(value));
};

export const capitalizeFirstLetter = (text: string) => {
  if (typeof text !== 'string') return '';
  return text.charAt(0).toUpperCase() + text.slice(1);
};
