import React from "react";
import ReactGA from "react-ga";

const GAEventTracker = (category = "Category",action = "action", label = "label") => {
        ReactGA.event({
            category,
            action,
            label,
        });
};

export default GAEventTracker;