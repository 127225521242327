import produce from 'immer';

import { CardActions, ICardState, ICardReducer } from './types';

export const initialState: ICardState = {
  cards: {
    items: [],
    count:0
  },
};

const cardReducer: ICardReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case CardActions.ADD_CARD_DATA:
        draft.cards = action.payload;
        break;
      default:
    }
  });

export default cardReducer;