import React from 'react';
import { ModalInfoStyle } from './modal-info-style';
import ModalContainer from '../modal-container/modal-container';
import MainButton from '../../main-button/main-button';
import { IModalInfo } from './modal-info-types';
import PasswordBox from '../../password-box/password-box';
import { useForm } from 'react-hook-form';
import { CONFIRM_PASSWORD_INPUT_NAME, getRegisterInputMetadata } from '../../../utils/login-input-names';
import { getRegisterFormErrors } from '../../../utils/login-input-validations';


const ModalInfo: React.FC<IModalInfo> = ({ title, text, text2, onClick, modalConfig, password }) => {
  const { register, errors, handleSubmit } = useForm();
  
  const validatePassword = (value: string) => {
    return value === password;
  };

  const handleForm = async (data:any) => {
    onClick();
  }

  return (
    <ModalContainer {...modalConfig}>
      <ModalInfoStyle>
        <div className="modal-info-title">
          <p>{title}</p>
        </div>
        <div className="modal-info-text">
          <p>{text}</p>
        </div>
        <div className="modal-info-text">
          <p>{text2}</p>
        </div>
        <div className="confirm-input">
          <PasswordBox
            labelText="repetir contraseña"
            name={CONFIRM_PASSWORD_INPUT_NAME}
            inputRef={register(
              getRegisterInputMetadata(CONFIRM_PASSWORD_INPUT_NAME, {
                passwordMissmatch: validatePassword
              })
            )}
            errorCode={getRegisterFormErrors(
              errors,
              CONFIRM_PASSWORD_INPUT_NAME
            )}
            onKeyPress={handleSubmit(handleForm)}
          />
        </div>
        <div className="modal-info-buttons">
            <div className="modal-info-buttons__item" key={`modal-info-button-1`}>
              <MainButton  
                text= 'Rechazar'
                type= 'ghost'
                onClick={modalConfig.onClose}
              />
            </div>

            <div className="modal-info-buttons__item" key={`modal-info-button-2`}>
              <MainButton  
                text= 'Aceptar'
                type= 'primary'
                onClick={handleSubmit(handleForm)}
              />
            </div>
        </div>
      </ModalInfoStyle>
    </ModalContainer>
  );
};

export default ModalInfo;
