export const currentServer = 'master' //process.env.REACT_APP_ENV;

export const PUBLIC_KEY='pk_ac05d9443e914ee1b6d88f781fe2290e';
export const OPENPAY_MERCHANTE_ID='mabarczrcfhon9l8b1dz';
export const OPENPAY_SANDBOX=false;
export const googleAnalyticsKey = 'UA-175440665-1';
export const facebookPixelKey = '340936073703925';

interface IServices {
  [key: string]: any;
}

const SERVICES: IServices = {
  master: {
    url: 'https://api.slightpay.com/',
  },
  // staging: {
  //   url: 'https://api-staging.slightpay.com/',
  // },
  // development: {
  // //   // url: 'https://api-develop.slightpay.com/',
  //   url: 'http://localhost:3000/',
  //  },
};

export const SERVICE_URL = (SERVICES[currentServer as string] && SERVICES[currentServer as string].url) || SERVICES.development.url;
