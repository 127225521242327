import styled from "styled-components";

export const LoginStyle = styled.div`
  padding: 100px 40px;
  width: 100%;
  .login-container {
    margin: 0 auto;
    max-width: 325px;
    flex: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .login-form {
    margin-bottom: 18px;
    .form-row {
      margin-bottom: 12px;
    }
  }
  .login-submit  {
    margin-bottom: 30px;
  }
  .login-recovery-pass {
    text-align: center;
    margin-bottom: 42px;
    font-size: 14px;
    line-height: 16px;
    font-weight: 300;
    text-decoration-line: underline;
    color: #1a4ccd;
    cursor: pointer;
  }

  .login-split {
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    color: #808185;
    &__line {
      flex: auto;
      height: 1px;
      background: #808185;
    }
    &__text {
      margin: 8px;
    }
  }

  @media (max-width: 768px) {
    padding: 40px 20px;
  }

  .qr-code {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0px 20px 0px;
  }
  .steps {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .step-number {
    font-size: 50px;
    color: gray;
  }
  .step-text {
    margin-left: 12px;
  }
`;
