import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { store } from '../index';
import { toastActions } from './toasts/actions';
import { handleReasonsError } from '../utils/errorHandler';
import { SERVICE_URL } from '../constants/env';
import { authActions } from './auth/actions';
import {ILoadingActions} from '../store/loader/types'
import { formatMoney } from '../utils/utils';
import {errorsActions} from '../store/errors/actions'
import { IPaymentActions } from './payment/types';

export interface IFormatError {
  message: string;
  statusCode?: number;
  code?: string;
  reasons?: string[];
}

class Api {
  //private dispatch = useDispatch();
  private req: AxiosInstance;
  private resquests = {};
  constructor() {
    this.req = axios.create({ baseURL: SERVICE_URL, timeout: 300000 });
    this.req.interceptors.request.use((config)=> {
      // console.log({config})
      this.resquests[String(config.url)] = true;
      // store.dispatch({ type: ILoadingActions[this.verifyLoading()]});      
      return config;
    }, (error) =>{
      return Promise.reject(error);
    });
    this.req.interceptors.response.use(res => {
      // console.log({res})
      if(res.config.url === '/purchases/details'){
        let purchasesData = res.data
        const handleIsNaN = (val) => formatMoney(val) === '$NaN'
        if(handleIsNaN(purchasesData.payments[3].amount) ||handleIsNaN(purchasesData.payments[2].amount) ||handleIsNaN(purchasesData.payments[1].amount) ||handleIsNaN(purchasesData.payments[0].amount) || handleIsNaN(purchasesData.tax)) 
        this.modalError('Hay un error con la cantidad del producto verificalo con tu comercio', 'Vuelve a intentar, si el problema persiste contactate con el comercio en el que realizas la compra');
      } 
      this.resquests[String(res.config.url)] = false;
      store.dispatch({ type: ILoadingActions[this.verifyLoading()]});
      return res;
    }, async error =>{
      console.log({error}, 'error.response.data.code', error.response.data.code)
      this.resquests[String(error.config.url)] = false;
      store.dispatch({ type: ILoadingActions[this.verifyLoading()]});
      
      if(error.message === "Network Error") {
        this.modalError('Estamos teniendo dificultades tecnicas, por favor intente mas tarde', 'Por favor, si el problema persiste pongase en contacto con un administrador de Slightpay.');
      } else if(error.response.status > 399) {
        if(error.config.url === '/auth/login?relations=address'){
          this.modalError(error.response.data.message, 'Vuelve a intentar.');
        } else if(error.config.url === '/purchases/details' && error.config.headers.Authorization === "bearer null"){
          this.modalError('Espera, no estas realizando una compra', 'Busca algun comercio que te permita comprar con Slightpay');
        } else if(error.response.data.reasons) {
          if(error.response.data.reasons[0].path === 'confirmPurchase 3D Secure') {
            console.log('entre aqui')
            this.modalError(error.response.data.reasons[0].message, 'Por favor, si el problema persiste pongase en contacto con un administrador de Slightpay.');
          } else {
            if(error.config.url.includes('/charge-codi-dapp')) this.modalError(error.response.data.reasons[0].message, 'Por favor, si el problema persiste pongase en contacto con un administrador de Slightpay.');
            if(error.config.url.includes('/purchases/') && error.config.url.includes('/confirm-purchase') && error.response.data.reasons[0].message !== 'Debe proporcionar información personal correcta para comprobar su score, por favor revise sus datos' && error.response.data.reasons[0].message !== 'Has superado el número de intentos para revisar tu información'){
              this.modalError(error.response.data.reasons[0].message, 'Por favor, si el problema persiste pongase en contacto con un administrador de Slightpay.');
            } else if(error.response.data.reasons[0].message === 'Has superado el número de intentos para revisar tu información') {
              this.modalError(error.response.data.reasons[0].message, 'Comunicate con Slightpay para revisar tu situación via correo admin@slightpay.com');
            } else if(error.response.data.reasons[0].message === 'Debe proporcionar información personal correcta para comprobar su score, por favor revise sus datos') {
              this.modalError(error.response.data.reasons[0].message, 'Por favor, si al cambiar datos el problema persiste pongase en contacto con un administrador de Slightpay.');
            }
          }
        } else if(error.config.headers.Authorization === "bearer null" || error.response.data.code === "UNAUTHORIZED"){
          this.modalError('Espera, no sabemos tu identidad', 'Inicia sesión');
        } else if(error.response.data.code === 'OPENPAY_EXCEPTION'){          
          this.modalError(error.response.data.message === 'La tarjeta fue rechazada' ? `${error.response.data.message}. En ocasiones los bancos bloquean las transacciones por protección de sus usuarios, en este caso es necesario que te comuniques con tu banco y autorices tu compra para poder finalizar con tu solicitud` : error.response.data.message, error.response.data.message === 'La tarjeta fue rechazada' ? 'Por favor, pongase en contacto con su banco.' : '');
        } else if(error.response.data.code === 'VALIDATION_ERROR'){
          let {reasons} = error.response.data;
          if(reasons.length > 0){
            if(reasons.find(item => item.message.includes("Ya tienes una compra activa con Slightpay"))){
              this.modalError('Ya tienes una compra activa con Slightpay', 'Por favor, si el problema persiste pongase en contacto con un administrador de Slightpay.');  
            }
          } else this.modalError('Estamos teniendo dificultades tecnicas, por favor intente mas tarde', 'Por favor, si el problema persiste pongase en contacto con un administrador de Slightpay.');  
        } else if(error.response.data.code === 'PHONE_NUMBER_NOT_VERIFIED') {
          this.modalError(error.response.data.message, 'Por favor, si el problema persiste pongase en contacto con un administrador de Slightpay.');  
        } else if(error.response.data.error[0].message === 'Error de comercio'){
          store.dispatch({ type: IPaymentActions.COMMERCE_BLOCK, payload: true })
          this.modalError('Estamos teniendo dificultades tecnicas, por favor intente mas tarde', 'Por favor, si el problema persiste pongase en contacto con un administrador de Slightpay.');
        } else this.modalError('Estamos teniendo dificultades tecnicas, por favor intente mas tarde', 'Por favor, si el problema persiste pongase en contacto con un administrador de Slightpay.');  
      }
      return error;  
    }) 
  }

  private modalError (title, subtitle){
    store.dispatch<any>(errorsActions.setErrors({title, subtitle}));
  }

  private verifyLoading(){
    let isLoading =  false;
    for (const key in this.resquests) {
      isLoading =  isLoading || this.resquests[key]
    }
    return isLoading ? 'LOADING_GLOBAL':'LOADED_GLOBAL'
  }

  public get<T>(url: string, params: AxiosRequestConfig = {}): Promise<T> {
    return this.req.get(url, params);
  }

  public post<T>(url: string, body: any, params: AxiosRequestConfig = {}): Promise<T> {
    return this.req.post(url, body, params);
  }

  public put<T>(url: string, body: any, params: AxiosRequestConfig = {}): Promise<T> {
    return this.req.put(url, body, params);
  }

  public delete<T>(url: string, config: AxiosRequestConfig = {}): Promise<T> {
    return this.req.delete(url, config);
  }

  public setJWT(jwt: string) {
    this.req.defaults.headers.common.Authorization = `Bearer ${jwt}`;
  }

  public setCookie(cookie: any) {
    this.req.defaults.headers.common.Cookie = cookie;
  }

  public errorHandler(error: any, type?:'LOGIN' | 'FORGOT' | 'CHECK' | 'REGISTER'): IFormatError {
    if (error.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      type ? store.dispatch<any>(
        toastActions.error( authActions.handleCustomErrors(type , error.response.data.code)  || 'Ocurrió un error inesperado. Intente más tarde.'),
      ):
        handleReasonsError(error.response.data)
      return {
        message: error.response.data.message,
        statusCode: error.response.data.statusCode,
        code: error.response.data.code,
        reasons: error.response.data.reasons,
      };
    }else if(error.message === 'EMAIL_EXIST' || 'RFC_EXIST' || 'EMAIL_NOT_VERIFIED' || 'EMAIL_NO_VALID' || 'RFC_NO_VALID' ) {
      if(type){
        store.dispatch<any>(
          toastActions.error( authActions.handleCustomErrors(type , error.message)  || 'Ocurrió un error inesperado. Intente más tarde.'),
        )
      }

    }
    if (error.request) {
      /*
       * The request was made but no response was received, `error.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      return {
        message: error.request.statusText,
      };
    }
    // Something happened in setting up the request and triggered an Error
    return {
      message: error.message,
    };
  }
}

export const LightpayApi = new Api();
