import styled from 'styled-components';

export const ModalInfoStyle = styled.div`
  text-align: center;
  max-width: 700px;

  .modal-info-title {
    font-size: 18px;
    line-height: 22px;
    color: #34353d;
    margin-bottom: 32px;
    font-weight: 600;
  }
  .modal-info-text {
    font-size: 16px;
    line-height: 24px;
    color: #34353d;
    margin-bottom: 32px;
  }
  .modal-info-buttons {
    display: flex;
    justify-content: center;
    &__item {
      min-width: 140px;
      margin-right: 17px;
      &:last-child  {
        margin-right: 0px;
      }
    }
  }

  .confirm-input {
    margin-bottom: 20px;
  }
`;
