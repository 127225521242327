import styled from 'styled-components';

export const ModalSuccessStyle = styled.div`
  .modal-success-title {
    max-width: 600px;
    text-align: center;
    font-size: 24px;
    line-height: 36px; 
    margin-bottom: 40px;
    font-weight: 500;
    span {
      color: #1A4CCD;
      font-weight: 600;
      &.special {
        font-size: 26px;
      }

      &.black {
        color: black;
        font-size: 17px;
        line-height: 24px;
      }
    }
  }
  .modal-success-text {
    margin-bottom: 24px;
    font-size: 16px;
    line-height: 24px;
    color: #01030c;
    text-align: center;
    font-weight: 300;
    p {
      text-align: center;
    }
  }
  .modal-info-image {
    margin-bottom: 24px;
  }

`