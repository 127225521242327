import React, { useState, useEffect, useCallback } from "react";
import { WalletsStyle } from "./wallets-style";
import { authSelectors } from "../../../store/auth/selectors";
import { useSelector, useDispatch } from 'react-redux';
import { cardActions } from "../../../store/card/actions";
import { cardSelector } from "../../../store/card/selectors";
import { Controller, useForm } from "react-hook-form";
import { SELECT_CARDS_INPUT_NAME, getCardInputMetadata, ADRESS_CARD_INPUT_NAME } from "../../../utils/login-input-names";
import { paymentActions } from "../../../store/payment/actions";
import { useParams, useHistory, Link, useLocation } from "react-router-dom";
import { paymentSelector } from "../../../store/payment/selectors";
import { errorsActions } from '../../../store/errors/actions'
import { IPurchase } from "@aluxion-lightpay/interfaces";
import useWindowManager from "../../../utils/windows-manager";
import LogoIcon from "../../../assets/icons/Logo/logo-icon";
import useModal from "../../../hooks/useModal/use-modal";
import { ErrorsSelector } from "../../../store/errors/selectors";
import { authActions } from "../../../store/auth/actions";
import { loadSelector } from "../../../store/loader/selectors";
import { loaderActions } from "../../../store/loader/actions";
import Loader from "../../../components/loader/loader";
import { modalActions } from "../../../store/modal/actions";
import { InfoStyle } from "../../public/info-qr/info-qr-style";
import * as firebase from "firebase";
import LoaderStatic from "../../../components/StaticLoader/loader";
import MainButton from "../../../components/main-button/main-button";
import ModalSuccess from "../../../components/modals/modal-success/modal-success";
import ModalBankData from "../../../components/modals/modal-bank-data/modal-bank-data";
import ModalModifyUserData from "../../../components/modals/modal-modify-user-info/modal-modify-user-info";
import amplitude from 'amplitude-js'
import ModalCode from "../../../components/modals/modal-code/modal-code";
import ModalModifyPhone from "../../../components/modals/modal-modify-phone/modal-modify-phone";
import { amex, bbva, invex } from "../../../assets/images/images"
import { wallet987, wallet233, wallet268, wallet399, wallet433, wallet476, applePay, wallet467, wallet825, wallet616, wallet675 } from "../../../assets/images/images";
import { aurrera, benavides, eleven } from "../../../assets/images/images";
import SendApiConversion from "../../../constants/apiConversionFb";
import moment from "moment"
const Wallets: React.FC = () => {
  const openModal = async (id: number | string) => { await dispatch(modalActions.show(id)) };
  const closeModals = async () => { await dispatch(modalActions.hide()); };
  const [closeTimer, setCloseTimer] = useState(setTimeout(() => { }, 0));
  const dispatch = useDispatch();
  const { token } = useParams() as any;
  const history = useHistory();
  //const { id } = useSelector(authSelectors.getMe());
  const me = useSelector(authSelectors.getMe());

  // const  id  = 234567;//useSelector(authSelectors.getMe());
  const purchasesData: any = useSelector(paymentSelector.purchasesData());
  const isOneShot = useSelector(paymentSelector.isOneShot())
  const { isModal, closeModal } = useModal();
  const { items: creditCardData } = useSelector(cardSelector.getCardData());
  const { register, errors, handleSubmit, control, watch } = useForm();
  const cardSelected = watch(SELECT_CARDS_INPUT_NAME);
  const [address, setAdress] = useState<string>('Seleccione una tarjeta');
  const [idCard, setIdCard] = useState<number | null>(null);
  const [error, setError] = useState<string>('')
  const [cardScreen, setCardScreen] = useState<boolean>(false);
  const [walletScreen, setWalletScreen] = useState<boolean>(false)
  const [wallets, setWallets] = useState<any>([])
  const [walletQR, setWalletQR] = useState<number>(0);
  const [finalMessage, setFinalMessage] = useState<boolean>(false)
  const { closeWindow } = useWindowManager();
  const errorsData = useSelector(ErrorsSelector.catchErrors());
  const scoringStatus = useSelector(authSelectors.getScoringStatus());
  const loading = useSelector(loadSelector.isLoading());
  const [qrScreen, setQRScreen] = useState<boolean>(false);
  const [dappQr, setDappQr] = useState<any>("");
  const query = new URLSearchParams(useLocation().search);
  const [screenPayment, setScreenPayment] = useState<boolean>(false)
  const [isDesktop, setDesktop] = useState<boolean>(true);
  const [isDesktopSteps, setDesktopSteps] = useState<boolean>(false);
  const [times, setTimes] = useState<number>(0);
  const userToken = useSelector(authSelectors.getCurrentJWT());
  const type = useSelector(paymentSelector.type())
  const [showScoring, setShowScoring] = useState<boolean>(false);
  const [paymentPartition, setPaymentPartition] = useState<any>({});


  useEffect(() => {
    const paymentPartitions = purchasesData?.payments?.filter((payment) => {
      if (isOneShot) {
        if (payment?.paymentIndex === 0) {
          return payment
        }
      } else {
        if (payment?.paymentIndex === 1 && payment?.type === type) {
          return payment
        }
      }
    })

    setPaymentPartition(paymentPartitions[0])
  }, [type])

  useEffect(() => {
    console.log(scoringStatus, me?.approved, "el scoring")
    if (!me?.approved) {
      if (!scoringStatus) {
        dispatch(authActions.sendBuroDeCredito(purchasesData));
      } else {
        if (scoringStatus.status === 'SUCCESS') {
          closeModals()
        } else if (scoringStatus?.message.includes('Debe proporcionar cierta información para autorizar su préstamo')) {
          if (scoringStatus.message.includes('manual')) dispatch(paymentActions.createUserByFinerio())
          // amplitude.getInstance().logEvent('open bankings scoring starts', purchasesData);
          // dispatch(errorsActions.resetErrors())
          openModal('extraBankData')
        }
        else if (scoringStatus?.message.includes('Debe proporcionar información personal correcta para comprobar su score, por favor revise sus datos')) {
          scoringStatus.message.includes('Times: ') && setTimes(Number(scoringStatus.message.substr(scoringStatus.message.indexOf('Times: ') + 7)))
          openModal('updateUserData')
          // dispatch(errorsActions.resetErrors())
        }
      }
      // else if ((isModal?.idModal.length === 0 || isModal?.idModal === 'confirmBuy' ||  isModal?.idModal === 'selectPaymentMethod') && scoringStatus.title !== '') openModal('errorBuy');
    }
  }, [scoringStatus, me, showScoring, isOneShot])

  useEffect(() => {
    const getCodePhone = async () => {
      const codePhone: any = await dispatch(authActions.checkPhoneVerified)
      console.log(userToken)
      console.log("codePhone " + codePhone)
      if (!codePhone) {
        openModal('SmsCode');
      }
      return codePhone;
    }

    if (userToken) { const codePhone = getCodePhone(); }
  }, [userToken]);

  useEffect(() => {
    dispatch(paymentActions.getDappWallets(setWallets))
    dispatch(paymentActions.getPaymentData(token));
    //SendApiConversion('Client_CheckoutStart', [me?.email], [''], '', { "Total_cart": purchasesData?.totalAmount, "Promo_code": purchasesData?.extraDetails?.discountCode?.code, "order_id": purchasesData?.id })
  }, [])

  // Si hay errores, no cambiara el status por lo tanto no es necesario observarlo
  useEffect(() => {
    if (purchasesData) {
      dispatch(paymentActions.get3dSecureErrors(purchasesData?.id));
    }
  }, [])

  useEffect(() => {
    if (purchasesData?.status === 'PENDING' || purchasesData?.status === 'SUCCESSFUL' || purchasesData?.status === 'PREPENDING') openModal('successBuy');
  }, [purchasesData])

  useEffect(() => {
    if (!query.get("justlogin") && purchasesData?.id) {
      let database = firebase.database();
      //Logic to listening Firebase and then login
      let purchasesInfo = database.ref('compras/' + purchasesData.id);
      purchasesInfo.on('value', async function (snapshot) {
        let values = snapshot.val()

        if (values?.mobile === true && !sessionStorage.getItem("justlogin")) {
          setDesktop(false);
        }
      });
    }
  }, [])

  useEffect(() => {
    console.log(wallets)
  }, [wallets])

  const setPayment = async (payment: number) => {
    try {
      let database = firebase.database();
      let purchasesInfo = database.ref('compras/' + purchasesData?.id);
      const updates = {};
      updates['/payment'] = payment;
      purchasesInfo.update(updates);
      //purchasesInfo.push("prueba" + payment);
    } catch (error) {
      console.log(error)
    }

  }

  const createSpeiOrPaynetTransaction = async (types: string) => {
    const paymentPartitions = purchasesData?.payments?.filter((payment) => {
      if (isOneShot) {
        if (payment?.paymentIndex === 0) {
          return payment
        }
      } else {
        if (payment?.paymentIndex === 1 && payment?.type === type) {
          return payment
        }
      }
    })

    dispatch(paymentActions.createSpeiOrPaynetTransaction(purchasesData?.id, types, 0, paymentPartitions[0].id))
  }

  useEffect(() => {
    if (!query.get("justlogin") && purchasesData?.id) {
      let database = firebase.database();
      //Logic to listening Firebase and then login
      let purchasesInfo = database.ref('compras/' + purchasesData.id);
      purchasesInfo.on('value', async function (snapshot) {
        let values = snapshot.val()
        if (values?.payment === 2) {
          // Logic to login and set the properties...
          generateCodiTransaction(values?.walletQR);
          //console.log("Valor payment firebase "+values?.payment)
          setScreenPayment(true);
          //console.log("Screen Payment "+screenPayment);
        } else {
          if (values?.payment === 0) {
            setFinalMessage(false);
            setScreenPayment(false);
          } else if (values?.payment === 1) {
            setCardScreen(true);
            sessionStorage.getItem("justlogin") && history.push(`/credit-card/${token}`)
          }
        }

        if (values?.goback === true && !sessionStorage.getItem("justlogin")) {
          setCardScreen(false);
        }
      });
    }
  }, [purchasesData])

  const generateCodiTransaction = async (qrType: number) => {
    let paymentSelected
    purchasesData?.payments?.map((payment) => {
      if (isOneShot && payment?.paymentIndex === 0) {
        paymentSelected = payment
      } else if (payment?.type === type && payment?.paymentIndex === 1) {
        paymentSelected = payment
      }
    })

    try {
      let resultUrl: any = await dispatch(paymentActions.createCodiTransaction(purchasesData?.id, 0, qrType, paymentSelected.id))
      setFinalMessage(true)
      console.log(resultUrl)
      //setQRScreen(true);
      setDappQr(resultUrl?.data?.data?.qr_image);
      if (!isDesktop) {
        setPayment(2);
        setScreenPayment(!screenPayment);
      }
      //alert(screenPayment)
    } catch (e) {
      console.log(e)
    }
  }

  const logoutRemote = () => {
    let purchaseRef = firebase.database().ref('compras/' + purchasesData?.id);
    purchaseRef.remove()
      .then(function () {
        console.log("Remove succeeded.")
      })
      .catch(function (error) {
        console.log("Remove failed: " + error.message)
      });

    purchaseRef.set({
      logout: true
    })

    dispatch(authActions.logout());
  }
  return (
    <>
      {loading && <><Loader opacity={1} /></>}
      <WalletsStyle>
        <div className="main-form-wrapper">
          <div className="main-layout-logo">
            <LogoIcon scale="1.3" fill="" />
          </div>
          <div className="">
            {!walletScreen && sessionStorage.getItem("justlogin")
              ?
              <>
                <div className="">
                  <p>Elige un método de pago</p>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', height: '320px', justifyContent: 'center', alignItems: 'center ', background: "gray" }}>
                  <div className='payment-method-item' onClick={() => { setPayment(1); setCardScreen(true) }}>
                    <p style={{ margin: '25px 50px', textAlign: 'center' }}>PAGO CON TARJETA</p>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center ' }}>
                    <img src={bbva} style={{ width: "50px", height: "50px" }} />
                    <img src={amex} style={{ width: "50px", height: "50px" }} />
                    <img src={invex} style={{ width: "50px", height: "50px" }} />
                  </div>
                  <div className='payment-method-item' onClick={() => { setWalletScreen(true); setScreenPayment(true); setCardScreen(false) }}>
                    <p style={{ margin: '25px 50px', textAlign: 'center' }}>WALLET</p>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center ', marginBottom: "20px" }}>
                    <img src={wallet987} style={{ width: "55px", height: "55px" }} />
                    <img src={wallet476} style={{ width: "35px", height: "35px" }} />
                    <div style={{ marginTop: "10px" }}>
                      <img src={applePay} style={{ width: "60px", height: "30px" }} />
                      <p style={{ fontSize: "10px" }}>*Proximamente</p>
                    </div>
                  </div>
                </div>
              </>
              :
              <div className='moda-options'>
                <div className="modal-to-paid-content" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                  {
                    !sessionStorage.getItem('justlogin')
                      ?
                      screenPayment
                        ?
                        isDesktop
                          ?
                          <>
                            {
                              isDesktopSteps ?

                                <InfoStyle>
                                  <div className="info-container">
                                    <div className="">
                                      <p style={{ marginBottom: '20px' }}>Para finalizar el pago de tu compra en <b style={{ fontWeight: 800 }}>{purchasesData?.merchantName ? purchasesData?.merchantName : purchasesData?.commerce?.name}</b>, sigue los pasos mencionados a continuación.</p>
                                      <div className="steps">
                                        <p className="step-number">01</p>
                                        <p className="step-text">Abre la aplicación de tu Wallet y escanea el siguiente código</p>
                                      </div>
                                      <div className="qr-code">
                                        <img style={{ maxWidth: '45%' }} src={dappQr} alt="Codigo QR Dapp" />
                                      </div>
                                    </div>
                                    <div className="">
                                      <div className="steps">
                                        <p className="step-number">02</p>
                                        <p className="step-text">Toma una captura de pantalla del pago para aclaraciones.</p>
                                      </div>
                                    </div>
                                    <div className="">
                                      <div className="steps">
                                        <p className="step-number">03</p>
                                        <p className="step-text">Cierra sesión y entra a tu perfil en clientes.slightpay.com y verifica que se haya realizado tu pago</p>
                                      </div>
                                    </div>
                                  </div>
                                </InfoStyle>
                                :
                                <>
                                  <p style={{ textAlign: 'center', marginBottom: "20px" }}>Selecciona cualquiera de estas opciones para desplegar el código QR y proceder con tu pago.</p>
                                  <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', height: '340px', justifyContent: 'center' }}>
                                    {
                                      wallets.map((wallet: any, key: number) => {
                                        return (
                                          <div key={key} className='wallets-items' onClick={() => {
                                            //setWalletQR(wallet.qr) 
                                            generateCodiTransaction(wallet.qr)
                                            setDesktopSteps(true)
                                          }}>
                                           {                                        
                                            wallet.id == 476 ?
                                              <img src={wallet476} className="wallet-img" />
                                              : wallet.id == 233 ?
                                                <img src={wallet233} className="wallet-img" />
                                                : wallet.id == 268 ?
                                                  <img src={wallet268} className="wallet-img" />
                                                  : wallet.id == 399 ?
                                                    <img src={wallet399} className="wallet-img" />
                                                    : wallet.id == 433 ?
                                                      <img src={wallet433} className="wallet-img" />
                                                      : wallet.id == 987 ?
                                                        <img src={wallet987} className="wallet-img" />
                                                        : wallet.id == 467 ?
                                                          <img src={wallet467} className="wallet-img" />
                                                          : wallet.id == 825 ?
                                                          <img src={wallet825} className="wallet-img" />
                                                          : wallet.id == 675 ?
                                                          <img src={wallet675} className="wallet-img" />
                                                          : wallet.id == 616 &&
                                                          <img src={wallet616} className="wallet-img" />}

                                            <p className="wallets-items_text">{wallet.name}</p>
                                          </div>
                                        )
                                      })
                                    }
                                  </div>
                                </>
                            }

                          </>
                          :
                          <>
                            <InfoStyle>
                              <div className="info-container">
                                <div className="">
                                  <p style={{ marginBottom: '20px' }}>Para finalizar el pago de tu compra en <b style={{ fontWeight: 800 }}>{purchasesData?.merchantName ? purchasesData?.merchantName : purchasesData?.commerce?.name}</b>, sigue los pasos mencionados a continuación.</p>
                                  <div className="steps">
                                    <p className="step-number">05</p>
                                    <p className="step-text">El cliente deberá escanear el siguiente código QR en su celular. Sugiere que utilice la cámara o la aplicación Wallet</p>
                                  </div>
                                  <div className="qr-code">
                                    <img style={{ maxWidth: '45%' }} src={dappQr} alt="Codigo QR Dapp" />
                                  </div>
                                </div>
                                <div className="">
                                  <div className="steps">
                                    <p className="step-number">06</p>
                                    <p className="step-text">Pide al cliente que te muestre la pantalla confirmando el pago.</p>
                                  </div>
                                </div>
                                <div className="">
                                  <div className="steps">
                                    <p className="step-number">07</p>
                                    <p className="step-text">Pide al cliente que de click en cerrar sesión.</p>
                                  </div>
                                </div>
                                <div className="">
                                  <div className="steps">
                                    <p className="step-number">08</p>
                                    <p className="step-text">Para verificar que se haya realizado de forma correcta, cierra esta ventana y busca en tu correo la confirmación de tu compra</p>
                                  </div>
                                </div>
                              </div>
                            </InfoStyle>
                          </>
                        :
                        !cardScreen
                          ?
                          !isDesktop ?
                            <LoaderStatic />
                            :
                            <>
                              <div className="">
                                <p>Elige un método de pago</p>
                              </div>
                              <div style={{ display: 'flex', flexDirection: 'column', height: '100%', width: "100%", marginBottom: "20px" }}>
                                {paymentPartition?.amount <= 5000 && <>
                                  <Link className='payment-method-item' to={`/credit-card/${token}`}>
                                    <p style={{ margin: '25px 50px', textAlign: 'center' }}>PAGO CON TARJETA</p>
                                  </Link>
                                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center ' }}>
                                    <img src={bbva} style={{ width: "50px", height: "50px" }} />
                                    <img src={amex} style={{ width: "50px", height: "50px" }} />
                                    <img src={invex} style={{ width: "50px", height: "50px" }} />
                                  </div>
                                </>
                                }
                                {purchasesData?.commerce?.walletEnabled && <>
                                  <div className='payment-method-item' onClick={() => { setWalletScreen(true); setScreenPayment(true); setCardScreen(false) }}>
                                    <p style={{ margin: '25px 50px', textAlign: 'center' }}>WALLET</p>
                                  </div>
                                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center ' }}>
                                    <img src={wallet987} style={{ width: "55px", height: "55px" }} />
                                    <img src={wallet476} style={{ width: "35px", height: "35px" }} />
                                    <div style={{ marginTop: "10px" }}>
                                      <img src={applePay} style={{ width: "60px", height: "30px" }} />
                                      <p style={{ fontSize: "10px" }}>*Proximamente</p>
                                    </div>
                                  </div>
                                </>
                                }
                                {purchasesData?.commerce?.paynetEnabled &&
                                  <>
                                    <div className='payment-method-item margin' onClick={() => { createSpeiOrPaynetTransaction("PayNet") }}>
                                      <p style={{ margin: '25px 50px', textAlign: 'center' }}>PAYNET</p>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center ', marginBottom: "5px", marginTop: "10px" }}>
                                      <img src={aurrera} style={{ width: "20%", height: "20%" }} />
                                      <img src={eleven} style={{ width: "8%", height: "8%" }} />
                                      <img src={benavides} style={{ width: "20%", height: "20%" }} />
                                    </div>
                                  </>
                                }
                                {purchasesData?.commerce?.speiEnabled &&
                                  <>
                                    <div className='payment-method-item' onClick={() => { createSpeiOrPaynetTransaction("SPEI") }}>
                                      <p style={{ margin: '25px 50px', textAlign: 'center' }}>SPEI</p>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center ' }}>
                                      <p style={{ fontSize: "14px" }}>Transferencia bancaria</p>
                                    </div>
                                  </>
                                }
                              </div>
                            </>
                          :
                          <InfoStyle>
                            <div className="info-container">
                              <div className="">
                                <div className="steps">
                                  <p className="step-number">01</p>
                                  <p className="step-text">Escoge tu tarjeta o añade una nueva para proceder a hacer el pago.</p>
                                </div>
                              </div>
                            </div>
                          </InfoStyle>
                      :
                      !finalMessage ?
                        <>
                          <p style={{ textAlign: 'center' }}>Selecciona cualquiera de estas opciones para desplegar el código QR y proceder con tu pago.</p>
                          <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', height: '340px', justifyContent: 'center' }}>
                            {
                              wallets.map((wallet: any, key: number) => {
                                return (
                                  <div key={key} className='wallets-items' onClick={() => {
                                    setWalletQR(wallet.qr)
                                    if (sessionStorage.getItem("justlogin")) {
                                      let purchaseRef = firebase.database().ref('compras/' + purchasesData.id);
                                      purchaseRef.set({
                                        walletQR: wallet.qr
                                      })
                                    }
                                    setPayment(2);
                                  }}>
                                    {wallet.id == 476 ?
                                      <img src={wallet476} className="wallet-img" />
                                      : wallet.id == 233 ?
                                        <img src={wallet233} className="wallet-img" />
                                        : wallet.id == 268 ?
                                          <img src={wallet268} className="wallet-img" />
                                          : wallet.id == 399 ?
                                            <img src={wallet399} className="wallet-img" />
                                            : wallet.id == 433 ?
                                              <img src={wallet433} className="wallet-img" />
                                              : wallet.id == 987 ?
                                                <img src={wallet987} className="wallet-img" />
                                                : wallet.id == 467 ?
                                                  <img src={wallet467} className="wallet-img" />
                                                  : wallet.id == 825 ?
                                                  <img src={wallet825} className="wallet-img" />
                                                  : wallet.id == 675 ?
                                                  <img src={wallet675} className="wallet-img" />
                                                  : wallet.id == 616 &&
                                                  <img src={wallet616} className="wallet-img" />}

                                    <p className="wallets-items_text">{wallet.name}</p>
                                  </div >
                                )
                              })
                            }
                          </div >

                        </>
                        :
                        <>
                          <p style={{ textAlign: 'center', marginBottom: "20px" }}>Haz finalizado la compra sigue los pasos que se te indiquen</p>
                          <div className='final-buttons' onClick={logoutRemote}>
                            <p style={{ margin: '25px 50px', textAlign: 'center' }}>Cerrar Sesión</p>
                          </div>
                          <div className='final-buttons' onClick={() => {
                            setPayment(0)
                          }}>
                            <p style={{ margin: '25px 50px', textAlign: 'center' }} >Volver a elegir Wallet</p>
                          </div>
                          <div className='final-buttons' onClick={() => {
                            setWalletScreen(false)
                            setFinalMessage(false)
                            setScreenPayment(false)
                            setPayment(0)
                          }}>
                            <p style={{ margin: '25px 50px', textAlign: 'center' }}>Cambiar método de pago</p>
                          </div>
                        </>
                  }
                </div >
              </div >

            }
          </div >
          {
            screenPayment &&
            <div className="back-to-pay" onClick={() => { setScreenPayment(false); setPayment(0); setDesktopSteps(false) }}>
              <p>Volver a elegir metodo de pago</p>
            </div>
          }
          <div className="credit-card-back" onClick={() => { dispatch(authActions.logout()); }}>
            <p>Cancelar y volver a mi carrito</p>
          </div>
        </div >
      </WalletsStyle >
      <ModalSuccess
        modalConfig={{
          // onClose: closeWindow,
          active: (isModal?.show && isModal?.idModal === 'successBuy') || false,
        }}
        purchase={purchasesData}
      />
      <ModalBankData
        modalConfig={{
          onClose: closeModal,
          active: (isModal?.show && isModal?.idModal === 'extraBankData') || false,
        }}
        idCard={idCard!}
        show={errorsData.title === ''}
      />
      <ModalModifyUserData
        modalConfig={{
          onClose: closeModal,
          active: (isModal?.show && isModal?.idModal === 'updateUserData') || false,
        }}
        onClick={() => { openModal('extraBankData') }}
        times={times}
        idCard={idCard!}
      />
      <ModalCode
        modalConfig={{
          active: (isModal?.show && isModal?.idModal === 'SmsCode') || false,
        }}
        setShowScoring={setShowScoring}
      />
      <ModalModifyPhone
        modalConfig={{
          active: (isModal?.show && isModal?.idModal === 'ModifyPhone') || false,
        }}
      />
    </>
  );
};

export default Wallets;
