import React from "react";
import ModalContainer from "../modal-container/modal-container";
import { ModalBuyExpirationStyle } from "./modal-buy-expiration-style";

interface IModalBuyExpirationProps {
  onClose: () => void;
  active: boolean;
}

const ModalBuyExpiration: React.FC<IModalBuyExpirationProps> = ({
  onClose,
  active
}) => {
  return (
    <ModalContainer type="light" onClose={() => onClose()} active={active}>
      <ModalBuyExpirationStyle>
        <div className="modal-buy-expiration-title">
          <p>Opss...lo sentimos</p>
        </div>
        <div className="modal-buy-expiration-text">
          <p>
            Ha expirado el tiempo límite para realizar tu compra y el proceso ha
            sido cancelado.
          </p>
        </div>
      </ModalBuyExpirationStyle>
    </ModalContainer>
  );
};
export default ModalBuyExpiration;
