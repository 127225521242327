import { colors } from '../colors';

export const buttonColors = {
  primary: {
    text: colors['NT—001-000'],
    default: colors['PR––001-100'],
    disabled: colors['PR––001-30'],
    hover: colors['HV––Text'],
  },
  secondary: {
    text: colors['PR––001-100'],
    default: colors['NT—001-000'],
    disabled: colors['PR––001-30'],
    hover: colors['HV––Text'],
  },
  error: {
    text: colors['NT—001-000'],
    default: colors['ER–001-100'],
    disabled: colors['PR––001-30'],
    hover: colors['HV—Error'],
  },
  alert: {
    text: colors['NT—001-000'],
    default: colors['AL–001-100'],
    disabled: colors['PR––001-30'],
    hover: colors['HV—Alert'],
  },
  success: {
    text: colors['NT—001-000'],
    default: colors['SU–001-100'],
    disabled: colors['PR––001-30'],
    hover: colors['HV—Success'],
  },
};
