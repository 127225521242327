import { colors } from '../colors';

export const InputColors = {
  label: colors['TX–001-30'],
  errorText: colors['SC–004-50'],
  input: {
    default: colors['SC–001-30'],
    disabled: colors['TX–001-15'],
    focus: colors['PR––001-100'],
    hover: colors['HV––Text'],
    error: colors['SC–004-50'],
  },
  value: {
    default: colors['TX–001-100'],
    disabled: colors['TX–001-30'],
    focus: colors['PR––001-100'],
    hover: colors['TX–001-100'],
    error: colors['SC–004-50'],
  },
  placeholder: {
    default: colors['TX–001-30'],
    focus: colors['PR––001-50'],
    hover: colors['HV––Text'],
    error: colors['ER–001-50'],
  },
};
