import React from 'react'
import { exclamacion } from '../../assets/images/images';
import ErrorBankStyles from './errorBank-styles';



interface IErrorBankProps {
  messageOne: string,
  messegeTwo?: string,
}

const ErrorBank = (props: IErrorBankProps) => {
  const { messageOne, messegeTwo } = props;
  return (
    <ErrorBankStyles>
      <div className="containerConected">
        <div className="bank-logo">
          <img src={exclamacion} alt="" style={{ width: '80px' }}/>
        </div>
        <div className="lds-ring">
          <div>Error de Conexión!</div>
        </div>
        <div className="contentTitle">
          <p className="descriptionTitle">{messageOne}</p>
          {
            messegeTwo && <p className="descriptionTitle">{messegeTwo}</p>
          }
        </div>

      </div>

    </ErrorBankStyles>

  )
}

export default ErrorBank
