import { useDispatch } from 'react-redux';
import { authActions } from '../store/auth/actions';
// import useModal from "../../../hooks/useModal/use-modal";
import useModal from "../hooks/useModal/use-modal";

const useWindowManager = () => {
  const _window = window;
  const dispatch = useDispatch();
  const { isModal, openModal, closeModal } = useModal();
  
  const printWindow = () => {
    console.log(_window)
  }

  const validateWindowToken = () => {
    console.log("Aquí haremos validación")
  }

  // This fn only works if the windows is open using the payment button
  const closeWindow = () => {
    if(isModal?.idModal !== 'extraBankData') return;
    dispatch(authActions.logout());
    _window.close();
  }

  return {
    closeWindow,
    printWindow,
    validateWindowToken,
  }

}
export default useWindowManager;
