// @ts-nocheck
import { NestDataObject } from 'react-hook-form';

// LOGIN
export const getLoginFormErrors = (inputErrors: NestDataObject<Record<any, string>>, inputName: string) => {
    if (!inputErrors[inputName]) return;
  
    if (inputErrors[inputName]?.type === 'isValidEmail') return 'invalid-email';
    if (inputErrors[inputName]?.type === 'isValidPassword') return 'invalid-password';
    if (inputErrors[inputName]?.type === 'passwordMissmatch') return 'no-match';
  
    return inputErrors[inputName]?.message;
  };

// FOR REGISTER 
export const getRegisterFormErrors = (inputErrors: NestDataObject<Record<any, string>>, inputName: string) => {
  if (!inputErrors[inputName]) return;

  if (inputErrors[inputName]?.type === 'passwordMissmatch') return 'no-match';
  if (inputErrors[inputName]?.type === 'isValidName') return 'invalid-name';
  if (inputErrors[inputName]?.type === 'isValidPassword') return 'no-strong';
  if (inputErrors[inputName]?.type === 'isValidSelect') return 'required';
  if (inputErrors[inputName]?.type === 'isValidPhone') return 'invalid-phone';

  if (inputErrors[inputName]?.type === 'isValidEmail') return 'invalid-email';
  if (inputErrors[inputName]?.type === 'existEmail') return 'invalid-email';

  if (inputErrors[inputName]?.type === 'ineNotUploaded') return 'both-ine-images-needed';
  if (inputErrors[inputName]?.type === 'isRFC') return 'invalid-rfc';
  if (inputErrors[inputName]?.type === 'existRFC') return 'invalid-rfc';
  if (inputErrors[inputName]?.type === 'isValidNumber') return 'invalid-format';
  if (inputErrors[inputName]?.type === 'isValidDate') return 'invalid-format';
  if (inputErrors[inputName]?.type === 'isValidPhoto') return 'invalid-image';
  if (inputErrors[inputName]?.type === 'isValidSize') return 'too-heavy-file';

  return inputErrors[inputName]?.message;
};

// FOR CREDIT CARD
export const getCardFormErrors = (inputErrors: NestDataObject<Record<any, string>>, inputName: string) => {
  if (!inputErrors[inputName]) return;
  if (inputErrors[inputName]?.type === 'isValidSelect') return 'required';
  return inputErrors[inputName]?.message;
};

// FOR NEW CREDIT CARD
export const getNewCardFormErrors = (inputErrors: NestDataObject<Record<any, string>>, inputName: string) => {
  if (!inputErrors[inputName]) return;

  if (inputErrors[inputName]?.type === 'isValidSelect') return 'required';
  if (inputErrors[inputName]?.type === 'isValidNumber') return 'invalid-format';
  if (inputErrors[inputName]?.type === 'invalidMonth') return 'invalid-month';
  if (inputErrors[inputName]?.type === 'invalidYear') return 'invalid-year';
  if (inputErrors[inputName]?.type === 'isValidCard') return 'invalid-card';
    
  if (inputErrors[inputName]?.type === 'isValidName') return 'invalid-name';

  return inputErrors[inputName]?.message;
};

// FOR EXTRA BANK DATA

export const getExtraBankDataFormErrors = (inputErrors: NestDataObject<Record<any, string>>, inputName: string) => {
  if (!inputErrors[inputName]) return;

  if (inputErrors[inputName]?.type === 'isValidName') return 'invalid-name';
  if (inputErrors[inputName]?.type === 'isValidEmail') return 'invalid-email';

  return inputErrors[inputName]?.message;
};

// FOR CONFIRM BUY

export const getModalBuyErrors = (inputErrors: NestDataObject<Record<any, string>>, inputName: string) => {
  if (!inputErrors[inputName]) return;
  return inputErrors[inputName]?.message;
};