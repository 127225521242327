// This theme was created using the Material Design Guidelines
// For more info take a look -> https://material.io/design/material-theming/implementing-your-theme.html#
import { ITheme } from '../theme-type';

const normal: ITheme = {
  colors: {
    primary: '#1A4CCD',
    primaryVariant: 'transparent',
    secondary: '#B3B3B6',
    secondaryVariant: '',
    background: '#F2F2F2',
    surface: 'white',
    error: '',
    onPrimary: '#F6F6F6',
    onSecondary: '',
    onBackground: '#1A1C24',
    onSurface: '#1A1C24',
    onError: '#E49CBB',
  },
  typography: {
    h1: '',
    h2: '',
    h3: '',
    h4: '',
    h5: '',
    h6: '',
    subtitle1: '',
    subtitle2: '',
    body1: '',
    body2: '',
    button: '',
    caption: '',
    overline: '',
  },
};

export default normal;
