import styled from 'styled-components/macro';

interface ICardImageShadowContainer {
  colorShadow?: string;
}

const CardImageShadowContainer = styled.div<ICardImageShadowContainer>`
  position: relative;
  margin-bottom: 30px;
  padding-bottom: 30px;
  margin-right: 30px;
  display: flex;
  z-index: 1;
  justify-content: center;
  .image-container {
    position: relative;
    width: 245.59px;
    height: 297.8px;
    z-index: 2;
    img {
      width: 100%;
      height: auto;
      min-height: 100%;
      object-fit: cover;
    }
  }
  .shadow-image {
    position: absolute;
    z-index: -1;
    top: 30px;
    right: -30px;
    width: 245.59px;
    height: 297.8px;
    background: black;
    background: ${props => props.colorShadow};
  }
`;

export default CardImageShadowContainer;
