import styled from "styled-components/macro";
import { InputColors } from "../../assets/colors/input-colors/input-colors";
import { colors } from "../../assets/themes";

export const SelectBoxContainer = styled.div`
  position: relative;

  .select-box-topbar {
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 9;
    margin-bottom: 8px;

    .select-box-topbar-label {
      color: ${InputColors.label};
      text-transform: uppercase;
    }
  }

  .select-box-topbar-icon {
    position: relative;
    z-index: 9;
    display: inherit;
    img {
      max-width: 18px;
      cursor: pointer;
    }
  }

  .select-box-main-field {
    border-bottom: 1px solid ${InputColors.input.default};
    p {
      font-family: "gilroy";
      font-size: 16px;
      line-height: 24px;
      font-weight: 300;
      color: ${InputColors.value.default};
    }
  }

  &:hover {
    .select-box-main-field {
      border-bottom: 1px solid ${InputColors.input.hover};

      p {
        color: ${InputColors.value.hover};
      }
    }
  }

  .cXimuH {
    font-size: 12px !important;
  }
  &.show-list {
    z-index: 12;
    .select-box-main  {
      .select-box-icon {
        transform: rotate(180deg);
      }

      .select-box-main-field {
        border-bottom: 1px solid ${InputColors.input.focus};

        p {
          color: ${InputColors.value.focus};
        }
      }
    }
    .select-box-main-list {
      position: absolute;
      display: block;
      color: ${InputColors.input.default};
      p  {
        opacity: 0.8;
        transition: all 0.3s ease-in-out;
        &:hover {
          opacity: 1;
        }
      }
    }
  }

  &.error {
    .select-box-main-field {
      border-bottom: 1px solid ${InputColors.input.error};

      p {
        color: ${InputColors.value.error};
      }
    }

    .select-box-error {
      color: ${InputColors.errorText};
    }
  }

  &.disabled {
    pointer-events: none;

    .select-box-topbar-label {
      color: ${InputColors.label};
    }

    .select-box-main-field {
      background: #f2f2f3;
      border-bottom: 1px solid ${InputColors.input.disabled};

      p {
        color: ${InputColors.value.disabled};
      }

      &:before {
        background: ${InputColors.input.disabled};
      }
    }
  }

  &.complete {
    .select-box-main-field {
      border-bottom: 1px solid ${InputColors.input.default};

      p {
        color: ${InputColors.value.default};
      }
    }
  }

  .selector-separator {
    background: ${colors["NT–001-100"]};
    height: 1px;
    margin: 0px 8px;
  }

  .select-box-topbar {
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 9;
    margin-bottom: 6px;
  }

  .select-box-topbar-label {
    color: ${InputColors.label};
    text-transform: uppercase;
    font-size: 12px;
    line-height: 14px;
    &.right {
      color: #1A4CCD;
      cursor: pointer;
    }
  }

  .select-box-topbar-icon {
    position: relative;
    z-index: 9;
    display: inherit;

    img {
      max-width: 18px;
      cursor: pointer;
    }
  }

  .select-box-main-field {
    position: relative;
    width: 100%;
    padding: 13px 18px 9px;
    padding-right: 30px;
    padding-left: 0;
    transition: all 0.2s ease-in-out;
    margin-bottom: 4px;
    color: ${colors["SC–001-100"]};
    border-bottom: 1px solid ${colors["NT–001-100"]};
    display: flex;
    align-items: center;

    cursor: pointer;
    height: 48px;

    p {
      cursor: inherit;
    }

    &:focus {
      background: ${colors["SC–001-100"]};
      border-bottom: none;
      color: ${colors["SC–001-100"]};
      border-bottom: 1px solid ${colors["SC–001-100"]};
    }
  }

  .select-box-main {
    position: relative;
  }

  .select-box-icon {
    position: absolute;
    top: 16px;
    right: 6px;
    width: 18px;
    z-index: 10;
    transition: all 0.3s ease-in-out;
    img {
      max-width: 18px;
      cursor: pointer;
    }
  }

  .select-box-tooltip {
    position: absolute;
    top: calc(100% + 12px);
    z-index: 29;
    width: 260px;
    right: 0;
  }

  .select-box-main-list {
    position: absolute;
    border-radius: 3px;
    box-shadow: 0px 2px 17px rgba(0, 0, 0, 0.05);
    z-index: 12;
    background: white;
    width: 100%;
    display: none;
    color: ${colors["SC–001-100"]};
    max-height: 250px;

    li {
      transition: 0.3s all ease-in-out;
      cursor: pointer;
      padding: 11px 13px;
      width: 100%;

      &:hover {
        background: ${colors["SC-001-10"]};
      }
    }
  }

  &.required {
    .select-box-topbar-label {
      position: relative;
      padding-left: 10px;
      &::before  {
        content: "";
        width: 4px;
        height: 4px;
        position: absolute;
        left: 0;
        top: 9px;
        background: ${colors["PR-001/100"]};
        border-radius: 100%;
        transition: all 0.3s ease-in-out;
      }
    }
  }

  .select-box-error {
    color: ${colors["ER-001-100"]};
  }
`;
