import React from "react";
import CircleStat from "../circle-stat/circle-stat";
import { ItemResumeStyle } from "./item-resume-style";

interface IItemResumeProps {
  totalDues: number;
  currentDue: number;
  date: string;
  amount: string;
  information?: boolean  
}

const ItemResume: React.FC<IItemResumeProps> = ({
  totalDues,
  currentDue,
  date,
  amount,
  information
}) => {
  const progress = (currentDue * 100) / totalDues;
  return (
    <ItemResumeStyle>
      <div className="item-resume-graph">
        <CircleStat radius={17} stroke={3} progress={progress} />
      </div>
      <div className="item-resume-fee">
        <p>
          Cuota {currentDue}/{totalDues}
        </p>
      </div>
      <div className="item-resume-date">
        <p>{date}</p>
      </div>
{/*       {information && 
        <div className="item-resume-information">
          <p>i</p>
        </div>
      } */}
      <div className={`${information ? "item-resume-amount-information" : "item-resume-amount"}`}>
        {information && 
          <div className="tooltip" style={{color: "white", borderRadius: "9999px", background: "gray", width: "23px", textAlign: "center", marginRight: "5px", marginLeft: "32px", cursor: "pointer"}}>
            <p>i</p>
            <p className="tooltip-box">Aplazar tus pagos no genera intereses, únicamente una pequeña comisión que será incluida en tu primera cuota</p>
          </div>
        }
        <p>{amount}</p>
      </div>
    </ItemResumeStyle>
  );
};

export default ItemResume;
