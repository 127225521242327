import styled from 'styled-components';

export const CalendarItemContainer = styled.div`
  background: white;

  border: 1px solid #f1f1f1;
  box-sizing: border-box;
  border-radius: 6px;

  .calendar-select-type  {
    display: flex;
  }

  .current-month {
    position: relative;
    flex: auto;
    text-align: center;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
  }

  .current-year {
    text-transform: uppercase;
    flex: auto;
    text-align: center;
    cursor: pointer;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
  }

  .calendar-header  {
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: inset 0px -1px 0px #f1f1f1;
    padding: 16px;
    &__date  {
      flex: auto;
      justify-content: space-between;
      align-items: center;
      display: flex;
      font-weight: 500;
    }
  }

  .calendar-arrow-container {
    display: flex;
    align-items: center;
    display: none;
  }

  /* GRID */

  .row {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: stretch;
    width: 100%;
  }

  /* Calendar */

  .calendar {
    display: block;
    position: relative;
    width: 100%;
    padding: 0px 0px 16px;
  }

  .calendar-days {
    display: flex;
    justify-content: space-between;
    box-shadow: inset 0px -1px 0px #f1f1f1;
  }

  .calendar-days-day {
    text-transform: uppercase;
    width: 36px;
    height: 36px;
    display: flex;
    margin: 8px 14px;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    line-height: 20px;
  }

  .cell-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
  }

  .cell {
    position: relative;
    border: 1px solid red;
    height: 42px;
    min-width: 42px;
    width: 42px;
    margin: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    font-size: 20px;
    line-height: 25px;
    font-weight: 500;
    border-radius: 6px;
    transition: 0.3s ease-in-out all;
    border: 1px solid white;
    cursor: pointer;
    &:hover {
    }
  }
  .col.cell {
    &.selected {
      color: white;
      background: #1a4ccd;
    }
    &.last {
      background: none;
    }
    &.added-hours {
      background: none;
    }
  }

  .no-allow {
    cursor: not-allowed !important;
  }

  .selected-multi {
    position: relative;
    z-index: 1;
    color: white;

    .bg {
      opacity: 0.05;
      transition: 0.5s ease-in;
    }
    &:hover {
    }
  }

  .in-selected {
    position: relative;
    z-index: 1;
    color: white;
    border-radius: 100%;

    .bg {
      opacity: 0.05;
      transition: 0.5s ease-in;
    }

    &::after  {
      content: '';

      width: 30px;
      height: 30px;

      position: absolute;
      left: 5px;
      top: 5px;
      z-index: -1;
    }
    /* &:hover {
      background: white;
    } */
  }

  .disabled {
    opacity: 0;
    pointer-events: none;
  }

  .arrows {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      max-width: 14px;
      cursor: pointer;
    }
  }

  .arrow-left {
    margin-right: 7px;
    position: relative;
    &:before {
      content: '';

      border-radius: 50%;
      width: 6px;
      height: 6px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: -18px;
    }
  }

  .select-month-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: white;
    z-index: 9;
    display: none;
    &.active {
      display: block;
    }
  }

  .select-month-container-row {
    display: flex;

    height: 25%;
  }

  .select-month-container-item {
    width: 33.33%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: 0.3s all ease-in-out;
    text-transform: capitalize;

    /* &:hover {

    } */
  }

  .year-select {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    background: white;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 100%;
    z-index: 12;
    display: none;

    &.active {
      display: block;
      ul {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(70px, 1fr));
      }
    }

    li {
      padding: 16px 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }

  .year-select-close {
    text-align: right;
    padding: 10px 16px;
    display: none;

    img {
      max-width: 16px;
      cursor: pointer;
    }
  }

  &.anual {
    background: white;
    font-size: 13px;
    box-shadow: none;
    border-radius: 0px;

    .current-year  {
      display: none;
    }
    .arrows {
      display: none;
    }
  }

  .calendar-legend {
    padding: 16px 24px;
    display: flex;
  }
  .calendar-legend-item {
    margin-right: 16px;
    display: flex;
    &.municipal {
      .calendar-legend-item-color {
      }
    }
    &.other {
      .calendar-legend-item-color {
      }
    }
    &.national {
      .calendar-legend-item-color {
      }
    }
    .calendar-legend-item-color {
      width: 16px;
      height: 16px;
      margin-right: 8px;
      border-radius: 2px;
    }
    .calendar-legend-item-text {
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: #9da0a4;
    }
  }

  &.select {
    .calendar-legend {
      display: none;
    }
    .cell {
      font-size: 16px;
      line-height: 10px;
      &:hover {
      }
    }
    .calendar-days-day {
      margin: 8px;
    }
    .calendar-days-day {
      height: 28px;
      min-width: 28px;
      width: 28px;
      font-size: 14px;
      line-height: 10px;
    }
  }
`;
