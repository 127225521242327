import * as Interfaces from '@aluxion-lightpay/interfaces';

export const selectCity = (value: string) => {
  switch (value) {
    case 'Aguascalientes':
      return Interfaces.MUNICIPALITY_AGUASCALIENTES;
    case 'Baja California':
      return Interfaces.MUNICIPALITY_BAJA_CALIFORNIA;
    case 'Baja California Sur':
      return Interfaces.MUNICIPALITY_BAJA_CALIFORNIA_SUR;
    case 'Chihuahua':
      return Interfaces.MUNICIPALITY_CHIHUAHUA;
    case 'Colima':
      return Interfaces.MUNICIPALITY_COLIMA;
    case 'Campeche':
      return Interfaces.MUNICIPALITY_CAMPACHE;
    case 'Coahuila':
      return Interfaces.MUNICIPALITY_COAHUILA_DE_ZARAGOZA;
    case 'Chiapas':
      return Interfaces.MUNICIPALITY_CHIAPAS;
    case 'Ciudad de México':
      return Interfaces.MUNICIPALITY_CIUDAD_DE_MEXICO;
    case 'Durango':
      return Interfaces.MUNICIPALITY_DURANGO;
    case 'Guerrero':
      return Interfaces.MUNICIPALITY_GUERRERO;
    case 'Guanajuato':
      return Interfaces.MUNICIPALITY_GUANAJUATO;
    case 'Hidalgo':
      return Interfaces.MUNICIPALITY_HIDALGO;
    case 'Jalisco':
      return Interfaces.MUNICIPALITY_JALISCO;
    case 'México':
      return Interfaces.MUNICIPALITY_MEXICO;
    case 'Michoacán':
      return Interfaces.MUNICIPALITY_MICHOACAN_DE_OCAMPO;
    case 'Morelos':
      return Interfaces.MUNICIPALITY_MORELOS;
    case 'Nayarit':
      return Interfaces.MUNICIPALITY_NAVARIT;
    case 'Nuevo León':
      return Interfaces.MUNICIPALITY_NUEVO_LEON;
    case 'Oaxaca':
      return Interfaces.MUNICIPALITY_OAXACA;
    case 'Puebla':
      return Interfaces.MUNICIPALITY_PUEBLA;
    case 'Querétaro':
      return Interfaces.MUNICIPALITY_QUERETARO;
    case 'Quintana Roo':
      return Interfaces.MUNICIPALITY_QUINTANA_ROO;
    case 'Sinaloa':
      return Interfaces.MUNICIPALITY_SINALOA;
    case 'San Luis Potosí':
      return Interfaces.MUNICIPALITY_SAN_LUIS_POTOSI;
    case 'Sonora':
      return Interfaces.MUNICIPALITY_SONORA;
    case 'Tabasco':
      return Interfaces.MUNICIPALITY_TABASCO;
    case 'Tlaxcala':
      return Interfaces.MUNICIPALITY_TLAXCALA;
    case 'Tamaulipas':
      return Interfaces.MUNICIPALITY_TAMAULIPAS;
    case 'Veracruz':
      return Interfaces.MUNICIPALITY_VERACRUZ_DE_IGNACIO_DE_LA_LLAVE;
    case 'Yucatán':
      return Interfaces.MUNICIPALITY_YUCATAN;
    case 'Zacatecas':
      return Interfaces.MUNICIPALITY_ZACATECAS;
    default:
      break;
  }
};
