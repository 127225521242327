import styled, { css } from 'styled-components';
import { ILogoIcon } from './logo-icon-type';

const SVGContainer = styled.svg<ILogoIcon>`
  ${(props: ILogoIcon) =>
    props.scale
      ? css`
          transform: scale(${props.scale}, ${props.scale});
        `
      : css``}
`;

export default SVGContainer;
