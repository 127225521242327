import React from 'react';
import SVGContainer from './logo-icon-style';
import { ILogoIcon } from './logo-icon-type';

const LogoIcon: React.FC<ILogoIcon> = (props: ILogoIcon) => {
  const { scale, small, fill } = props;

  return (
    <SVGContainer
      scale={scale}
      width="148"
      height="47"
      viewBox="0 0 301 85"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {small ? (
        <>
          <path
            d="M10.71 11.04C10.71 9.14999 12.53 8.17 14.77 8.17C17.36 8.17 19.32 9.49999 20.37 11.74L28.07 7.53999C25.34 2.70999 20.44 
          0.119995 14.77 0.119995C7.56 0.119995 1.47 4.10999 1.47 11.25C1.47 23.57 19.53 20.77 19.53 25.88C19.53 27.91 17.57 28.89 14.7 
          28.89C11.2 28.89 8.82 27.21 7.84 24.34L0 28.75C2.52 34.14 7.7 37.08 14.7 37.08C22.19 37.08 28.77 33.44 28.77 25.88C28.77 13 10.71 
          16.22 10.71 11.04Z"
            fill={!!fill ? fill:'#1A1C24'}
          />
          <path
            d="M54.37 19.12C49.26 19.12 45.55 21.01 43.03 24.23V20.1H34V69.1H43.03V50.97C45.55 54.19 49.26 56.08 54.37 56.08C63.75 56.08 
          71.45 48.03 71.45 37.6C71.45 27.17 63.75 19.12 54.37 19.12ZM52.69 47.47C47.16 47.47 43.03 43.48 43.03 37.6C43.03 31.72 47.16 27.73 
          52.69 27.73C58.29 27.73 62.42 31.72 62.42 37.6C62.42 43.48 58.29 47.47 52.69 47.47Z"
            fill={!!fill ? fill:'#1A1C24'}
          />
        </>
      ) : (
        <>
          <path
            d="M10.71 26.04C10.71 24.15 12.53 23.17 14.77 23.17C17.36 23.17 19.32 24.5 20.37 26.74L28.07 22.54C25.34 17.71 20.44 15.12 14.77 
          15.12C7.56 15.12 1.47 19.11 1.47 26.25C1.47 38.57 19.53 35.77 19.53 40.88C19.53 42.91 17.57 43.89 14.7 43.89C11.2 43.89 8.82 42.21 
          7.84 39.34L0 43.75C2.52 49.14 7.7 52.08 14.7 52.08C22.19 52.08 28.77 48.44 28.77 40.88C28.77 28 10.71 31.22 10.71 26.04Z"
            fill={!!fill ? fill:'#1A1C24'}
          />
          <path d="M34.5773 51.1H43.6073V0H34.5773V51.1Z" fill={!!fill ? fill:'#1A1C24'} />
          <path
            d="M56.3522 11.9C59.4322 11.9 61.9522 9.38 61.9522 6.37C61.9522 3.36 59.4322 0.769999 56.3522 0.769999C53.3422 0.769999 50.8222 
          3.36 50.8222 6.37C50.8222 9.38 53.3422 11.9 56.3522 11.9ZM51.8722 51.1H60.9022V16.1H51.8722V51.1Z"
            fill={!!fill ? fill:'#1A1C24'}
          />
          <path
            d="M95.9771 16.1V20.44C93.5271 17.15 89.7471 15.12 84.7771 15.12C74.8371 15.12 67.3471 23.24 67.3471 33.04C67.3471 42.91 74.8371 
          50.96 84.7771 50.96C89.7471 50.96 93.5271 48.93 95.9771 45.64V49.42C95.9771 54.95 92.4771 58.03 86.8071 58.03C81.4171 58.03 79.1071 
          55.86 77.6371 53.13L69.9371 57.54C73.0171 63.21 79.0371 66.08 86.5271 66.08C95.7671 66.08 104.797 60.9 104.797 49.42V16.1H95.9771ZM86.1771 
          42.63C80.5071 42.63 76.3771 38.64 76.3771 33.04C76.3771 27.51 80.5071 23.52 86.1771 23.52C91.8471 23.52 95.9771 27.51 95.9771 33.04C95.9771 
          38.64 91.8471 42.63 86.1771 42.63Z"
            fill={!!fill ? fill:'#1A1C24'}
          />
          <path
            d="M132.445 15.12C127.755 15.12 124.115 16.87 122.015 20.02V2.1H112.985V51.1H122.015V32.2C122.015 26.11 125.305 23.52 129.715 
          23.52C133.775 23.52 136.645 25.97 136.645 30.73V51.1H145.675V29.61C145.675 20.3 139.865 15.12 132.445 15.12Z"
            fill={!!fill ? fill:'#1A1C24'}
          />
          <path
            d="M173.406 24.78V16.1H165.496V6.3L156.466 9.03V16.1H150.376V24.78H156.466V39.34C156.466 48.79 160.736 52.5 173.406 51.1V42.91C168.226 
          43.19 165.496 43.12 165.496 39.34V24.78H173.406Z"
            fill={!!fill ? fill:'#1A1C24'}
          />
          <path
            d="M200.75 34.12C195.64 34.12 191.93 36.01 189.41 39.23V35.1H180.38V84.1H189.41V65.97C191.93 69.19 195.64 71.08 200.75 71.08C210.13 
          71.08 217.83 63.03 217.83 52.6C217.83 42.17 210.13 34.12 200.75 34.12ZM199.07 62.47C193.54 62.47 189.41 58.48 189.41 52.6C189.41 46.72 193.54 
          42.73 199.07 42.73C204.67 42.73 208.8 46.72 208.8 52.6C208.8 58.48 204.67 62.47 199.07 62.47Z"
            fill={!!fill ? fill:'#1A1C24'}
          />
          <path
            d="M250.798 35.1V39.23C248.278 36.08 244.498 34.12 239.388 34.12C230.078 34.12 222.378 42.17 222.378 52.6C222.378 63.03 230.078 71.08 
          239.388 71.08C244.498 71.08 248.278 69.12 250.798 65.97V70.1H259.828V35.1H250.798ZM241.068 62.47C235.538 62.47 231.408 58.48 231.408 52.6C231.408 
          46.72 235.538 42.73 241.068 42.73C246.668 42.73 250.798 46.72 250.798 52.6C250.798 58.48 246.668 62.47 241.068 62.47Z"
            fill={!!fill ? fill:'#1A1C24'}
          />
          <path
            d="M290.837 35.1L283.067 58.62L273.967 35.1H264.097L278.447 68.98C276.767 73.67 274.247 75.77 269.277 75.7V84.1C278.167 84.59 284.117 
          80.18 287.757 70.1L300.497 35.1H290.837Z"
            fill={!!fill ? fill:'#1A1C24'}
          />
        </>
      )}
    </SVGContainer>
  );
};

export default LogoIcon;