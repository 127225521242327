import { useSelector, useDispatch } from 'react-redux';
import { modalActions } from '../../store/modal/actions';
import { modalSelector } from '../../store/modal/selectors';

const useModal = () => {
  const isModal = useSelector(modalSelector.isShowing());
  const dispatch = useDispatch();
  const openModal = async (id: number | string) => {
    await dispatch(modalActions.show(id));
  };

  const closeModal = async () => {
    await dispatch(modalActions.hide());
  };
  return {
    isModal,
    openModal,
    closeModal,
  };
};

export default useModal;
