import styled, { css } from 'styled-components/macro';

interface INoteTextProps {
  color?: string;
}

export const NoteText = styled.div<INoteTextProps>`
  font-family: 'gilroy';
  font-weight: 'Normal';
  font-size: 12px;
  line-height: 17px;
  ${props => css`
    color: ${props.color};
  `}
`;
