import { colors } from '../colors'; // Todo esto hay que cambiarlo

export const CheckboxColors = {
  label: {
    default: colors['TX–001-100'],
    disabled: colors['TX–001-30'],
  },
  errorText: colors['SC–004-50'],
  checkbox: {
    default: colors['SC–001-30'],
    disabled: colors['TX–001-15'],
    focus: colors['PR––001-100'],
    hover: colors['PR––001-100'],
    error: colors['SC–004-50'],
  },
};
