import styled from 'styled-components';

export const ModalModifyPhoneStyle = styled.div`
  max-width: 444px;
  .modal-code-title {
    font-size: 16px;
    line-height: 26px;
    font-weight: 600;
    margin-bottom: 25px;
    span {
      color: #1A4CCD;
      font-size: 18px;
      margin-bottom: 10px;
    }
  }
  
  .modal-code-text {
    margin-bottom: 25px;
  }
  .modal-code-input {
    margin-bottom: 42px;
  }
  .modal-code-send {
    margin-bottom: 24px;
    font-size: 14px;
    line-height: 22px;
    font-weight: 300;
    &__title {

    }
    &__button {
      color: #1A4CCD;
      text-decoration: underline;
      cursor: pointer;
    }
    &__incorrect {
      color: #1A4CCD;
      text-decoration: underline;
      cursor: pointer;
      display: flex;
      justify-content: flex-end;
    }
  }
  .modal-code-cancel {
    color: #1A4CCD;
    text-decoration: underline;
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    margin: 20px 20px;
  }

  .modal-code-button {

  }

  

`