import styled from "styled-components";

export const ModalAdressStyle = styled.div`
  
  
  max-width: 436px;
  
  .credit-card-back {
    font-size: 14px;
    line-height: 24px;
    font-weight: 300;
    text-decoration: underline;
    color: #1A4CCD;
    margin-top: 42px;
    cursor: pointer;
  }
    
  .modal-adress-title {
    margin-bottom: 16px;
  }

  .update-address-title {
    width: 100%;
    font-family: "Gilroy";
    font-size: 20px;
    line-height: 26px;
    color: #01030C;
    margin-bottom: 20px; 

    span {
        color: #1A4CCD;
    }
  }
`;
