import { IUser } from "@aluxion-lightpay/interfaces";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { logo } from "../../../assets/images/images";
import { authActions } from "../../../store/auth/actions";
import { authSelectors } from "../../../store/auth/selectors";
import { toastActions } from "../../../store/toasts/actions";
import {
  getLoginInputMetada,
  LOGIN_EMAIL_INPUT_NAME
} from "../../../utils/login-input-names";
import { getLoginFormErrors } from "../../../utils/login-input-validations";
import InputBox from "../../input-box/input-box";
import MainButton from "../../main-button/main-button";
import ModalContainer from "../modal-container/modal-container";
import { ModalRecoveyPassStyle } from "./modal-recovery-pass-style";
import { IModalRecoveyPassProps } from './modal-recovery-pass-types';
import LogoIcon from "../../../assets/icons/Logo/logo-icon";



const ModalRecoveyPass: React.FC<IModalRecoveyPassProps> = ({
  modalConfig
}) => {
  const dispatch = useDispatch();
  const user = useSelector(authSelectors.getMe());
  const { register, handleSubmit, errors } = useForm();
  const [send, setSend] = useState<boolean>(false);
  const [errorSend, setErrorSend] = useState<boolean>(false);

  const handleSend = async (data: any) => {
    try {
      await dispatch(
        authActions.recoverPassword(
          user ? user.email : data[LOGIN_EMAIL_INPUT_NAME]
        )
      );
      setErrorSend(false);
      setSend(true);
    } catch {
      setSend(false);
      setErrorSend(true);
    }
  };

  return (
    <ModalContainer {...modalConfig}>
      <ModalRecoveyPassStyle>
        <div className="modal-logo">
          <LogoIcon scale="1.3" fill=""/>
        </div>

        {send && !errorSend ? (
          <>
            <div className="modal-recovery-text">
              <p>
                Ya te hemos enviado a un email con los pasos para crear una
                contraseña nueva. Dale unos minutos y comprueba la carpeta de
                spam si no te ha llegado en un rato.
              </p>
              <p>
                No lo he recibido,{" "}
                <span onClick={handleSubmit(handleSend)}>volver a enviar.</span>
              </p>
            </div>
          </>
        ) : !send && errorSend ? (
          <>
            <div className="modal-recovery-title">
              <p>Opss...lo sentimos</p>
            </div>
            <div className="modal-recovery-text">
              <p>Ha habido un problema</p>
              <span
                onClick={() => {
                  setErrorSend(false);
                }}
              >
                Volver a enviar.
              </span>
            </div>
          </>
        ) : (
          <>
            <div className="modal-recovery-title">
              <p>¿Necesitas ayuda con la contraseña? </p>
            </div>
            <div className="modal-recovery-text">
              <p>
                No te preocupes, nos ha pasado a todos. Introduce abajo tu
                correo y te ayudamos a crear una contraseña nueva.
              </p>
            </div>
            <div className="modal-recovery-input">
              <InputBox
                labelText="Email"
                name={LOGIN_EMAIL_INPUT_NAME}
                inputRef={register(getLoginInputMetada(LOGIN_EMAIL_INPUT_NAME))}
                errorCode={getLoginFormErrors(errors, LOGIN_EMAIL_INPUT_NAME)}
                onKeyPress={handleSubmit(handleSend)}
              />
            </div>
            <div className="modal-recovery-button">
              <MainButton
                text="Enviar"
                type="primary"
                onClick={handleSubmit(handleSend)}
              />
            </div>
          </>
        )}
      </ModalRecoveyPassStyle>
    </ModalContainer>
  );
};

export default ModalRecoveyPass;
