import styled from 'styled-components';

const LoaderBankContainer = styled.div`
display: flex;
width: 100%;
height: 100%;
align-content: center;
flex-direction: column;
align-items: center;

.containerConected{
  margin: auto;
  background-color: #F7F7F7;
  width: 70%;
  height: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
}
.descriptionTitle{
  font-weight: 200;
  @media (max-width: 768px) {
    text-align: center;
  }
}
.bank-logo{
  margin-top:1rem;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 3px solid #1A4CCD;
  border-radius: 50%;
  animation: lds-ring 1.5s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #1A4CCD transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.15s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.45s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

`

export default LoaderBankContainer;