import styled, { css } from 'styled-components/macro';

interface ILabelTextProps {
  color?: string;
}

export const LabelText = styled.div<ILabelTextProps>`
  font-family: 'gilroy';
  font-size: 12px;
  line-height: 14px;
  font-weight: 'normal';
  letter-spacing: 1px;
  text-transform: uppercase;
  ${props => css`
    color: ${props.color};
  `}
`;
