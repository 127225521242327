export const logo = require("./files/logo.svg");
export const image500 = require("./files/500.png");
export const image404 = require("./files/404.png");
export const bannerCampaign = require("./files/banner-campaign.png");
export const mainLayoutBackground = require("./files/main-layout-background.jpeg");
export const testOpe1 = require("./files/operaciones-prueba1.png");
export const testOpe2 = require("./files/operaciones-prueba2.png");
export const testOpe3 = require("./files/operaciones-prueba3.png");
export const login1 = require("./files/login-1.png");
export const login2 = require("./files/login-2.png");
export const login3 = require("./files/login-3.png");
export const login4 = require("./files/login-4.png");
export const login5 = require("./files/login-5.png");
export const profileTest = require("./files/profile-test.png");
export const tickBlueIcon = require("./files/tick-blue-icon.svg");
export const tickGreyIcon = require("./files/tick-grey-icon.svg");
export const tickRedIcon = require("./files/tick-red-icon.svg");
export const mexicoFlag = require("./files/mexico.svg");
export const arrowDownBlue = require("./files/icon-arrow-down-blue.svg");
export const arrowDownGrey = require("./files/icon-arrow-down-grey.svg");
export const cameraGrey = require("./files/camera-icon.svg");
export const arrowPlus = require("./files/arrow-plus.svg");
export const calendarIcon = require("./files/calendar-icon.svg");
export const arrowDatepickerMonthBack = require("./files/arrow-datepicker-month-back.svg");
export const arrowDatepickerMonthNext = require("./files/arrow-datepicker-month-next.svg");
export const eyeBlue = require("./files/icon-eye-blue.svg");
export const eyeBlueCrossed = require("./files/icon-eye-blue-crossed.svg");
export const check = require("./files/check-icon.svg");
export const semiCircle = require("./files/semi-circle-mask.svg");
export const attachPlus = require('./files/attach-plus.svg');
export const modalSuccess = require('./files/modal-success.png');
export const modalPrepending = require('./files/prepending.png');
export const openpayLogo = require('./files/openpay_color.png');
export const eye = require('./files/eye.png');
export const infoBank = require('./files/info2.png');
export const infoBank1 = require('./files/info1.png');
export const escudo = require('./files/escudo.png');
export const exclamacion = require('./files/exclamacion.png');
export const gifAmex = require('./gifBanks/amex.gif');
export const gifAzteca = require('./gifBanks/bancoazteca.gif');
export const gifCoppel = require('./gifBanks/bancoppel.gif');
export const gifBanorte = require('./gifBanks/banorte.gif');
export const gifBanregio = require('./gifBanks/banregio.gif');
export const gifBbva = require('./gifBanks/bbva.gif');
export const gifCiti = require('./gifBanks/citibanamex.gif');
export const gifHsbc = require('./gifBanks/hsbc.gif');
export const gifInvex = require('./gifBanks/invex.gif');
export const gifLiverpool = require('./gifBanks/liverpool.gif');
export const gifSantander = require('./gifBanks/santander.gif');
export const gifScotiabank = require('./gifBanks/scotiabank.gif');
export const pugv2 = require('./files/pugv2.png');
export const amex = require('./banks/americanexpress.svg');
export const bbva = require('./banks/bbva.svg');
export const invex = require('./banks/invex.svg');
export const wallet987 = require('./wallets/BancoAzteca-1.png');
export const wallet233 = require('./wallets/superapp.png');
export const wallet268 = require('./wallets/broxel.png');
export const wallet476 = require('./wallets/tomiin.png');
export const wallet399 = require('./wallets/klinc.png');
export const wallet467 = require('./wallets/minu.png');
export const wallet825 = require('./wallets/paynom.png');
export const wallet433 = require('./wallets/coltomoney.png');
export const wallet675 = require('./wallets/mercadopago.jpg');
export const wallet616 = require('./wallets/pagaphone.png');
export const applePay = require('./wallets/apple-pay.png');
export const benavides = require('./files/benavides.png');
export const aurrera = require('./files/aurrera.png');
export const eleven = require('./files/eleven_logo.png');