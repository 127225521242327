import styled from "styled-components";

export const ModalRecoveyPassStyle = styled.div`
  max-width: 440px;
  .modal-recovery-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #01030c;
    margin-bottom: 8px;
  }
  .modal-recovery-text {
    font-size: 16px;
    line-height: 24px;
    color: #01030c;
    margin-bottom: 12px;
    font-weight: 300;
    span {
      cursor: pointer;
      color: #1a4ccd;
      text-decoration: underline;
      font-weight: 600;
    }
    p {
      margin-bottom: 12px;
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
  .modal-recovery-input {
    margin-bottom: 25px;
  }
  .modal-recovery-button {
  }
`;
