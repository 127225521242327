import React, { useEffect, useState } from "react";
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import InputBox from "../../../components/input-box/input-box";
import MainButton from "../../../components/main-button/main-button";
import ModalRecoveyPass from "../../../components/modals/modal-recovery-pass/modal-recovery-pass";
import PasswordBox from "../../../components/password-box/password-box";
import { authActions } from '../../../store/auth/actions';
import { getLoginInputMetada, LOGIN_EMAIL_INPUT_NAME, LOGIN_PASSWORD_INPUT_NAME } from '../../../utils/login-input-names';
import { getLoginFormErrors } from '../../../utils/login-input-validations';
import { InfoStyle } from "./info-qr-style";
import { paymentActions } from "../../../store/payment/actions";
import LogoIcon from "../../../assets/icons/Logo/logo-icon";
import useModal from "../../../hooks/useModal/use-modal";
import { loadSelector } from "../../../store/loader/selectors";
import { loaderActions } from "../../../store/loader/actions";
import Loader from "../../../components/loader/loader";
import amplitude from 'amplitude-js'
import { paymentSelector } from '../../../store/payment/selectors';
import * as firebase from "firebase";
import QRCode from 'qrcode.react'
import { authSelectors } from "../../../store/auth/selectors";

const InfoQR: React.FC = () => {
  // const loading = useSelector(loadSelector.isLoading());
  const purchasesData: any = useSelector(paymentSelector.purchasesData());
  // const [isNaNError, setIsNaNError] = useState(true)
  const token  = sessionStorage.getItem('buyToken')
  const loading = useSelector(loadSelector.isLoading());
  // const handleIsNaN = (val) => formatMoney(val) === '$NaN'
  console.log(loading, "FormLoading");

  return (
    <>
      {loading && <><Loader /></>}
      <InfoStyle>
        <div className="info-container">
          <div className="main-layout-logo">
            <LogoIcon scale="1.3" fill="" />
          </div>
          <div className="">
            <p style={{ marginBottom: '20px' }}>Para finalizar el pago de tu compra en <b style={{ fontWeight: 800 }}>{purchasesData?.merchantName ? purchasesData?.merchantName : purchasesData?.commerce?.name}</b>, inicia o crea tu cuenta SlightPay.</p>
            <div className="steps">
              <p className="step-number">01</p>
              <p className="step-text">El cliente deberá escanear el siguiente código QR para seguir con la operación.</p>
            </div>
            <div className="qr-code">
              <QRCode value={`${window.location.href}?justlogin=true`} />
            </div>
          </div>
          <div className="">
            <div className="steps">
              <p className="step-number">02</p>
              <p className="step-text">El cliente debe entrar a su cuenta o registrarse desde su celular.</p>
            </div>
          </div>
          <div className="">
            <div className="steps">
              <p className="step-number">03</p>
              <p className="step-text">El cliente debe seleccionar Pago con Wallet y seguir con los pasos del pago.</p>
            </div>
          </div>
        </div>
      </InfoStyle>
    </>
  );
};
export default InfoQR;
