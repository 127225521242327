import React from 'react';
import { useState , useEffect} from 'react';

import LoaderStaticStyle from './loader-style';

const LoaderStatic: React.FC<{}> = () => {

  const [counter, setCounter] = useState(0);

  React.useEffect(() => {
    counter >= 0 && setTimeout(() => setCounter(counter + 1), 1000);
  }, [counter]);

   console.log(counter,"VALI");

  return (
    <LoaderStaticStyle>
      <div className="loader-animation" >
        <div className="big-letter-container">
          <span className="big-letter up">
            slight
          </span>
          <span className="big-letter down">
            pay
          </span>
         
        </div>
      </div>
      <div className="loader-text">
      <p className='messageInfo'>Eligiendo...</p>
      </div>
    </LoaderStaticStyle>
  );
};

export default LoaderStatic;
