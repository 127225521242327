import produce from 'immer';

import { IPaymentActions, IPaymentState, IPaymentReducer } from './types';

// Move to other file
const checkForDataOnSessionStorage = (blockName: string): IPaymentState => {
  let tempState: IPaymentState;
  // Think a more abstract solution
  const defaultState: IPaymentState = {
    purchases: null,
    purchaseError: null,
    isOneShot: false,
    type: "4 cuotas",
    commerceBlock: false,
  };
  try {
    if (sessionStorage.getItem(blockName)) tempState = JSON.parse(sessionStorage.getItem(blockName) as string);
    else tempState = defaultState;
  } catch (error) {
    tempState = defaultState;
  }

  const initialState: IPaymentState = { ...tempState }; // Refact this and use a redux action
  return initialState;
};

export const initialState: IPaymentState = checkForDataOnSessionStorage('paymentState');

export const saveStoreInSessionStorage = (blockName: string, data: IPaymentState) => {
  sessionStorage.setItem(blockName, JSON.stringify(data));
};


const loaderReducer: IPaymentReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case IPaymentActions.GET_PURCHASE:
        draft.purchases = action.payload;
        break;
      case IPaymentActions.PURCHASE_ERROR:
        draft.purchaseError = action.error;
        break;
      case IPaymentActions.IS_ONESHOT:
        draft.isOneShot = action.payload;
        break;
      case IPaymentActions.TYPE:
        draft.type = action.payload;
        break;
      case IPaymentActions.COMMERCE_BLOCK:
        draft.commerceBlock = action.payload;
        break;   
      default:
    }
    saveStoreInSessionStorage('paymentState', draft);

  });

export default loaderReducer;