import { IAuthState } from './types';

export const authSelectors = {
  getMe: () => (state: { auth: IAuthState }) => state.auth.user,
  getLoginError: () => (state: { auth: IAuthState }) => state.auth.loggingError,
  getEmail: () => (state: { auth: IAuthState }) => state.auth.registerForm?.email,
  getErrorEmail: () => (state: { auth: IAuthState }) => state.auth.checkEmailError,
  getIneStatus: () => (state: { auth: IAuthState }) => state.auth.registerForm?.ine,
  getIneModalStatus: () => (state: { auth: IAuthState }) => state.auth.registerForm?.ineModal?.isOpen,
  getCurrentJWT: () => (state: { auth: IAuthState }) => state.auth.userToken,
  getCurrentBuyJWT: () => (state: { auth: IAuthState }) => state.auth.token,
  getRegisterError: () => (state: { auth: IAuthState }) => state.auth.registerError,
  getUserCommerce: () => (state: { auth: IAuthState }) => state.auth.user?.commerce,
  getIsItVerified: () => (state: { auth: IAuthState }) => state.auth.isItVerify,
  getUserPhone: () => (state: { auth: IAuthState }) => state.auth.phoneNumber,
  getScoringStatus: () => (state: { auth: IAuthState }) => state.auth.scoringResult, 
};