import React, { useEffect, useState } from "react";
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga';
import { useSelector, useDispatch } from 'react-redux';
import { ToastProvider } from 'react-toast-notifications';
import { ThemeProvider } from 'styled-components';
import "./App.scss";
import Normal from './assets/themes/normal/normal';
import MyCustomToast from './components/custom-toast/custom-toast';
import Loader from "./components/loader/loader";
import ModalBuyExpiration from "./components/modals/modal-buy-expiration/modal-buy-expiration";
import Toaster from './components/Toaster/Toaster';
import { facebookPixelKey, googleAnalyticsKey, OPENPAY_MERCHANTE_ID, OPENPAY_SANDBOX, PUBLIC_KEY } from './constants/env';
import AuthorizationRoutes from "./navigation/auth-routes";
import AppRoutes from "./navigation/lightpay-app-routes";
import { authSelectors } from "./store/auth/selectors";
import { loadSelector } from "./store/loader/selectors";
import { ErrorsSelector } from "./store/errors/selectors";
import ModalErrorGlobalCard from './components/modals/modal-error-global/modal-error-global'
import { errorsActions } from './store/errors/actions'
import MessengerCustomerChat from 'react-messenger-customer-chat';
import amplitude from 'amplitude-js'

const App: React.FC = () => {
  const [modalExpiration, setModalExpiration] = useState<boolean>(false);
  const errorsData = useSelector(ErrorsSelector.catchErrors());
  const loading = useSelector(loadSelector.isLoadingGlobal());
  const userToken = useSelector(authSelectors.getCurrentJWT());
  const dispatch = useDispatch()

  useEffect(() => {
    ReactGA.initialize(googleAnalyticsKey);
    ReactGA.pageview('https://pagos.slightpay.com/');
    ReactPixel.init(facebookPixelKey);
    ReactPixel.pageView();


    amplitude.getInstance().init("1a071dc4782cad551bb9d7d47cfa6cf5", undefined, {
      includeUtm: true
    });

    if(!window.location.href.includes('credit-card')) {
      amplitude.getInstance().setUserId(null);
      amplitude.getInstance().logEvent('checkout started');
    }
  }, []);

  (window as any).OpenPay.setSandboxMode(OPENPAY_SANDBOX);
  (window as any).OpenPay.setId(OPENPAY_MERCHANTE_ID);
  (window as any).OpenPay.setApiKey(PUBLIC_KEY);

  return (
    <ThemeProvider theme={Normal}>
      <ToastProvider components={{ Toast: MyCustomToast }}>
        {loading ? (<Loader />) : (
          <ModalErrorGlobalCard
            modalConfig={{
              onClose: () => dispatch(errorsActions.resetErrors()),
              active: errorsData.isActvieModal,
            }}
            title={errorsData.title}
            subtitle={errorsData.subtitle}
          />
        )}
        {userToken ? <AppRoutes /> : <AuthorizationRoutes />}
        {/* Modal expiracion de compra */}
        <ModalBuyExpiration
          active={modalExpiration}
          onClose={() => setModalExpiration(false)}
        />
        <MessengerCustomerChat
          pageId="2959990204015260"
          appId="2181789578621383"
        />

        <Toaster />
      </ToastProvider>
    </ThemeProvider>
  );
};

export default App;
