import { IBaseAction } from '../base-action.interface';

export enum IErrorsActions {
  SET_ERROR = 'SET_ERROR',
  RESET_ERROR = 'RESET_ERROR'
}

export interface IErrorsState {
  readonly title: string;
  readonly subtitle: string;
  readonly isActvieModal: boolean;
}


export interface IErrorReducer {
  (state: IErrorsState, action: IErrorsActionsTypes): IErrorsState;
}

export interface ISetErrorAction extends IBaseAction {
  type: IErrorsActions.SET_ERROR;
  payload: { title: '', subtitle: ''};

}

export interface IResetErrorAction extends IBaseAction {
  type: IErrorsActions.RESET_ERROR;
}


export type IErrorsActionsTypes = ISetErrorAction | IResetErrorAction;
