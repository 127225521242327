import styled from 'styled-components';

const LoaderStaticStyle = styled.div`
  width: 200px;
  height: 200px;
  color: #424242;
  display: flex;
  align-items: center;
  justify-content: center;
  .messageInfo{
    margin-top:40px;
    position: relative;
      top: -40px;
      left: -25px;
      text-align: center;
  }

  .loader-animation {
    width: 180px;
    height: 180px;
    position: absolute;

    .big-letter-container {
      position: relative;
      top: -100px;
      left: -25px;
      text-align: center;

      .big-letter {
        font-size: 15px;
        position: relative;
        font-weight: bold;
        text-align: center;
        &.up {
          top: -3px;
        }
        &.down {
          top: 3px;

        }

      }
  
    }

  }

  .loader-animation:before {
    content: "";
    display: block;
    width: 100px;
    height: 100px;
    margin: 8px;
    border-radius: 50%;
    border: 4px solid #1A4CCD;
    border-color: #1A4CCD transparent #1A4CCD transparent;
    animation: loader-animation 1.3s linear infinite;
  }


  @keyframes loader-animation {
    0% {
      transform: rotate(0deg);
      opacity: 1;
    }
    25% {
      opacity: 0.4;
    }
    50% {
      opacity: 1;
    }
    75% {
      opacity: 0.4;
    }
    100% {
      transform: rotate(360deg);
      opacity: 1;
    }
  }
`;

export default LoaderStaticStyle;
