/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { FormGridRow } from "../../form-grid-row/form-grid-row";
import InputBox from "../../input-box/input-box";
import { IModalBankDataProps } from './modal-bank-data-types';
import { ModalBankDataStyle, Step0Styles, Step1Styles, Step5Styles, Step2Styles, Step4Styles } from "./modal-bank-data-style";
import {
  getBankInputMetadata, BANK_USER_INPUT, BANK_PASSOWRD_INPUT, BANK_TOKEN_INPUT
} from "../../../utils/login-input-names";
import { useForm } from "react-hook-form";
import { getExtraBankDataFormErrors } from "../../../utils/login-input-validations";
import { useSelector, useDispatch } from 'react-redux';
import LogoIcon from "../../../assets/icons/Logo/logo-icon";
import Bitmap from "../../../assets/images/files/Bitmap.png";
import shild from "../../../assets/images/files/shild.png";
import back from "../../../assets/images/files/back.png";
import x from "../../../assets/images/files/x.png";
import like from "../../../assets/images/files/like.png";
import error from "../../../assets/images/files/404.png";
import americanexpress from "../../../assets/images/banks/americanexpress.svg";
import banorte from "../../../assets/images/banks/banorte.svg";
import citinanamex from "../../../assets/images/banks/citibanamex.svg";
import santander from "../../../assets/images/banks/santander.svg";
import azteca from "../../../assets/images/banks/bancoazteca.svg";
import banregio from "../../../assets/images/banks/banregio.svg";
import invex from "../../../assets/images/banks/invex.svg";
import scotia from "../../../assets/images/banks/scotiabank.svg";
import bancoppel from "../../../assets/images/banks/bancoppel.svg";
import bbva from "../../../assets/images/banks/bbva.svg";
import liverpool from "../../../assets/images/banks/liverpool.svg";
import heybanco from "../../../assets/images/banks/heybanco.svg";
import def from "../../../assets/images/banks/def.jpg"
import { errorsActions } from '../../../store/errors/actions'

import { modalActions } from "../../../store/modal/actions";
import PasswordBox from "../../password-box/password-box";
import { paymentActions } from "../../../store/payment/actions";
import { paymentSelector } from "../../../store/payment/selectors";
import { authActions } from "../../../store/auth/actions";
import { IPurchase } from "@aluxion-lightpay/interfaces";
import { loaderActions } from "../../../store/loader/actions";
import * as firebase from "firebase";
import Loader from '../../loader/loader';
import MainButton from '../../../components/main-button/main-button'
import PasswordBoxBank from "../../password-box/password-boxBank";
import InputBoxBank from "../../input-box/input-boxBank";
import LoaderBank from "../../loader/loaderBank";
import ErrorBank from "../../errorBank/errorBank";
import { escudo, eye, gifAmex, gifAzteca, gifBanorte, gifBanregio, gifBbva, gifCiti, gifCoppel, gifHsbc, gifInvex, gifLiverpool, gifSantander, gifScotiabank, infoBank, infoBank1 } from "../../../assets/images/images";
import LoaderSuccess from "../../loader/loaderSuccess";
import amplitude from 'amplitude-js'
import SendApiConversion from "../../../constants/apiConversionFb";
import { authSelectors } from "../../../store/auth/selectors";

let config = {
  apiKey: "AIzaSyCsdF478wIdcdtSCEzmTWPwq6nBYnn5TTE",
  authDomain: "slightpay-score.firebaseapp.com",
  databaseURL: "https://slightpay-score.firebaseio.com",
  storageBucket: "slightpay-score.appspot.com"
}

firebase.initializeApp(config)

const banksImgs = {
  americanexpress: americanexpress,
  banorte: banorte,
  citibanamex: citinanamex,
  santander: santander,
  bancoazteca: azteca,
  banregio: banregio,
  invex: invex, 
  scotiabank: scotia,
  bancoppel: bancoppel,
  bbvabancomer: bbva,
  liverpool: liverpool,
  heybanco: heybanco,
  def: def
}

const banksGifImgs = {
  americanexpress: gifAmex,
  banorte: gifBanorte,
  citibanamex: gifCiti,
  santander: gifSantander,
  bancoazteca: gifAzteca,
  banregio: gifBanregio,
  invex: gifInvex,
  scotiabank: gifScotiabank,
  bancoppel: gifCoppel,
  bbvabancomer: gifBbva,
  liverpool: gifLiverpool,
}

const banksClass = {
  americanexpress: "americanexpress",
  banorte: "banorte",
  citibanamex: "citibanamex",
  santander: "santander",
  bancoazteca: "bancoazteca",
  banregio: "banregio",
  invex: "invex",
  scotiabank: "scotiabank",
  bancoppel: "bancoppel",
  bbvabancomer: "bbvabancomer",
  liverpool: "liverpool",
  heybanco: "heybanco"
}

const banksImgsSize = {
  americanexpress: { height: '68px', width: '120px' },
  banorte: { height: '68px', width: '120px' },
  santander: { height: '68px', width: '120px' },
  citibanamex: { height: '68px', width: '120px' },
  bancoazteca: { height: '68px', width: '120px' },
  banregio: { height: '68px', width: '120px' },
  invex: { height: '68px', width: '120px' },
  scotiabank: { height: '68px', width: '120px' },
  bancoppel: { height: '68px', width: '120px' },
  bbvabancomer: { height: '68px', width: '120px' },
  liverpool: { height: '68px', width: '120px' },
  heybanco: { height: '68px', width: '120px' },
}
 
const ModalBankData: React.FC<IModalBankDataProps> = (props: IModalBankDataProps) => {
  const [errorBank, setErrorBank] = useState(false)
  const [messageOne, setMessageOne] = useState("")
  const [messageTwo, setMessageTwo] = useState("")
  const [step, setStep] = useState(0);
  const [userToken, setUserToken] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const [currentBank, setCurrentBank] = useState({ name: '', fields: [{ friendlyName: '' }, { friendlyName: '' }] })
  let useBanks = () => {
    let [banks, setBanks] = useState([] as any);
    let getBanksFields = async () => {
      let banks = await paymentActions.getBanksFields();
      console.log({ banks })
      setBanks(banks)
    }  
    // Aqui empezare la logica de Obtener bancos
    useEffect(() => {
      getBanksFields()
      console.log(purchasesData)
    }, []);

    return banks
  }

  const { modalConfig, idCard } = props
  const dispatch = useDispatch();
  const { register, handleSubmit, errors, control, watch } = useForm();
  const purchasesData = useSelector(paymentSelector.purchasesData()) as IPurchase;
  const { id, email, phoneNumber }: any = useSelector(authSelectors.getMe());
  
  const isOneShot = async (oneShot: boolean) => {
    await dispatch(paymentActions.isOneShot(oneShot))
    closeModal()
  }
  
  const openModal = async (id: number | string) => {
    await dispatch(modalActions.show(id));
  };

  const closeModal = async () => {
    await dispatch(modalActions.hide());
  };

  const openErrorModal = async (title: string, subtitle: string) => {
    await dispatch(errorsActions.setErrors({ title, subtitle }));
  };
  const handleForm = async (data: any) => {
    console.log(data, 'la data del inicio handleForm')
    // dispatch(loaderActions.loaded());
    // dispatch(loaderActions.loadedGloabal());
    // if(isLoading) return;
    setIsLoading(true)
    let database = firebase.database();

    let bankData: any = {
      bankName: currentBank.name,
      username: data[BANK_USER_INPUT],
      password: data[BANK_PASSOWRD_INPUT],
    };

    try {
      let resultToken
      let resultFinerioExtra

      if(userToken?.credentialId) {
        console.log('entre por el if')
        console.log('Esto le envie: ', data[BANK_TOKEN_INPUT], userToken?.credentialId, userToken?.customerId)
        resultToken = await dispatch(paymentActions.sendFinerioToken(data[BANK_TOKEN_INPUT], userToken?.credentialId, userToken?.customerId));
        setUserToken({})
      } else {
        console.log('entre por el else')
        resultFinerioExtra = await dispatch(paymentActions.sendExtraBankData(bankData, purchasesData.id));
      }
      // let resultFinerioExtra = true;
      if (resultFinerioExtra?.data || resultToken) {
        console.log('Checking in firebase....')
        let userIdFinerio = resultFinerioExtra?.data ? resultFinerioExtra?.data?.id : userToken?.customerId
        // let userIdFinerio = '200'
        console.log(userIdFinerio, resultFinerioExtra?.data?.id, userToken?.customerId)
        let clientInfo = database.ref('clientes/' + userIdFinerio);
        clientInfo.on('value', async function (snapshot) {
          if (snapshot.val()) {
            if (snapshot.val().detail) {
              console.log('si existe')
              if (snapshot.val().detail.statusCode === "500") {
                // ------------------------------------ 1 El usuario puede continuar intentando {valid: true, motivo: finerio/buro} ------------------------------------
                await dispatch(paymentActions.createUserByFinerio())
                let result: any = await paymentActions.checkContinueFinerioWay()
                let { reason } = result?.data
                // if(reason && reason === 'Finerio') 
                setIsLoading(false)
                await paymentActions.insertLogError({ code: snapshot.val().detail.statusCode, description: snapshot.val().detail.message, purchase: purchasesData })
                amplitude.getInstance().logEvent('error encountered', {'error_type': 'scoring open banking', 'error_reason': JSON.stringify(snapshot.val())});
                if (reason && reason === 'Buro') {
                  setTimeout(() => { document.location.reload() }, 2000)
                }
                // Hay que validar los errores por nombre de Finerio
                  if(snapshot.val().status === 401){
                    console.log("401 ELS");
                    setErrorBank(true)
                    setMessageOne(snapshot.val().message);
                  }else if(snapshot.val().status === 403){
                    console.log("403 ELS");
                    setErrorBank(true)
                    setMessageOne('Hubo un error de conexión, tu banca en línea no ha sido configurada o se requiere una configuración extra.');
                    setMessageTwo('Entra a tu banca en línea o comunícate con tu banco para activar tu acceso a banca en línea.')
                  }else if(snapshot.val().status === 500 ){
                    console.log("500 ELS");
                    setErrorBank(true)
                    setMessageOne('Hubo un problema de conexión con tu banco, sincroniza tu cuenta nuevamente en 5 minutos.');
                  }else{
                    console.log('Hemos revisado...')
                    setErrorBank(true);
                    setMessageOne('Hemos revisado tu score bancario y desafortunadamente no fue suficiente para autorizar tu compra, puedes intentarlo de nuevo ingresando las credenciales de otra cuenta bancaria. En este paso lo que intentamos validar es tu capacidad de pago.')
                  }
                console.log('Un error')
                dispatch(loaderActions.loaded());
              } else {
                setIsLoading(false)
                setStep(3)
                const approvedResult = await paymentActions.aproveUser()
                dispatch(authActions.setFinerioSuccess())
                // await dispatch(paymentActions
                //   .confirmPurchase(idCard, purchasesData?.id));
                amplitude.getInstance().logEvent('open bankig connected', {'bankType': currentBank.name, approved: approvedResult, 'merchantName': purchasesData?.commerce?.name})
                SendApiConversion('account profile submitted',[email],[phoneNumber],purchasesData?.commerce?.name);
                closeModal()
                // openModal('successBuy')
              }
            } else if (snapshot.val().status === 200 && snapshot.val().message === "Solicitar token") {
              if(resultFinerioExtra) {
                let credentialId = snapshot.val().credentialId
                console.log(credentialId, resultFinerioExtra?.data?.id, resultFinerioExtra)
                setUserToken({ credentialId, customerId: resultFinerioExtra?.data?.id })
                setIsLoading(false)
                setStep(6)
                return true
              }
            } else {
              console.log('no existe') 
            }
          }
          console.log(snapshot.val(), 'what is it in the first one')
        });
      }
    } catch (error) {
      setIsLoading(false)
      setStep(4)
      console.log(error, 'this error');
      amplitude.getInstance().logEvent('error encountered', {'error_type': 'create account', 'error_reason': JSON.stringify(error)});
      // dispatch(paymentActions.addPurchaseError({
      //   message: 'Hemos detectado un error en la conexión',
      //   value: 'credito',
      //   validation: 'para mas información contactanos a soporte@slightpay.com',
      // }))
      // dispatch(loaderActions.loaded());
    }
  };

  
  const handleStepWithAmplitude = () => {
    setStep(5)
    amplitude.getInstance().logEvent('open banking scoring starts cant find bank');
  }

  const handleStepZeroWithAmplitude = () => {
    setStep(0)
    amplitude.getInstance().logEvent('bank');
  }

  let banks = useBanks()

  const content = () => {
    if (isLoading) return (
      <>
        <LoaderBank currentBank={currentBank.name} />
        {/* <Loader /> */}
        {/* <ErrorBank messageOne={messageOne} /> */}
      </>
    )
    if(errorBank) return (
      <>
        <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', paddingRight: '4rem'}}>
          <img src={x} className='imgCloseBank' style={{ cursor: 'pointer', width: '15px', height: '15px', marginTop: '5rem' }} onClick={() => {setStep(2); setErrorBank(false)}} />
        </div>
        <ErrorBank messageOne={messageOne} messegeTwo={messageTwo && messageTwo}/>
      </>
    )
    if (step === 6) {
      let imgName = currentBank.name ? currentBank.name.toLowerCase().replace(" ", "") : 'banorte';
     return (
       <Step2Styles>
         <div className="grid-container" style={{ gridTemplateRows: '0.5fr 0.5fr 0.5fr', gridTemplateAreas: '"back . close" ". forms ." ". main-btn ." ". imageInfo ."' }}>
           <div className="back">
             <img src={back} style={{ cursor: 'pointer', width: '10px', height: '15px' }} onClick={() => setStep(1)} />
           </div>
           {/* <div className="close">
             <img src={x} style={{ cursor: 'pointer', width: '15px', height: '15px' }} onClick={() => modalConfig.onClose ? modalConfig.onClose() : null} />
           </div> */}
           {/* <div className="logo">
             <LogoIcon fill='' scale="0.7" />
           </div> */}
           <FormGridRow pattern="1fr" respPattern="1fr" className="forms">
             <div className="bank-logo" style={{ marginBottom: '25px' }}>
               {banksImgs[imgName] ? <img src={banksImgs[imgName]} style={{ cursor: 'pointer', ...banksImgsSize[imgName], margin: 'auto' }} alt={currentBank.name} /> : (<p>{currentBank.name}</p>)}
             </div>
             <div className="contentBg" style={{ gridTemplateRows: '1.5fr 2.5fr', gridTemplateAreas: '"title title title" ". input-1 ."'  }}>
               <div className="title">
                 <div className="modal-adress-title update-address-subtitle" style={{ wordBreak: "initial", fontWeight: 300, textAlign: "center"}}>
                   <p className="descriptionModalBank">Se ha enviado un token de sesión por parte de <span style={{ color: '#000000', fontWeight: 500 }}>{currentBank.name}</span>, por favor ingreselo a continuación:</p>
                 </div>
               </div>
               <div className="input-1" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                 <InputBox
                   labelText={''}
                   name={BANK_TOKEN_INPUT}
                   inputRef={register(getBankInputMetadata(BANK_TOKEN_INPUT))}
                   errorCode={getExtraBankDataFormErrors(errors, BANK_TOKEN_INPUT)}
                   placeholder={`Token`}
                   bankStyle
                 />
               </div>
             </div>
  
           </FormGridRow>

           <div className="main-btn" style={{ display: 'flex', alignItems: 'center' }}>
             <MainButton className="btnConectar" type="primary" text="ENVIAR" onClick={handleSubmit(handleForm)} />
           </div>

           <div className="imgInfo">
             <img className="infoOne" src={infoBank1} />
             <img className="infoTwo" src={infoBank}  />
           </div>
         </div>
       </Step2Styles>
     )
   }
    if (step === 4) return (
      <Step4Styles>
        <div className="grid-container">
          <div className="back">
            <img src={back} style={{ cursor: 'pointer', width: '10px', height: '15px' }} onClick={() => setStep(0)} />
          </div>
          {/* <div className="close">
            <img src={x} style={{ cursor: 'pointer', width: '15px', height: '15px' }} onClick={() => modalConfig.onClose ? modalConfig.onClose() : null} />
          </div> */}
          <div className="oops">
            <p style={{ fontSize: 'xx-large', fontFamily: 'gilroy', fontWeight: 'bold', }} >!Oops¡</p>
          </div>
          <div className="img-error">
            {/* <div style={{width: '230px', height: '259px', backgroundColor: 'blue', position: 'fixed',}} /> */}
            <img src={error} style={{ width: '100%', height: 'auto', zIndex: 10 }} />
          </div>
          <div className="text">
            <div className="modal-adress-title update-address-subtitle" style={{ wordBreak: "initial" }}>
              <p style={{ textAlign: 'center' }} >Lo sentimos, no se ha podido realizar la operación. Intenta de nuevo más tarde.</p>
            </div>
          </div>
          <div className="btn">
            <MainButton type="primary" text="Regresar" onClick={() => setStep(0)} />
          </div>
        </div>
      </Step4Styles>
    ) 
    if (step === 3) return (
      <Step5Styles>
        <div className="grid-container">
          <div className="back">
            <img src={back} style={{ cursor: 'pointer', width: '10px', height: '15px' }} onClick={() => modalConfig.onClose ? modalConfig.onClose() : null} />
          </div>
          {/* <div className="close">
            <img src={x} style={{ cursor: 'pointer', width: '15px', height: '15px' }} onClick={() => modalConfig.onClose ? modalConfig.onClose() : null} />
          </div> */}
          <div className="logo">
            <LogoIcon scale="1.2" fill="white" />
          </div>
          <div className="texts" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', }} >
              <img src={like} style={{ marginTop: '25px', width: '48px', height: '29px', }} />
              <div className="modal-adress-title update-address-subtitle" style={{ wordBreak: "initial", display: 'flex', flexDirection: "column" }}>
                <p style={{ marginTop: '25px', color: 'white', fontSize: 'xx-large', fontFamily: 'gilroy', fontWeight: 'bold', }} >¡Listo!</p>
                <p style={{ width: '250px', marginTop: '25px', color: 'white', alignSelf:"center" }}>Tu cuenta ha sido conectada con éxito</p>
                <p style={{ width: '300px', marginTop: '25px', color: 'white', alignSelf:"center" }}>Estamos procesando la información y tu plan de pagos.</p>
              </div>
            </div>
          </div>
          <div className="main-btn">
            {/* <MainButton type="primary" text="Regresar" onClick={()=>setStep(1)} /> */}
            <LoaderSuccess />
          </div>
        </div>
      </Step5Styles>
    )
    if (step === 5) return (
      <Step5Styles>
        <div className="grid-container">
          <div className="back">
            <img src={back} style={{ cursor: 'pointer', width: '10px', height: '15px' }} onClick={() => setStep(1)} />
          </div>
          {/* <div className="close">
            <img src={x} style={{ cursor: 'pointer', width: '15px', height: '15px' }} onClick={() => modalConfig.onClose ? modalConfig.onClose() : null} />
          </div> */}
          <div className="logo">
            <LogoIcon fill='' scale="0.7" />
          </div>
          <div className="texts">
            <div className="largeText modal-adress-title update-address-subtitle" style={{ wordBreak: "initial" }}>
              <br />
              <p>Si no encontraste tu banco en la sección anterior, significa que aún estamos trabajando en tener lista la conexión con tu institución financiera.</p>
              <br />
              <p>Sabemos que eso puede ser frustrante, pero estamos cada día mas cerca de tener una solución a la medida para ti. </p>
              <br />
            </div>
            <div className="largeText modal-adress-title update-address-subtitle" style={{ wordBreak: "initial" }}>
              <p>Te sugerimos lo vuelvas a intentar en el corto plazo ya que estamos trabajando para poder otorgar nuestro servicio a mas personas cada día.</p>
              <br />
              <p style={{ fontWeight: 600 }} >- SlightPay Team</p>
            </div>
          </div>
          <div className="main-btn">
            <MainButton type="primary" text="REGRESAR" onClick={() => setStep(1)} />
          </div>
        </div>
      </Step5Styles>
    )
    if (step === 2) {
       let imgName = currentBank.name ? currentBank.name.toLowerCase().replace(" ", "") : '';
      return (
        <Step2Styles>
          <div className="grid-container">
            <div className="back">
              <img src={back} style={{ cursor: 'pointer', width: '10px', height: '15px' }} onClick={() => setStep(1)} />
            </div>
            {/* <div className="close">
              <img src={x} style={{ cursor: 'pointer', width: '15px', height: '15px' }} onClick={() => modalConfig.onClose ? modalConfig.onClose() : null} />
            </div> */}
            {/* <div className="logo">
              <LogoIcon fill='' scale="0.7" />
            </div> */}
            <FormGridRow pattern="1fr 1fr" respPattern="1fr" className="forms">
              <div className="bank-logo">
                {banksImgs[imgName] ? <img src={banksImgs[imgName]} style={{ cursor: 'pointer', ...banksImgsSize[imgName], margin: 'auto' }} alt={currentBank.name} /> : (<p>{currentBank.name}</p>)}
              </div>
              <div className="contentBg">
                <div className="title">
                  <div className="modal-adress-title update-address-subtitle" style={{ wordBreak: "initial", fontWeight: 300, textAlign: "center"}}>
                    <p className="descriptionModalBank">Introduce las claves con las que accedes a <span style={{ color: '#000000', fontWeight: 500 }}>BancaNet</span> desde el portal web de <span style={{ color: '#000000', fontWeight: 500 }}>{currentBank.name}</span> </p>
                  </div>
                </div>
                {/* <div className="label-1">
                  <p style={{ fontSize: "14px", color: "#bab3b6", marginBottom: "15px", width: '100%', marginTop: '15px', textAlign: 'initial'}} >{currentBank.fields.length > 0 ? currentBank.fields[0].friendlyName:'NO. DE TARJETA / CUENTA / CLABE'}</p>
                </div> */}
                <div className="input-1">
                  <InputBoxBank
                    labelText={''}
                    name={BANK_USER_INPUT}
                    inputRef={register(getBankInputMetadata(BANK_USER_INPUT))}
                    errorCode={getExtraBankDataFormErrors(errors, BANK_USER_INPUT)}
                    placeholder={`${currentBank.fields.length > 0 ? currentBank.fields[0].friendlyName : 'NO. DE TARJETA / CUENTA / CLABE'}`}
                  />
                </div>
                {/* <div className="label-2">
                  <p style={{ fontSize: "14px", color: "#bab3b6", marginBottom: "15px", width: '100%', marginTop: '15px', textAlign: 'initial'}} >{currentBank.fields.length > 0 ? currentBank.fields[1].friendlyName:'CONTRASEÑA'}</p>
                </div> */}
                <div className="input-2">
                  <PasswordBoxBank
                    name={BANK_PASSOWRD_INPUT}
                    labelText={''}
                    inputRef={register(getBankInputMetadata(BANK_PASSOWRD_INPUT))}
                    errorCode={getExtraBankDataFormErrors(errors, BANK_PASSOWRD_INPUT)}
                    placeholder={`${currentBank.fields.length > 0 ? currentBank.fields[1].friendlyName : 'CONTRASEÑA'}`}
                  />
                </div>
              </div>
   
            </FormGridRow>
            {
              banksGifImgs[imgName] &&
            <div className="keys">
              <div className="contentGif">
                {banksGifImgs[imgName] ? <img className="whereKeys" src={banksGifImgs[imgName] && banksGifImgs[imgName] } alt={currentBank.name} /> : (<p>{currentBank.name}</p>)}
              </div>
              {banksGifImgs[imgName]  && <a>Dónde puedo consultar estas claves?</a>}
            </div>
            }

            <div className="main-btn">
              <MainButton className="btnConectar" type="primary" text="CONECTAR" onClick={handleSubmit(handleForm)} />
            </div>

            <div className="imgInfo">
              <img className="infoOne" src={infoBank1} />
              <img className="infoTwo" src={infoBank}  />
            </div>
          </div>
        </Step2Styles>
      )
    }
    let allBanks = banks.data ? banks.data.map(item => item) : []
    if (step === 1) return (
      <Step1Styles>
        <div className="grid-container">
          <div className="back">
            <img src={back} style={{ cursor: 'pointer', width: '10px', height: '15px' }} onClick={handleStepZeroWithAmplitude} />
          </div>
          {/* <div className="close">
            <img src={x} style={{ cursor: 'pointer', width: '15px', height: '15px' }} onClick={() => modalConfig.onClose ? modalConfig.onClose() : null} />
          </div> */}
          <div className="credentials">
            <p >Para conectar tu cuenta ten a la mano las Credenciales de tu Banca en Línea</p>
          </div>
          <div className="title">
            <p>Selecciona tu banco</p>
          </div>
          <div className="banks-list">
            {allBanks.map(item => {
              let imgKey = item.name.toLowerCase().replace(" ", "")
              console.log("EFE", banksClass[imgKey]);
              return item.status === 'ACTIVE' ? (
                <div className='bank-item'>
                  {banksImgs[imgKey] ? (
                    <div onClick={() => {
                      setCurrentBank(item);
                      setStep(2)
                    }} className="contentImg" style={{ display: ' flex' }} >
                      <img className={`imgBank ${banksClass[imgKey]}`} src={banksImgs[imgKey]? banksImgs[imgKey] : def} style={{ margin: 'auto' }} alt={item.name} />
                    </div>
                  ) : (
                    <div onClick={() => {
                      setCurrentBank(item);
                      setStep(2)
                    }} className="contentImg defaultBank" style={{ display: ' flex', textAlign: 'center', color:"white", alignItems: 'center', justifyContent:'center' }} >
                      <p>{item.name}</p>
                    </div>
                  )}
                </div>
              ) : (<></>)
            })}

          </div>
          <div className="question">
            <div className="modal-adress-title update-address-subtitle" style={{ wordBreak: "initial" }}>
              <p  style={{ textAlign: 'center' }} >¿No encuentras tu banco?</p>
            </div>
            <p onClick={handleStepWithAmplitude} style={{ fontSize: "14px", color: "#bab3b6", marginBottom: "15px", width: '100%', marginTop: '15px', textAlign: 'center', cursor: 'pointer' }} >Da click <span style={{ fontWeight: 'bold', textDecoration: 'underline' }} >aquí.</span></p>
          </div>
          <div className="click-here">
          </div>
        </div>
      </Step1Styles>
    )

    return (
      <Step0Styles>
        <div className="grid-container">
          {/* <div className="back">
            <img src={back} style={{ cursor: 'pointer', width: '10px', height: '15px' }} onClick={() => modalConfig.onClose ? modalConfig.onClose() : null} />
          </div> */}
          {/* <div className="close">
            <img src={x} style={{ cursor: 'pointer', width: '15px', height: '15px' }} onClick={() => modalConfig.onClose ? modalConfig.onClose() : null} />
          </div> */}
          <div className="logo">
            <LogoIcon fill='' scale="0.7" />
          </div>
          <div className="text">
            <div className="modal-adress-title update-address-subtitle" style={{ wordBreak: "initial" }}>
              {localStorage.getItem('buro_validation') ? <p>Desafortunadamente no hemos podido revisar tu score crediticio para autorizar tu compra.</p> : <p>Hemos revisado tu score crediticio, desafortunadamente no ha sido suficiente para autorizar tu compra.</p>}
              <br />
              <p>Como alternativa, puedes vincular una cuenta bancaria a Slightpay</p>
            </div>
          </div>
          <div className="save">
            <div className="eyes"> <img src={eye} style={{ width: '20px', height:'20px'}} /></div>
            <div className="subtitle-1">
              <p style={{ fontWeight: 600, color: "#1a4ccd" }} >Es seguro.</p>
            </div>
            <div className="description-1">
              <p>Ya que tus datos están cifrados en todo momento.</p>
            </div>
          </div>
          <div className="private">
            <div className="shild"><img src={escudo} style={{ width: '20px' }} /></div>
            <div className="subtitle-2"><p style={{ fontWeight: 600, color: "#1a4ccd" }} >Es Privado.</p></div>
            <div className="description-2"><p>El acceso a tu información financiera es sólo en modo lectura.</p></div>
          </div>

          <div className="main-btn">
            <div className="btn"> <MainButton className="btnConectar" type="primary" text="Seleccionar mi Banco" onClick={() => setStep(1)} /></div>
            <div className="label"><p style={{ marginBottom: '5px' }} >*In partnership with</p></div>
            <div className="finerio-icon"><img src={Bitmap} style={{ width: '80px' }} /></div>
          </div>
          <div className="pay-btn">
          <div className="label-pay"><p style={{ marginTop: '20px', marginBottom: '5px' }} >Si prefieres, puedes pagar tu compra de contado!</p></div>
            <div className="btn-pay"> <MainButton className="btnConectar" type="primary" text="Continuar" onClick={() => isOneShot(true)} /></div>
          </div>
        </div>
      </Step0Styles>
    )

  }
  if (!props.modalConfig.active || !props.show) {
    return <React.Fragment />
  }

  return (
    <ModalBankDataStyle color={step === 3 ? '#1A4CCD' : 'white'}>
      <div className='content' >
        {content()}
      </div>
    </ModalBankDataStyle>
  )
};

export default ModalBankData;
