import styled, { css } from 'styled-components';

interface IFormGridRowProps {
  pattern?: string;
  respPattern?: string;
  gap?: string;
}

export const FormGridRow = styled.div<IFormGridRowProps>`
  width: 100%;
  margin-bottom: 12px;
  display: grid;
  gap: 20px;
  ${props =>
    props.gap &&
    css`
      gap: ${props.gap};
    `}
  ${props =>
    props.pattern &&
    css`
      grid-template-columns: ${props.pattern};
    `}
  &:last-child {
    margin-bottom: 24px;
  }
  @media (max-width: 520px) {
  ${props =>
    props.pattern &&
    css`
      grid-template-columns: ${props.respPattern};
    `}
  }
`;
