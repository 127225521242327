import styled from "styled-components";

export const ModalContainerStyle = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: hidden;
  opacity: 0;
  transition: 0.3s ease-in-out all;
  padding: 100px 40px;
  background: white;
  z-index: 1000;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  & {
    -ms-overflow-style: none;
  }

  &.light {
    background: rgba(242, 242, 243, 0.98);
  }

  &.active {
    visibility: visible;
    opacity: 1;
  }

  &.large  {
    overflow-y: scroll;
    align-items: flex-start;
  }

  .modal-container-content {
    position: relative;
    padding-top: 80px;
    width: 100%;

    @media screen and (max-width: 1196px) {
      padding-top: 15px;
    }
  }

  .error-title {
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 40px;
    @media screen and (max-width: 1196px) {
      font-size: 18px;
    }
  }

  .modal-head {
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 0;
    left: 0;
    padding: 50px 50px 12px;
  }
  .modal-container-close {
    width: 32px;
    height: 32px;
    padding: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .modal-head-back {
    width: 32px;
    height: 32px;
    padding: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .modal-container-children {
    margin-bottom: 24px;
    padding: 0px 24px;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
  }
  .modal-container-buttons {
    border-top: 1px solid black;
    padding: 16px 24px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px;
  }

  .modal-logo {
    width: 171px;
    margin: 0 auto 52px;
  }

  @media (max-width: 768px) {
    padding: 80px 20px;
  }
`;
