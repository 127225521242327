import { IBaseAction } from '../base-action.interface';
import { ICard } from '@aluxion-lightpay/interfaces';

export enum CardActions {
    ADD_CARD_DATA = 'ADD_CARD_DATA',
}

export interface IUserCards {
  items: ICard[];
  count: number;
}

export interface ICardState { 
    readonly cards: IUserCards
}

export interface ICardReducer {
  (state: ICardState, action: CardActionsTypes): ICardState;
}

export interface ICardAddAction extends IBaseAction {
  type: CardActions.ADD_CARD_DATA;
  payload:IUserCards
}

export type CardActionsTypes = 
|ICardAddAction