import styled from "styled-components";
import { mainLayoutBackground } from "../../assets/images/images";

export const MainLayoutStyle = styled.div`
  display: flex;
  .main-layout-info {
    height: 100vh;
    min-height: 100vh;
    width: 50%;
    background: url(${mainLayoutBackground});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: fixed;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .main-layout-info-title {
      font-size: 36px;
      line-height: 44px;
      text-align: center;
      color: #ffffff;
      font-weight: 600;
      margin-bottom: 32px;
    }
    .main-layout-info-resume  {
      background-color: rgba(255, 255, 255, 0.9);
      padding: 25px;
      width: 100%;
      max-width: 420px;
      &__item {
        margin-bottom: 32px;
        &:last-child {
          margin-bottom: 0px;
        }
      }
    }

    .main-layout-info-resume-add  {
      background-color: rgba(255, 255, 255, 0.9);
      padding: 25px;
      width: 100%;
      max-width: 420px;
      margin-bottom: 20px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      &__item {
        margin-bottom: 32px;
        &:last-child {
          margin-bottom: 0px;
        }
      }
    }

    .main-layout-oneshot-resume  {
      background-color: rgba(255, 255, 255, 0.9);
      width: 100%;
      padding: 40px;
      max-width: 420px;
      .item-resume-fee {
        font-size: 14px;
        line-height: 22px;
        color: #01030C;
        width: 70%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
      .margin-fee {
        margin-right: 50px
      }
      .item-resume-amount {
        font-size: 14px;
        line-height: 22px;
        font-weight: 600;
        text-align: right;
        color: #1A4CCD;
        width: 20%;
        margin-right: 10px
      }
      .item-resume-graph {
        margin-right: 12px;
        width: 24px;
        height: 24px;
        display: flex;
        align-items: start;
      }
      .strong {
        font-size: 20px;
        line-height: 22px;
        font-weight: 800;
        margin-bottom: 30px;
      }
      &__item {
        display: flex;
        &:last-child {
          margin: 30px 0px 0px 0px;
        }
      }
    }
  }
  .main-layout-content {
    width: 50%;
    margin-right: 50%;  
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .main-layout-logo  {
    width: 171.56px;
    margin-bottom: 42px;
    margin-left: auto;
    margin-right: auto
  }

  .main-layout-info-title-promo {
    font-size: 25px;
    line-height: 44px;
    text-align: center;
    color: #ffffff;
    font-weight: 600;
    margin-bottom: 10px;
  }

  .main-layout-info-promo {
    display: flex;
    flex-direction: column;
    background-color: rgba(255, 255, 255, 0.9);
    padding: 20px;
    width: 100%;
    max-width: 420px;
  }

  .item-promo-title {
    color: black;
    font-size: 20px;
    text-align: left;
    font-weight: 500;
    width: 80%;
  }
  .item-promo-title-code {
    font-size: 15px;
    text-align: left;
    width: 80%;
  }
  .item-promo-title-price {
    color: #1A4CCD;
    font-size: 15px;
    text-align: left;
    width: 20%;
  }
  .item-promo-title-price-final {
    color: #1A4CCD;
    font-size: 15px;
    text-align: right;
    width: 100%;
    padding-right: 10px;
    font-weight: 700;
  }
  .item-promo {
    display: flex;
    flex-direction: row;
    padding: 5px;
  }

  @media (max-width: 1024px) {
    flex-direction: column-reverse;

    .main-layout-info {
      position: relative;
      width: 100%;
      max-height: 60vh;
    }
    .main-layout-content {
      margin-right: 0%;  
      width: 100%;
      min-height: auto;
    }

    .qr-code {
      display: none;
    }
  }
`;
