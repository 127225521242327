import { Dispatch } from 'redux';

import { IPaymentActions, IPaymentActionsTypes, IPurchaseErrors } from './types';
import { ILoadingActionsTypes, ILoadingActions } from '../loader/types';
import { IFormatError, LightpayApi } from '../restclient';
import { IPurchase } from '@aluxion-lightpay/interfaces';
// import { ApiErrorCodes } from '../http-errors';
interface IServerResponsePurchases {
  data: IPurchase
}

interface IBankForm {
  bankName: string;
  username: string;
  password: string;
}

// const handleCustomErrors = (action: 'CONFIRM_PAYMENT' | 'ADD_CARD', code?: string): string => {
//   let message = '';
//   if (code) {
//     const messageByCode: string = ApiErrorCodes[action][code] || 'error';
//     message = messageByCode;
//   }
//   return message;
// };

const get3dSecureErrors = (purchaseId) => async (dispatch: Dispatch<IPaymentActionsTypes | ILoadingActionsTypes>) => {
  try {
    dispatch({ type: ILoadingActions.LOADING });
    await LightpayApi.get<IServerResponsePurchases>(`/purchases/errors-3d-secure/${purchaseId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `bearer ${sessionStorage.getItem('token')}`,
      },
    });
    dispatch({ type: ILoadingActions.LOADED });

  } catch (error) {
    const formatError: IFormatError = LightpayApi.errorHandler(error);
    dispatch({ type: ILoadingActions.LOADED });
    throw formatError;
  }
};

const getPaymentData = (token: string) => async (dispatch: Dispatch<IPaymentActionsTypes | ILoadingActionsTypes>) => {
  try {
    dispatch({ type: ILoadingActions.LOADING });
    const purchasesData = await LightpayApi.get<IServerResponsePurchases>(`/purchases/details`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `bearer ${token}`,
      },
    });
    dispatch({ type: IPaymentActions.GET_PURCHASE, payload: purchasesData.data });
    dispatch({ type: ILoadingActions.LOADED });

  } catch (error) {
    const formatError: IFormatError = LightpayApi.errorHandler(error);
    dispatch({ type: ILoadingActions.LOADED });
    throw formatError;
  }
};

const setCommerceBlock = (commerce: any) => async (dispatch: Dispatch<IPaymentActionsTypes | ILoadingActionsTypes>) => {
  dispatch({ type: ILoadingActions.LOADING });
  dispatch({ type: IPaymentActions.GET_PURCHASE, payload: commerce });
  dispatch({ type: ILoadingActions.LOADED });
}

const confirmPurchase = (cardId: number, id: number, isOneShot: boolean, type: string,amount: number, bankName: string, lastDigits: string, purchasesData) =>
  async (dispatch: Dispatch<IPaymentActionsTypes | ILoadingActionsTypes>) => {
    try {
      // dispatch({ type: ILoadingActions.LOADING });
      // generate token with openPay
      const deviceSessionId = await (window as any).OpenPay.deviceData?.setup();
      const timezoneOffset = new Date().getTimezoneOffset();
      let result: any = await LightpayApi.post(`${sessionStorage.getItem('query') === 'true' ? 'sandbox' : ''}/purchases/${id}/confirm-purchase`,
        {
          cardId,
          deviceSessionId,
          timezoneOffset,
          urlOrigin: `${window.location.origin}/select-payment`,
          type: isOneShot ? 'OneShot' : type,
          amount: amount,
          bankName: bankName,
          lastDigits: lastDigits,
          sessionId: localStorage.getItem('clearsaleSessionId')
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `bearer ${sessionStorage.getItem('token')}`,
          }
        }
      )
      console.log(result, "FIN")

      if (result?.data?.payment_method?.url) {
        window.location.href = result.data.payment_method.url
      }

      if (result?.data?.formatUrl) {
        setTimeout(() => {
          window.location.href = result.data.formatUrl
        }, 5000)
      }

      if (result?.data?.returnUrl) {
        setTimeout(() => {
          window.location.href = result.data.returnUrl
        }, 5000)
      }

      if (!result.data) {
        throw new Error('Hubo un error al hacer tu compra');
      } else {
        dispatch({ type: IPaymentActions.GET_PURCHASE, payload: {...purchasesData, status: result.data.status } });
      }
    } catch (error) {
      console.log(error, "ERRI")
      const formatError: IFormatError = LightpayApi.errorHandler(error);
      dispatch({ type: ILoadingActions.LOADED });
      throw formatError;
    }
  }
const addPurchaseError = (error: IPurchaseErrors) => async (
  dispatch: Dispatch<IPaymentActionsTypes | ILoadingActionsTypes>) => {
  dispatch({ type: IPaymentActions.PURCHASE_ERROR, error })
}

const getBanksFields = async () => {
  let resultBanks = await LightpayApi.get(`/scoring/banks`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `bearer ${sessionStorage.getItem('token')}`,
      }
    }
  )

  return resultBanks
}

const insertLogError = async (body) => {
  let resultInsert = await LightpayApi.post(`/logs/insert-log`, body, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${sessionStorage.getItem('token')}`,
    }
  })

  return resultInsert
}

const aproveUser = async () => {
  let resultUser = await LightpayApi.get(`/scoring/aproved-user`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `bearer ${sessionStorage.getItem('token')}`,
      }
    }
  )

  return resultUser
}

const sendExtraBankData = (bankForm: IBankForm, id: number) => async (dispatch: Dispatch<IPaymentActionsTypes | ILoadingActionsTypes>) => {
  try {
    // dispatch({ type: ILoadingActions.LOADING });
    let result = await LightpayApi.post(`/scoring/open-banking/${id}`,
      bankForm,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `bearer ${sessionStorage.getItem('token')}`,
        }
      }
    )
    // dispatch({ type: ILoadingActions.LOADED });

    return result
  } catch (error) {
    console.log("Send Error", error)
    const formatError: IFormatError = LightpayApi.errorHandler(error);
    // dispatch({ type: ILoadingActions.LOADED });
    throw formatError;
  }
}

const sendFinerioToken = (token, credentialId, customerId) => async (dispatch: Dispatch<IPaymentActionsTypes | ILoadingActionsTypes>) => {
  console.log(token, credentialId, 'send finerio token')
  try {
    // dispatch({ type: ILoadingActions.LOADING });
    let result = await LightpayApi.post(`/scoring/send-token`,
      { token, credentialId, customerId },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `bearer ${sessionStorage.getItem('token')}`,
        }
      }
    )
    // dispatch({ type: ILoadingActions.LOADED });

    return result
  } catch (error) {
    console.log("Send Error", error)
    const formatError: IFormatError = LightpayApi.errorHandler(error);
    // dispatch({ type: ILoadingActions.LOADED });
    throw formatError;
  }
}

const createUserByFinerio = () => async (dispatch: Dispatch<IPaymentActionsTypes | ILoadingActionsTypes>) => {
  try {
    // dispatch({ type: ILoadingActions.LOADING });
    let result = await LightpayApi.post(`/scoring/create-user-finerio`,
      {},
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `bearer ${sessionStorage.getItem('token')}`,
        }
      }
    )

    return result
  } catch (error) {
    const formatError: IFormatError = LightpayApi.errorHandler(error);
    dispatch({ type: ILoadingActions.LOADED });
    throw formatError;
  }
}

const checkContinueFinerioWay = async () => {
  try {
    let result = await LightpayApi.post(`/scoring/check-continue-finerio-way`,
      {},
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `bearer ${sessionStorage.getItem('token')}`,
        }
      }
    )
    console.log(result, 'the response scoring check continue finerio way')

    return result
  } catch (error) {
    const formatError: IFormatError = LightpayApi.errorHandler(error);
    throw formatError;
  }
}

const getDappWallets = (setWallets: any) => async (
  dispatch: Dispatch<IPaymentActionsTypes | ILoadingActionsTypes>,
) => {
  let resultUrl: any = await LightpayApi.get(`/purchases/dapp-wallets`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `bearer ${sessionStorage.getItem('token')}`,
      },
    });

  if(resultUrl.data) {
    setWallets(resultUrl.data.data)
  }
}

const createCodiTransaction = (purchaseId: number, latepf: number, qrType: number, paymentPartitionId: number) => async (
  dispatch: Dispatch<IPaymentActionsTypes | ILoadingActionsTypes>,
) => {
  try {
    await dispatch({ type: ILoadingActions.LOADING });
    let resultUrl: any = await LightpayApi.post(`/purchases/charge-codi-dapp`, {
      purchaseId, paymentPartitionId, latepf, qrType, walletSessionId: localStorage.getItem('clearsaleSessionId')
    },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `bearer ${sessionStorage.getItem('token')}`,
        },
      });

    if (!resultUrl.data) {
      throw new Error('Hubo un error al hacer tu compra');
    }

    //window.open(resultUrl.data.data.qr_image, '_blank')
    await dispatch({ type: ILoadingActions.LOADED });
    console.log("REsultado dispatch actions " + resultUrl)
    return resultUrl
  } catch (error) {
    console.log(error, "ERRI")
    const formatError: IFormatError = LightpayApi.errorHandler(error);
    await dispatch({ type: ILoadingActions.LOADED });
    throw formatError;
  }
}

const isOneShot = (oneShot: boolean) => async (
  dispatch: Dispatch<IPaymentActionsTypes | ILoadingActionsTypes>,
) => {
  await dispatch({ type: ILoadingActions.LOADING });
  await dispatch({ type: IPaymentActions.IS_ONESHOT, payload: oneShot })
  await dispatch({ type: ILoadingActions.LOADED });
  //setWallets(resultUrl.data.data)
}

const paymentMethod = (payment: string) => async (
  dispatch: Dispatch<IPaymentActionsTypes | ILoadingActionsTypes>,
) => {
  await dispatch({ type: ILoadingActions.LOADING });
  await dispatch({ type: IPaymentActions.TYPE, payload: payment })
  await dispatch({ type: ILoadingActions.LOADED });
  //setWallets(resultUrl.data.data)
}

const createSpeiOrPaynetTransaction = (purchaseId: number, type: string, latepf: number, paymentPartitionId?: number) => async (
  dispatch: Dispatch<IPaymentActionsTypes | ILoadingActionsTypes>,
) => {
  try {
    await dispatch({ type: ILoadingActions.LOADING });
    let resultUrl: any = await LightpayApi.post(`/purchases/charge-openpay-manual`, {
      purchaseId, type, paymentPartitionId, latepf
    },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `bearer ${sessionStorage.getItem('token')}`,
        },
      });

    if (resultUrl.data) {
      console.log(resultUrl, 'la url...')
      window.open(resultUrl.data, '_blank')
    } else {
      throw resultUrl
    }
    await dispatch({ type: ILoadingActions.LOADED });
    return resultUrl
  } catch (error) {
    try {
      console.log(error, 'el error descrito...')
      const formatError: IFormatError = LightpayApi.errorHandler(error);
      throw formatError;
    } catch(e) {
      console.log(e)
    }
    await dispatch({ type: ILoadingActions.LOADED });
  }
}
export const paymentActions = {
  getPaymentData,
  confirmPurchase,
  addPurchaseError,
  sendExtraBankData,
  getBanksFields,
  aproveUser,
  insertLogError,
  createUserByFinerio,
  checkContinueFinerioWay,
  sendFinerioToken,
  getDappWallets,
  createCodiTransaction,
  get3dSecureErrors,
  isOneShot,
  paymentMethod,
  createSpeiOrPaynetTransaction,
  setCommerceBlock
};
