import styled from "styled-components";

export const InfoStyle = styled.div`
  padding: 40px 40px;
  align-items: center;
  display: flex;
  width: 100%;
  .info-container {
    margin: 0 auto;
    max-width: 325px;
    flex-direction: column;
    justify-content: center;
  }

  .back-to-pay{
    font-size: 14px;
    line-height: 24px;
    font-weight: 300;
    text-decoration: underline;
    color: #1A4CCD;
    margin-top: 15px;
    margin-bottom: 20px;
    cursor: pointer;
  }

  .login-split {
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    color: #808185;
    &__line {
      flex: auto;
      height: 1px;
      background: #808185;
    }
    &__text {
      margin: 8px;
    }
  }

  .qr-code {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0px 20px 0px;
  }
  .steps {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .step-number {
    font-size: 50px;
    color: gray;
  }
  .step-text {
    margin-left: 12px;
  }

  @media (max-width: 768px) {
    padding: 40px 20px;
  }
`;
