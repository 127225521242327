import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import ItemResume from "../item-resume/item-resume";
import { MainLayoutStyle } from "./main-layout-style";
import moment from 'moment';
import { formatMoney } from "../../utils/utils";
import QRCode from 'qrcode.react'
import CircleStat from "../circle-stat/circle-stat";
import { ItemResumeStyle } from "../item-resume/item-resume-style";
import { useSelector, useDispatch } from 'react-redux';
import { paymentSelector } from "../../store/payment/selectors";
import MainButton from "../../components/main-button/main-button";
import { paymentActions } from "../../store/payment/actions";
import { modalActions } from "../../store/modal/actions";
import { useHistory } from "react-router-dom"
import {
  getRegisterInputMetadata,
  PAYMENT_TYPE
} from "../../utils/login-input-names";
import { getRegisterFormErrors } from "../../utils/login-input-validations";
import SelectBox from "../select-box/select-box";

interface IMainLayoutProps {
  children: any;
  purchases?: any;
}

const MainLayout: React.FC<IMainLayoutProps> = ({ children, purchases }) => {
  const { errors, control, watch, setValue } = useForm();
  const dispatch = useDispatch()
  const history = useHistory();
  const openModal = async (id: number | string) => { await dispatch(modalActions.show(id)) };
  const isOneShot = useSelector(paymentSelector.isOneShot())
  const type = useSelector(paymentSelector.type())
  const [totalDues, setTotalDues] = useState<any>(["4 Cuotas", "6 Cuotas", "8 Cuotas"]);
  const [paymentPartitions, setPaymentPartitions] = useState<any>();

  const setOneShot = async () => {
    await dispatch(paymentActions.isOneShot(false))
    if (window.location.pathname !== "/select-payment") {
      history.push(`/select-payment`);
    }
  }

  const handleChangeSelect = async (data: string = '4 Cuotas') => {
    console.log(data)
    const paymentPartitions = purchasesData?.payments?.filter((payment) => {
      if (payment.type === data) {
        return payment
      }
    })

    paymentPartitions && setPaymentPartitions(paymentPartitions)
    await dispatch(paymentActions.paymentMethod(data))
  }

  const purchasesData: any = useSelector(paymentSelector.purchasesData());

  const paymentMethodSelect = watch(PAYMENT_TYPE) as string;

  useEffect(() => {
    let installments: any = []
    purchasesData?.payments?.map((payment) => {
      if (payment.type === '4 Cuotas' && payment.paymentIndex === 1) {
        installments.push('4 Cuotas')
      } else if (payment.type === '6 Cuotas' && payment.paymentIndex === 1) {
        installments.push('6 Cuotas')
      } else if (payment.type === '8 Cuotas' && payment.paymentIndex === 1) {
        installments.push('8 Cuotas')
      }
    })

    setTotalDues(installments)

    if (type && (paymentMethodSelect !== 'Seleccionar')) {
      handleChangeSelect(paymentMethodSelect)
    } else {
      if (!paymentMethodSelect || paymentMethodSelect === 'Seleccionar') {
        handleChangeSelect("4 Cuotas")
      } else {
        handleChangeSelect(paymentMethodSelect)
      }
    }
  }, [paymentMethodSelect, purchasesData])


  return (
    <MainLayoutStyle>
      <div className="main-layout-content">{children}</div>
      <div className="main-layout-info">
        {purchases?.extraDetails?.paymentDiscountCode && !isOneShot &&
          <>
            <div className="main-layout-info-title-promo">
              <p>Detalle de promoción</p>
            </div>
            <div className="main-layout-info-promo">
              <div className="item-promo">
                <div className="item-promo-title" >
                  Tu compra
                </div>
                <div className="item-promo-title-price" style={{ textDecoration: "line-through" }}>
                  {formatMoney((purchases?.subtotal) + (purchases?.extraDetails.paymentDiscountCode?.amount))}
                </div>
              </div>
              <div className="item-promo">
                <div className="item-promo-title-code">
                  {
                    purchases?.extraDetails?.discountCode?.porcentageAmount ?
                      `Promoción aplicada: ${(purchases?.extraDetails?.discountCode?.porcentageAmount) * 100}% de Descuento` :
                      `Promocion aplicada: -$${(purchases?.extraDetails?.discountCode?.giftAmount)} de Descuento`
                  }
                </div>
                <div className="item-promo-title-price">
                  -{formatMoney(purchases?.extraDetails.paymentDiscountCode?.amount)}
                </div>
              </div>
              <div className="item-promo">
                <div className="item-promo-title-code">
                  {`Código aplicado: ${purchases?.extraDetails?.discountCode?.code}`}
                </div>
              </div>
              <div className="item-promo">
                <div className="item-promo-title-price-final">
                  {formatMoney(purchases?.subtotal)}
                </div>
              </div>
            </div>
          </>
        }
        {isOneShot ?
          <>
            <div className="main-layout-info-title">
              <p>Tu pago de contado</p>
            </div>
            <div className="main-layout-oneshot-resume">
              <div className="main-layout-oneshot-resume__item">
                <div className="item-resume-fee strong margin-fee">
                  <p>
                    Total Compra
                  </p>
                </div>
                <div className="item-resume-amount strong">
                  <p>{formatMoney(purchasesData?.totalOneShot - purchasesData?.shippingFee - purchasesData?.tax)}</p>
                </div>
              </div>
              <div className="main-layout-oneshot-resume__item">
                <div className="item-resume-fee margin-fee">
                  <p>
                    Envío
                  </p>
                </div>
                <div className="item-resume-amount">
                  <p>{formatMoney(purchasesData?.shippingFee)}</p>
                </div>
              </div>
              <div className="main-layout-oneshot-resume__item">
                <div className="item-resume-graph" >
                  <CircleStat radius={17} stroke={4} progress={100} />
                </div>
                <div className="item-resume-fee strong margin-fee" style={{ marginTop: '5px', marginBottom: '0' }}>
                  <p style={{ color: "#1A4CCD" }}>
                    Pago completo
                  </p>
                </div>
                <div className="item-resume-amount strong" style={{ marginTop: '5px', marginBottom: '0' }}>
                  <p>{formatMoney(purchasesData?.totalOneShot)}</p>
                </div>
              </div>
            </div>
          </> :
          <>
            {
              totalDues?.length > 1 && <>
                <div className="main-layout-info-title">
                  <p>Selecciona tu</p>
                  <p>Plan de pago</p>
                </div>

                <div className="main-layout-info-resume-add">
                  <Controller
                    as={
                      <SelectBox
                        name={PAYMENT_TYPE}
                        // labelText="Elige tu plan de pago"
                        optionsArray={totalDues}
                        errorCode={getRegisterFormErrors(errors, PAYMENT_TYPE)}
                        tempValue={"4 Cuotas"}
                      />
                    }
                    name={PAYMENT_TYPE}
                    control={control}
                    rules={getRegisterInputMetadata(PAYMENT_TYPE)}
                  />
                </div>
              </>
            }
            <div className="main-layout-info-title">
              {
                totalDues?.length > 1 ? <p>Plan de pago</p> : <>
                  <p>Este es tu</p>
                  <p>Plan de pago</p>
                </>
              }
            </div>
            <div className="main-layout-info-resume">
              {
                paymentPartitions?.length !== 0 ? paymentPartitions?.map((paymentPartition, key) => {
                  return (
                    <div className="main-layout-info-resume__item">
                      <ItemResume
                        totalDues={paymentPartitions?.length}
                        currentDue={key + 1}
                        date={key === 0 ? "A pagar ahora" : moment(paymentPartition.dueDate).format('D MMM YYYY')}
                        amount={formatMoney(paymentPartition.amount)}
                        information={key === 0}
                      />
                    </div>
                  )
                }) : <>
                  <div className="main-layout-info-resume__item">
                    <ItemResume
                      totalDues={4}
                      currentDue={1}
                      date={"A pagar ahora"}
                      amount={formatMoney(purchases?.payments[0].amount)}
                      information={true}
                    />
                  </div>
                  <div className="main-layout-info-resume__item">
                    <ItemResume
                      totalDues={4}
                      currentDue={2}
                      date={moment(purchases?.payments[1].dueDate).format('D MMM YYYY') || ""}
                      amount={formatMoney(purchases?.payments[1].amount)}
                    />
                  </div>
                  <div className="main-layout-info-resume__item">
                    <ItemResume
                      totalDues={4}
                      currentDue={3}
                      date={moment(purchases?.payments[2].dueDate).format('D MMM YYYY') || ""}
                      amount={formatMoney(purchases?.payments[2].amount)}
                    />
                  </div>
                  <div className="main-layout-info-resume__item">
                    <ItemResume
                      totalDues={4}
                      currentDue={4}
                      date={moment(purchases?.payments[3].dueDate).format('D MMM YYYY') || ""}
                      amount={formatMoney(purchases?.payments[3].amount)}
                    />
                  </div>
                </>
              }
              {/* <div className="main-layout-info-resume" style={{ marginTop: '20px', marginBottom: '20px', fontWeight: 400, padding: '16px', textAlign: 'center', fontSize: '15px' }}>
          Al finalizar el proceso habrás pagado tu primera cuota y se habrán programado de forma automática el cobro de las siguientes 3.
        </div> */}
            </div>
          </>
        }
        {isOneShot &&
          <div style={{ marginTop: "20px" }}>
            <MainButton type="primary" text="Volver a 4 cuotas" onClick={setOneShot} />
          </div>}
      </div>
    </MainLayoutStyle>
  );
};

export default MainLayout;
