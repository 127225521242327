import styled from 'styled-components';
import { InputColors } from '../../assets/colors/input-colors/input-colors';

export const CalendarBoxContainer = styled.div`
  position: relative;
  z-index: 9;

  &.show-calendar {
    z-index: 12;
    .input-box-main-field {
      input {
      }
    }
    .calendar-popup {
      display: block;
    }
    .input-box-icon {
      opacity: 1;
    }
  }

  &.error {
    .input-box-main-field {
      input {
      }
    }
    .input-box-error {
      opacity: 1;
    }
  }

  &.complete {
    .input-box-main-field {
      input {
        &:focus {
        }
      }
    }
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.4;
  }

  .input-box-topbar {
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 9;
    margin-bottom: 8px;
  }

  .input-box-topbar-label {
    color: ${InputColors.label};
    transition: all 0.3s ease-in-out;
    text-transform: uppercase;
  }

  .input-box-topbar-icon {
    position: relative;
    z-index: 9;
    display: inherit;
    img {
      max-width: 18px;
      cursor: pointer;
    }
  }

  .input-box-main-field {
    position: relative;
    width: 100%;
    transition: all 0.2s ease-in-out;
    margin-bottom: 4px;

    cursor: pointer;
    height: 48px;
    border-radius: 6px;

    input {
      font-family: 'gilroy';
      line-height: 24px;
      font-size: 16px;
      font-weight: 300;
      width: 100%;
      padding: 13px 18px 9px 0;
      transition: all 0.2s ease-in-out;
      border-bottom: 1px solid ${InputColors.input.default};
      height: 48px;

      &::placeholder  {
        font-family: 'gilroy';
        line-height: 24px;
        font-size: 16px;
        font-weight: 300;
      }
    }

    &:hover {
    }

    &:focus-within {
      input {
      }
    }
  }

  .input-box-main {
    position: relative;
  }

  .input-box-icon {
    position: absolute;
    width: 24px;
    height: 24px;
    padding: 4px;
    top: 10px;
    right: 6px;
    z-index: 10;
    transition: all 0.3s ease-in-out;
    img {
      width: 100%;
      height: auto;
      cursor: pointer;
    }
  }

  .input-box-tooltip {
    position: absolute;
    top: calc(100% + 12px);
    z-index: 29;
    width: 260px;
    right: 0;
  }

  .calendar-popup {
    position: absolute;
    top: calc(100% - 10px);
    right: 0;
    border-radius: 3px;
    box-shadow: 0px 2px 17px rgba(0, 0, 0, 0.05);
    z-index: 12;
    display: none;

    li {
      transition: 0.3s all ease-in-out;
      cursor: pointer;
      padding: 11px 13px;
      width: 100%;

      &:hover {
      }
    }
  }

  .input-box-error {
    transition: 0.3s ease-in-out all;
    opacity: 0;
  }
`;
