import { Dispatch } from 'redux';

import { IErrorsActions, IErrorsActionsTypes } from './types';

const setErrors = (params) => async (dispatch: Dispatch<IErrorsActionsTypes>) => {
  dispatch({ type: IErrorsActions.SET_ERROR, payload: params  });
};

const resetErrors = () => async (dispatch: Dispatch<IErrorsActionsTypes>) => {
  dispatch({ type: IErrorsActions.RESET_ERROR });
};


export const errorsActions = {
  setErrors,
  resetErrors,
};
